import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client';
import './index.css'
import App from './App'
import Modal from 'react-modal'
import i18n from './i18n'
import monkeyPatch from './monkeypatch'
// Quick fix to prevent Google Translate to crash the app
monkeyPatch()

const root = document.getElementById('pp-root')
Modal.setAppElement(root)

root.className = 'pp-component'
/*
i18n.on("initialized", () => {
  createRoot(root).render(
    <App {...root.dataset} />
  )
})
*/

window.addEventListener(
  'load',
  () => {
    createRoot(root).render(
      <App {...root.dataset} />
    )
  },
  false
)



var updateAriaHidden = function (obj) {
  let menu = document.getElementById('menuTab')
  if (!menu) {
    // not all pages have a menu
    return null
  }

  if (obj.matches) {
    menu.setAttribute('aria-hidden', false)
  } else {
    menu.setAttribute('aria-hidden', true)
  }
}

var mm = window.matchMedia('(min-width: 980px)')
mm.addEventListener('change', updateAriaHidden)