import React, { useEffect, useState } from 'react'
import { observer, Provider as StoreProvider } from 'mobx-react'
import Script from 'react-load-script'
import { Trans } from 'react-i18next'
import MenuAndContent from './MenuAndContent'

import './css/app.css'
//import './css/themes/sigtunahem/sigtunahem.scss'

import { Root } from './Stores/index'

const store = Root

//const App = ({ isstandalone, inline, queue, page }) => {
const App = (props) => {
  var { isstandalone, inline, queue, page } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    store.auth.pollApi()

    store.portal.loadInfo().finally(() => {
      setLoading(false)
    })

    store.auth.updateCurrentUser()

    setLoading(false)
  }, [])

  page = page || 'mypage'

  return (
    <React.Fragment>
      {loading && (
        <div>
          <Trans>loading</Trans>
        </div>
      )}
      {!loading && (
        <StoreProvider store={store}>
          <MenuAndContent isStandalone={isstandalone} inline={inline} queue={queue} page={page} />
        </StoreProvider>
      )}
      <Script url="https://www.bing.com/api/maps/mapcontrol" />
    </React.Fragment>
  )
}

export default observer(App)
