import React, { Component } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import isEqual from 'lodash/isEqual'

export default class Container extends Component {
  constructor(props) {
    super(props)

    this.checkSelectedNodes(props.data, this.selectedLookup(props.selected))
    this.state = { data: props.data }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.checkSelectedNodes(nextProps.data, this.selectedLookup(nextProps.selected))
      this.setState({ data: nextProps.data })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data)
  }

  selectedLookup = (array) => {
    const lookup = {}
    array && array.map((selected) => (lookup[selected] = true))
    return lookup
  }

  checkSelectedNodes = (nodes, selected) => {
    if (!nodes) return

    nodes.forEach((node) => {
      if (selected && selected[node.value]) node.checked = true

      this.checkSelectedNodes(node.children, selected)
    })
  }

  render() {
    return <DropdownTreeSelect data={this.state.data} {...this.props} />
  }
}
