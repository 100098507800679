import { runInAction, extendObservable } from 'mobx'
import agent from './Agent'

class ReportsStore {
  constructor(parent) {
    this.parent = parent

    extendObservable(this, {
      apiError: false,

      errorReports: [],
      errorOptions: [],
      objectOptions: [],
      otherOptions: undefined,

      async getReports() {
        const data = await agent.Reports.get()

        if (data) {
          runInAction(() => {
            this.errorOptions.replace(data.errorOptions)
            this.objectOptions.replace(data.objectOptions)
            this.otherOptions = data.otherOptions
            this.errorReports.replace(data.errorReports)
          })
        }
      },
    })
  }

  postReport(data) {
    return agent.Reports.post(data)
  }
}

export default ReportsStore
