import React from 'react'
import PasswordValidationWidget from './Widgets/PasswordValidationWidget'

import { ReactComponent as IconExclamation } from 'Images/SVG/exclamation.svg'

const CustomFieldTemplate = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, label, help, description, errors, children, rawErrors = [] } = props
  let { classNames } = props

  if (props.uiSchema['ui:widget'] == PasswordValidationWidget) {
    classNames = classNames.replace('field-string', '') + 'field-password-validation'
  }

  if (props.uiSchema['ui:field'] == 'cascader') classNames = classNames.replace('field-object', 'field-string')
  let readonly = props.uiSchema.readonly
  let required = props.required
  if (props.uiSchema.hideRequired === true) required = false

  return (
    <div className={classNames}>
      {label && !readonly && (
        <label htmlFor={id} aria-describedby={id + '_error'}>
          {label} <span className="required">{required ? '*' : null}</span>
        </label>
      )}
      {label && readonly && (
        <span className="formLabel">
          {label} <span className="required">{required ? '*' : null}</span>
        </span>
      )}
      {/* {description}*/}
      <div className="input-container">
        {children}
        <div className="errors-container clearfix">
          {rawErrors.map((error, i) => (
            <span key={i} id={id + '_error'}>
              <span className="error-bubble">
                <IconExclamation />
              </span>{' '}
              {error.charAt(0).toUpperCase() + error.slice(1)}
            </span>
          ))}
        </div>
      </div>

      {help}
    </div>
  )
}

export default CustomFieldTemplate
