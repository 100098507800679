import React, { useEffect, useState } from 'react'
import Utils from 'lib/utils'
import ModalWindow from 'Components/ModalWindow'
import ObjectInfo from 'Components/ObjectInfo'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import agent from 'Stores/Agent'

const apiUrl = Utils.apiUrl
const datefmt = Utils.datefmt


const ObjectsRow = (props) => {
  let objectName = props.address && props.address.address1;
  console.log("ObjectsRow props", props)


  return (
    <div>
        
       <Trans>label.object_info</Trans>:&nbsp; 
      <a style={{ display: 'inline-block', minWidth: 'inherit', maxWidth: '100%' }} onClick={props.onObjectInfo} href="#">
        <span className="pp-flex">
          <span className="pp-flex-item pp-flex-hide-overflow">
            <span>{objectName}</span>
          </span>
        </span>
      </a>
    </div>
  )
}

const ContractRow = (props) => {

  console.log('ContractRow', props.onObjectInfo)

  const FilesRow = ({ id, name }) => {
    let fileType = name.split('.').pop()
    let fileName = name.slice(0, name.length - (fileType.length + 1))
    return (
      <div>
        <a style={{ display: 'inline-block', minWidth: 'inherit', maxWidth: '100%' }} title={name} href={apiUrl('/files/' + id)}>
          <span className="pp-flex">
            <span className="pp-flex-item pp-flex-hide-overflow">
              <span>{fileName}</span>
            </span>
            <span className="pp-flex-item pp-flex-item-strict">.{fileType}</span>
          </span>
        </a>
      </div>
    )
  }
  const files = []
  props.files && props.files.map((f, i) => files.push(<FilesRow id={f.id} name={f.name} key={i} />))

  const onObjectInfo = (obj) => (event) => {
    event.preventDefault()
    return props.onObjectInfo(obj)
  }
  const rentalObjects = []
  props.rentalObjectInfos && props.rentalObjectInfos.map((f, i) => rentalObjects.push(<ObjectsRow  {...f} key={i} onObjectInfo={onObjectInfo(f)} />))

  console.log(props)

  return (
    <tr>
      <th data-title={i18next.t('label.contract_number')} scope="col" style={{ verticalAlign: 'middle' }}>
        <strong>{props.number}</strong>
      </th>
      <th data-title={i18next.t('label.from_date')} scope="col" style={{ verticalAlign: 'middle' }}>
        {datefmt(props.fromDate, 'yyyy-MM-dd')}
      </th>
      <th data-title={i18next.t('label.to_date')} scope="col" style={{ verticalAlign: 'middle' }}>
        {datefmt(props.toDate, 'yyyy-MM-dd') || i18next.t('label.to_date_not_set')}
      </th>
      <th data-title={i18next.t('label.extended_to_date')} scope="col" style={{ verticalAlign: 'middle' }}>
        {datefmt(props.extendedToDate, 'yyyy-MM-dd') || i18next.t('')}
      </th>
      <th data-title={i18next.t('label.files')} scope="col" style={{ verticalAlign: 'middle' }}>
        {rentalObjects}
        {files}
      </th>
    </tr>
  )
}

const ContractTable = (props) => {
  const [loading, setLoading] = useState(true)
  const [contracts, setContracts] = useState([])

  useEffect(() => {
    async function getContracts() {
      const response = await agent.Contracts.get()
      setContracts(response.contracts)
      setLoading(false)
    }

    getContracts()
  }, [])

  if (loading) return <div>Laddar...</div>

  let rows = []
  contracts && contracts.map((p, i) => rows.push(<ContractRow {...p} key={i} onObjectInfo={props.onShowObjectInfo} />))

  return (
    <div>
      <table className="pp-data-table mb-large">
        <thead>
          <tr>
            <td>
              <strong>
                <Trans>label.contract_number</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.from_date</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.to_date</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.extended_to_date</Trans>
              </strong>
            </td>
            <td style={{ width: '35%' }}>
              <strong>
                <Trans>label.files</Trans>
              </strong>
            </td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

const AddressRow = ({ onChangeAddress, canChange, ...address }) => {
  return (
    <tr>
      <td data-title={i18next.t('label.contract_number')} scope="col" style={{ verticalAlign: 'middle' }}>
        <strong>{address.contractNumber}</strong>
      </td>
      <td data-title={i18next.t('label.street')} scope="col" style={{ verticalAlign: 'middle' }}>
        <strong>{address.address1 || address.address2}</strong>
      </td>
      <td data-title={i18next.t('label.zipcode')} scope="col" style={{ verticalAlign: 'middle' }}>
        <strong>{address.zip}</strong>
      </td>
      <td data-title={i18next.t('label.city')} scope="col" style={{ verticalAlign: 'middle' }}>
        <strong>{address.city}</strong>
      </td>
      {canChange && (
        <td>
          <button className="main-btn" onClick={() => onChangeAddress(address)}>
            Byt adress
          </button>
        </td>
      )}
    </tr>
  )
}

const ChangeAddressPopup = (props) => {
  const { address, info, onClose } = props

  const formSpec = {
    title: i18next.t('label.invoice_address'),
    fields: [
      { name: 'street', title: i18next.t('label.street'), required: true },
      { name: 'zip', title: i18next.t('label.zipcode'), required: true },
      { name: 'city', title: i18next.t('label.city'), required: true },
    ],
  }

  if (address != null) address.street = address.address1 || address.address2

  return (
    <ModalWindow isOpen={address != null} onClose={onClose} title={i18next.t('label.change_invoice_address')} maxWidth={500}>
      <div dangerouslySetInnerHTML={{ __html: info }} />

      <Form
        schema={formSpec}
        formData={address}
        submit={i18next.t('label.send_request')}
        onSubmit={async (f) => {
          const values = f.formData

          var newAddress = {
            contractNumber: values.contractNumber,
            street: values.street,
            city: values.city,
            zip: values.zip,
          }
          var result = await agent.Contracts.changeAddress(values.id, newAddress)
        }}
        onSuccess={async (values) => {
          await new Promise(() => setTimeout(onClose, 2000))
        }}
        successBox={<MessageBox type="success">{i18next.t('info.request_sent_by_mail')}</MessageBox>}
      />
    </ModalWindow>
  )
}

const AddressTable = (props) => {
  const [loading, setLoading] = useState(true)
  const [addresses, setAddresses] = useState([])
  const [canChange, setCanChange] = useState(false)
  const [info, setInfo] = useState(null)
  const [changeAddress, setChangeAddress] = useState(null)

  useEffect(() => {
    async function getAddresses() {
      const response = await agent.Contracts.getAddresses()
      setLoading(false)

      setAddresses(response.addresses || [])
      setCanChange(response.canChange)
      setInfo(response.info)
    }

    getAddresses()
  }, [])

  if (loading) return <div>Laddar...</div>

  let rows = []

  addresses &&
    addresses.map((a, i) =>
      rows.push(<AddressRow canChange={canChange} {...a} onChangeAddress={(address) => setChangeAddress(address)} key={i} />)
    )

  return (
    <div>
      <table className="pp-data-table mb-large">
        <thead>
          <tr>
            <td>
              <strong>
                <Trans>label.contract_number</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.street</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.zipcode</Trans>
              </strong>
            </td>
            <td>
              <strong>
                <Trans>label.city</Trans>
              </strong>
            </td>
            {canChange && (
              <td>
                <strong>
                  <Trans>label.change_invoice_address</Trans>
                </strong>
              </td>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <ChangeAddressPopup address={changeAddress} info={info} onClose={(sent) => setChangeAddress(null)} />
    </div>
  )
}

const Page = () => {
  const [showModal, setShowModal] = useState(false)
  const [currentObject, setCurrentObject] = useState()

  const setModal = (visible) => setShowModal(visible)
  const showObjectInfo = (obj) => {
    console.log('showObjectInfo', obj)
    setCurrentObject(obj, true)
    setShowModal(true)
  }
  const onClose = () => setModal(false)

  return (
    <div>
      <h1>
        <Trans>contract.header</Trans>
      </h1>

      <Tabs>
        <TabList>
          <Tab>
            <Trans>pages.contract</Trans>
          </Tab>
          <Tab>
            <Trans>pages.addresses</Trans>
          </Tab>
        </TabList>
        <TabPanel>
          <ContractTable onShowObjectInfo={showObjectInfo} />
        </TabPanel>
        <TabPanel>
          <AddressTable />
        </TabPanel>
      </Tabs>

      <ModalWindow
        isOpen={showModal}
        onClose={onClose}
        title={currentObject && currentObject.address && currentObject.address.address1}
      >
        <ObjectInfo object={currentObject} />
      </ModalWindow>

    </div>
  )
}

export default Page
