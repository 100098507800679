import { observable, extendObservable, runInAction } from 'mobx'
import agent from './Agent'
import i18next from 'i18next'

function transformNulls(obj) {
  if (obj == null) return

  Object.keys(obj).forEach((key) => {
    obj[key] = obj[key] != null ? obj[key] : undefined
  })
}

class UserStore {
  constructor(parent) {
    this.parent = parent
    this.auth = parent.auth
    this.ui = parent.ui

    extendObservable(this, {
      apiError: false,
      profile: observable(new Map()),
      areaOptions: undefined,
      housingOptions: undefined,
      incomeOptions: undefined,
      fieldSettings: undefined,
      queues: undefined,

      register(data) {
        return agent.User.register(data)
      },

      registerTenant(data) {
        return agent.User.registerTenant(data)
      },

      async getProfile() {
        const data = await agent.User.getProfile()
        if (!data) return

        transformNulls(data.profile)

        runInAction(() => {
          let housingOptions = data.housingOptions
          for (let key in housingOptions)
            if (housingOptions.hasOwnProperty(key)) housingOptions[key] = this.housingOption2name(housingOptions[key])

          let incomeOptions = data.incomeOptions
          for (let key in incomeOptions)
            if (incomeOptions.hasOwnProperty(key)) incomeOptions[key] = this.incomeOption2name(incomeOptions[key])

          this.profile.replace(data.profile)
          this.areaOptions = data.areaOptions
          this.housingOptions = housingOptions
          this.incomeOptions = incomeOptions
          this.fieldSettings = data.fieldSettings
          this.queues = data.queues
        })
      },

      async preloadUser() {
        const tasks = []
        const useQueue = this.parent.portal.useQueue()

        tasks.push(this.getProfile())
        tasks.push(this.parent.reports.getReports())

        if (useQueue) {
          tasks.push(this.parent.queue.loadSubscriptions())
          tasks.push(this.parent.queue.loadQueues())
          tasks.push(this.parent.queue.loadObjects())
        }

        await Promise.all(tasks)
      },

      async updateProfile(values) {
        await agent.User.updateProfile(values)
        return await this.getProfile()
      },

      async changePassword(oldPassword, newPassword) {
        return agent.User.changePassword(oldPassword, newPassword)
      },

      async deleteProfile(password) {
        return agent.Auth.delete(password)
      },
    })
  }

  housingOption2name(option) {
    const nameMapping = {
      annat: i18next.t('housing_options.other'),
      hyreslagenhet: i18next.t('housing_options.rental_apartment'),
      villa: i18next.t('housing_options.house'),
      bostadsratt: i18next.t('housing_options.tenant_owned_apartment'),
    }

    let key = option.toLowerCase().replace(/[åä]/g, 'a').replace('ö', 'o').replace(' ', '_')
    return nameMapping[key] || option
  }

  incomeOption2name(option) {
    const nameMapping = {
      pensionar: i18next.t('income_options.pensioner'),
      arbetssokande: i18next.t('income_options.unemployed'),
      fast_anstallning: i18next.t('income_options.employed'),
      eget_foretag: i18next.t('income_options.entrepreneur'),
      studerande: i18next.t('income_options.student'),
      tillfalligt_arbete: i18next.t('income_options.temporary_employed'),
    }

    let key = option.toLowerCase().replace(/[åä]/g, 'a').replace('ö', 'o').replace(' ', '_')
    return nameMapping[key] || option
  }
}

export default UserStore
