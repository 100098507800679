import React, { useEffect, useRef, useState } from 'react'
import Utils from 'lib/utils'
import Icons from 'lib/icons'
import { Root as store } from 'Stores'
import agent from 'Stores/Agent'
import { ErrorHandling, ErrorHandlingHelper } from 'Components/ErrorHandling'
import { ref } from 'yup'
import { CalendarContainer } from 'react-datepicker'
import {Trans} from "react-i18next";
import i18next from "i18next";

const datefmt = Utils.datefmt

const Message = ({ message }) => {
  const style = {
    person: {
      width: '90%',
      padding: '10px',
      border: '1px solid #edeef7',
      borderRadius: '5px',
      float: 'right',
      marginBottom: '10px',
      whiteSpace: 'pre',
    },
    admin: {
      width: '90%',
      padding: '10px',
      backgroundColor: '#edeef7',
      borderRadius: '5px',
      marginBottom: '10px',
      float: 'left',
      whiteSpace: 'pre',
    }
  }
  return (
    <React.Fragment>
      <div style={(message.fromAdmin ? style.admin : style.person)}>
        <div style={{ fontWeight: 'bold' }}>{datefmt(message.createdDate)}</div>
        {message.text}
      </div>
    </React.Fragment>
  )
}

const NewMessage = ({ onNewMessage }) => {
  const [data, setData] = useState('');

  const handleSend = (e) => {
    onNewMessage(data);
    setData('');
  }

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <textarea rows={3} style={{ width: '100%' }} value={data} onChange={(e) => setData(e.target.value)} placeholder={i18next.t('chat.placeholder')}>{data}</textarea>
        <button className="main-btn" onClick={handleSend}><Trans>chat.send_button</Trans>&nbsp;{Icons.Send}</button>
      </div>
    </React.Fragment>
  )
}

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [errorHandling, setErrorHandling] = useState(ErrorHandlingHelper.getDefaultState())

  const loadMessages = () => {
    agent.Chat.getMessages().then((msgs) => {
      setMessages(msgs);
      setLoading(false);
    })
      .catch((err) => {
        setErrorHandling(ErrorHandlingHelper.error(err))
      });
  }

  useEffect(loadMessages, []);
  useEffect(loadMessages, [refresh]);

  const onNewMessage = (text) => {
    agent.Chat.sendMessage(text)
      .then(() => {
        setRefresh(refresh + 1);
      })
      .catch((err) => {
        setErrorHandling(ErrorHandlingHelper.error(err))
      });
  }

  const containerRef = useRef(null);

  useEffect(() => {
    function resizeHandler() {
      if (!containerRef.current) return;
      var h = parseInt(document.documentElement.clientHeight, 10);
      containerRef.current.style.height = (h - 220) + 'px';
    }
    resizeHandler();
    if (containerRef.current) {
      var l = containerRef.current.childNodes.length;
      if (l > 0)
        containerRef.current.childNodes[l - 1].scrollIntoView(false);
    }

    window.addEventListener('resize', resizeHandler);

    return function () {
      window.removeEventListener('resize', resizeHandler);
    }
  });

  return (
    <React.Fragment>
      <ErrorHandling {...errorHandling} onClose={(e) => setErrorHandling(ErrorHandlingHelper.close())} />
      {loading && <div><Trans>loading</Trans></div>}
      {!loading && (
        <div>
          <h1><Trans>chat.header</Trans></h1>
          <div ref={containerRef} style={{ overflowY: 'auto', minHeight: '300px' }}>
            {messages && messages.map((m) => <Message key={m.id} message={m} />)}
          </div>
          <NewMessage onNewMessage={onNewMessage} />
        </div>
      )}
    </React.Fragment>
  )
}

export default Page
