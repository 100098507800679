import React from 'react'
import { ReactComponent as Check } from 'Images/SVG/check.svg'
import { ReactComponent as Exclamation } from 'Images/SVG/exclamation.svg'
import { ReactComponent as Information } from 'Images/SVG/info.svg'
import { ReactComponent as Close } from 'Images/SVG/cross.svg'
import { ReactComponent as Star } from 'Images/SVG/star.svg'
import { ReactComponent as History } from 'Images/SVG/history.svg'
import { ReactComponent as Cross } from 'Images/SVG/cross.svg'
import { ReactComponent as Housing } from 'Images/SVG/building.svg'
import { ReactComponent as Ship } from 'Images/SVG/ship.svg'
import { ReactComponent as Car } from 'Images/SVG/automobile.svg'
import { ReactComponent as Archive } from 'Images/SVG/archive.svg'
import { ReactComponent as Profile } from 'Images/SVG/user.svg'
import { ReactComponent as Lock } from 'Images/SVG/lock.svg'
import { ReactComponent as Ban } from 'Images/SVG/ban.svg'
import { ReactComponent as Mail } from 'Images/SVG/mail-outline.svg'
import { ReactComponent as Pen } from 'Images/SVG/mail-outline.svg'
import { ReactComponent as Send } from 'Images/SVG/send.svg'

export default {
  Check: <Check aria-hidden="true" />,
  Exclamation: <Exclamation aria-hidden="true" />,
  Information: <Information aria-hidden="true" />,
  Close: <Close aria-hidden="true" />,
  Star: <Star aria-hidden="true" />,
  History: <History aria-hidden="true" />,
  Cross: <Cross aria-hidden="true" />,
  Housing: <Housing aria-hidden="true" />,
  Ship: <Ship aria-hidden="true" />,
  Car: <Car aria-hidden="true" />,
  Archive: <Archive aria-hidden="true" />,
  Profile: <Profile aria-hidden="true" />,
  Lock: <Lock aria-hidden="true" />,
  Ban: <Ban aria-hidden="true" />,
  Mail: <Mail aria-hidden="true" />,
  Pen: <Pen aria-hidden="true" />,
  Send: <Send aria-hidden="true" />,
}
