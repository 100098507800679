import React from 'react'
import Utils from 'lib/utils'
import i18next from "i18next";

const outputDocument = Utils.outputDocument

const AgreeWidget = ({ id, required, title, value, options, onChange }) => {
  const text = options.text
  let checkTitle = i18next.t('agree_widget.check_title')
  if (options.title) checkTitle = <span dangerouslySetInnerHTML={{ __html: options.title }} />

  const onToggle = (e) => {
    onChange(e.target.checked || undefined)
  }

  return (
    <React.Fragment>
      {outputDocument(text)}
      <div>
        <input
          type="checkbox"
          className="form-control"
          id={id}
          checked={typeof value === 'undefined' ? false : value}
          title={title}
          required={required}
          onChange={onToggle}
        />
        &nbsp; {checkTitle}
      </div>
    </React.Fragment>
  )
}

export default AgreeWidget
