import React from 'react'
import Dropzone from 'react-dropzone'
import { Trans } from 'react-i18next'

class FileDropWidget extends React.Component {
  constructor() {
    super()
    this.state = { files: [] }
  }

  onDrop(files) {
    this.setState({
      files
    })
  }

  render() {
    const files = this.state

    return (
      <section>
        <div className="dropzone">
          <Dropzone onDrop={this.onDrop.bind(this)}>
            <p><Trans>file_drop_widget.helper</Trans></p>
          </Dropzone>
        </div>
        <aside>
          <h2><Trans>file_drop_widget.header</Trans></h2>
          <ul>
            {
              files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
            }
          </ul>
        </aside>
      </section>
    )
  }
}

export default FileDropWidget
