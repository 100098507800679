import React from 'react'
import i18next from "i18next";

const dataName2name = (option) => {
  const nameMapping = {
    objektnummer: i18next.t('label.object_number'),
    byggnadsar: i18next.t('label.year_built'),
    typ: i18next.t('label.type'),
    kvm: i18next.t('label.area_kvm'),
    hyra: i18next.t('label.rent_sek_per_month'),
    vaningsplan: i18next.t('label.floor_level')
  }

  let key = option.toLowerCase()
    .replace(/[åä]/g,'a')
    .replace('ö','o')
    .replace(' ','_');
  return nameMapping[key] || option
}

const TableRow = (props) => {
  let { name, value } = props

  let valueText = value
  if (typeof value == 'string') {
    if (value === "Ja") {
      valueText = i18next.t('yes')
    }
    else if (value === "Nej") {
      valueText = i18next.t('no')
    }
  }
  
  if (typeof value == 'boolean') {
    valueText = value ? i18next.t('yes') : i18next.t('no')
  } 
  
  if (name == 'Kvm') {
    if (typeof value === 'string')
      valueText = Math.round(parseFloat(value.replace(',', '.')))
    else
      valueText = Math.round(value)
  }


  return (
    <tr>
      <th style={{ width: '30%' }} scope="row">
        <strong>{dataName2name(name)}:</strong>
      </th>
      <td>{valueText}</td>
    </tr>
  )
}

const FactTable = (props) => {
  let { data } = props

  if (data.length == 0) return false

  let rows = []
  data.forEach((d, i) => {
    rows.push(<TableRow key={i} name={d.name} value={d.value} />)
  })

  return (
    <table className="pp-basic-data-table">
      <tbody>{rows}</tbody>
    </table>
  )
}

export { FactTable, TableRow }
