import agent from 'Stores/Agent'
import { isMobile } from 'react-device-detect'

const Status = {
  READY: 'ready',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success ',
}

const ServiceType = {
  BankId: 0,
  FrejaId: 1,
}

class BankIdClient {
  constructor({ mobile, serviceType, onSuccess, onError, onStatus }) {
    this.onSuccess = onSuccess
    this.onError = onError
    this.onStatus = onStatus
    this.serviceType = serviceType || ServiceType.BankId
    this.mobile = mobile
    this.status = Status.READY
    this.autoStartToken = null
  }

  login = async (data) => {
    this.autoStarted = false
    this.autoStartToken = null

    try {
      if (!data.ssn) {
        this.setError('invalid_ssn')
        return
      }

      data.serviceType = this.serviceType
      data.mobile = this.mobile

      this.setStatus(Status.PENDING)

      var response = await agent.Auth.bankIdLogin(data)
      if (!response.session) {
        this.onError && this.onError(response.status)
      }

      if (response.autoStartToken) {
        if ((!this.mobile && !isMobile) || (this.mobile && isMobile)) {
          this.autoStartToken = response.autoStartToken
          this.startBankId()
        }
      }

      await this.pollStatus({ ...data, session: response.session })
    } catch (err) {
      this.setError('unknown_error')
    }
  }

  startBankId = () => {
    setTimeout(() => {
      window.location = `bankid:///?autostarttoken=${this.autoStartToken}&redirect=null`
    }, 10)
  }

  pollStatus = async (data) => {
    try {
      if (!data.ssn) throw new Error('SSN not set')
      if (!data.session) throw new Error('Session not set')

      if (this.status != Status.PENDING) return

      data.serviceType = this.serviceType

      const response = await agent.Auth.bankIdStatus(data)
      if (response.status != 'pending') {
        this.clearTimer()
        if (response.token) {
          this.setStatus(Status.SUCCESS)
          this.onSuccess && this.onSuccess({ ...response, serviceType: this.serviceType })
        } else {
          this.setError(response.status)
        }
      } else if (!this.timer) {
        this.timer = setInterval(() => this.pollStatus(data), 2000)
      }
    } catch (err) {
      this.clearTimer()
      this.setStatus(Status.ERROR)
    }
  }

  verify = async (func, data) => {
    data = data || {}
    data.serviceType = this.serviceType
    let response = { status: 'pending' }

    this.setStatus(Status.PENDING)
    while (this.status == Status.PENDING) {
      const response = await func(data)

      if (response.session) data.session = response.session
      else if (response.status != 'pending') {
        if (response.token) {
          this.setStatus(Status.SUCCESS)
          this.onSuccess && this.onSuccess(response)
        } else {
          this.setError(response.status)
        }
      }

      await this.sleep(2000)
    }

    if (response.status != 'pending') {
      if (response.token) {
        this.setStatus(Status.SUCCESS)
        this.onSuccess && this.onSuccess(response)
      } else {
        this.setError(response.status)
      }
    }
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  cancel = () => {
    this.clearTimer()
    this.setStatus(Status.READY)
  }

  clearTimer = () => {
    clearInterval(this.timer)
    this.timer = null
  }

  setError = (error) => {
    this.status = Status.ERROR
    this.onError && this.onError(error)
  }

  setStatus = (status) => {
    this.status = status
    this.onStatus && this.onStatus(status)
  }

  dispose = () => {
    this.clearTimer()
  }
}

export { BankIdClient, Status, ServiceType }
export default BankIdClient
