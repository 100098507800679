import { extendObservable, runInAction, autorun } from 'mobx'
import agent from './Agent'

const CURRENT_TOKEN_KEY = 'pp_user'

const ApiStatus = Object.freeze({
  UNKNOWN: 0,
  WORKING: 1,
  ERROR: -1,
})

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

class AuthStore {
  constructor(parent) {
    const token = this.getStoredAccessToken()

    this.parent = parent
    this.pingTimer = null

    extendObservable(this, {
      loginType: null,
      accessToken: token,
      currentUser: null,
      refreshToken: null,

      apiStatus: false,
      apiError: false,

      getAccessToken() {
        return this.accessToken
      },

      getTokenData() {
        return this.accessToken && parseJwt(this.accessToken)
      },

      getAuthMethod() {
        const tokenData = this.getTokenData()
        let authMethod = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod']
        authMethod = authMethod && authMethod.toLowerCase()

        return authMethod
      },

      async setAccessToken(token, type) {
        if (token) {
          this.accessToken = token
          this.loginType = type
          window.localStorage.setItem(CURRENT_TOKEN_KEY, token)

          await this.updateCurrentUser()
          var user = this.currentUser
          var logged_in_event = new CustomEvent('logged-in', { detail: { name: user.firstname + ' ' + user.lastname } })
          document.dispatchEvent(logged_in_event)
        } else {
          window.localStorage.removeItem(CURRENT_TOKEN_KEY)
          this.accessToken = null
          this.loginType = null
        }
      },

      getCurrentUser() {
        return this.currentUser
      },

      setCurrentUser(user) {
        this.currentUser = user
      },

      isAuthenticated() {
        return this.accessToken != null
      },

      isTenant() {
        if (!this.isAuthenticated()) return false
        const user = this.currentUser

        return user && user.isTenant
      },

      useEmailAuth() {
        return parent.portal.info.get('emailAuth')
      },

      isBankId() {
        if (!this.isAuthenticated()) return false
        const user = this.currentUser

        return user && user.bankId
      },

      setApiWorking(working) {
        if (working) {
          this.apiStatus = ApiStatus.WORKING
        } else {
          this.apiStatus = ApiStatus.ERROR
        }
      },

      async checkApi() {
        agent.Auth.ping()
          .then(() => {
            this.setApiWorking(true)
          })
          .catch((err) => {
            // eslint-disable-line no-unused-vars
            if (err.status == 401) return
            this.setApiWorking(false)
          })
      },

      async updateCurrentUser() {
        let token = this.getAccessToken()
        if (!token) return

        let user
        try {
          user = await agent.Auth.current()
        } catch (err) {
          this.logout()
        }

        this.setCurrentUser(user)
      },

      async login(email, password) {
        this.apiError = false

        const response = await agent.Auth.login(email, password)
        await this.setAccessToken(response.accessToken, 'email').catch((err) => {
          if (err.status == 401) throw err

          this.apiError = true
        })
      },

      async loginBankId(data) {
        try {
          var session = await agent.Auth.bankIdLogin(data)
          let pending = true
          let response

          do {
            response = await agent.Auth.bankIdStatus({ session: session, ssn: data.ssn })

            if (response.token) {
              pending = false
              await this.setAccessToken(response.token)
              break
            }

            await sleep(2000)
          } while (pending)

          //await this.setAccessToken(token)
        } catch (err) {}
      },

      async logout() {
        this.setAccessToken(null)
        this.currentUser = null
        var logged_out_event = new CustomEvent('logged-out', { detail: 'Loggat ut' })
        document.dispatchEvent(logged_out_event)
      },

      async getBankIdToken() {
        const response = await agent.Auth.bankid()
        return response.accessToken
      },

      async setNewPassword(email, token, password) {
        return await agent.Auth.setNewPassword(email, token, password)
      },

      async requestNewPassword(email) {
        await agent.Auth.requestNewPassword(email)
        runInAction(() => {
          this.parent.ui.resetPasswordSuccess = true
        })
      },

      async getConsentDate(email, token) {
        const response = await agent.Auth.checkConsent(email, token)

        return response && response.consentDate
      },
    })

    autorun(this.checkApiStatus)
  }

  pollApi = () => {
    this.checkApi()

    if (this.pingTimer) {
      clearTimeout(this.pingTimer)
      this.pingTimer = null
    }

    if (this.apiStatus != ApiStatus.WORKING) {
      this.pingTimer = setTimeout(this.pollApi.bind(this), 10000)
    }
  }

  checkApiStatus = () => {
    const status = this.apiStatus

    if (status == ApiStatus.ERROR && !this.pingTimer) {
      this.pollApi()
    }
  }

  getStoredAccessToken() {
    let token

    try {
      token = window.localStorage.getItem(CURRENT_TOKEN_KEY)
    } catch (error) {
      token = null
    }

    return token
  }
}

export default AuthStore
