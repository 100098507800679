import { autorun, extendObservable } from 'mobx'
import agent from './Agent'

class UIStore {
  static initialState = {}
  static init(key, value) {
    if (UIStore.initialState[key] !== undefined) throw new Error('UIStore.init() {key} already defined')

    UIStore.initialState[key] = value
  }

  constructor(parent) {
    this.parent = parent

    extendObservable(this, {
      apiError: false,
      changePasswordSuccess: false,
      firstPage: false,
      resetPasswordSuccess: false,
      setPasswordSuccess: false,
      invalidLogin: false,
      registrationSuccess: true,
    })

    extendObservable(this, UIStore.initialState)

    autorun(this.checkIfFrontPage.bind(this))
  }

  checkIfFrontPage = () => {
    const store = this.parent
    const isAuth = store.auth.isAuthenticated()
    const display = this.firstPage && !isAuth ? 'block' : 'none'
    const elements = window.document.getElementsByClassName('pp-hide')
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = display
    }
  }
}

export default UIStore
