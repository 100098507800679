import { observable, extendObservable } from 'mobx'
import agent from './Agent'
import i18next from 'i18next'

class PortalStore {
  constructor(parent) {
    this.parent = parent

    extendObservable(this, {
      info: observable(new Map()),
    })
  }

  loadInfo = async () => {
    const info = await agent.Portal.info()
    this.info.replace(info)

    return this.info
  }

  getInfo = () => {
    return this.info
  }

  hasCapability = (capability) => {
    var capabilities = this.info.get('capabilities')
    if (!capabilities) return false

    return capabilities.includes(capability)
  }

  openRegistration = () => {
    return this.hasCapability('register')
  }

  isAgency = () => {
    return this.hasCapability('agency')
  }

  useAlreadyTenant = () => {
    return this.hasCapability('alreadyTenant')
  }

  useChat = () => {
    return this.hasCapability('chat')
  }

  useMedia = () => {
    return this.hasCapability('media')
  }

  useQueue = () => {
    return this.hasCapability('queue')
  }

  useReport = () => {
    return this.hasCapability('report')
  }

  showPayments = () => {
    return this.hasCapability('payments')
  }

  reportTitle = () => {
    let title = this.info.get('reportTitle')
    if (!title) return title

    const nameMapping = {
      hyresgastfelanmalan: i18next.t('report_title.tenant_report'),
    }

    let key = title.toLowerCase().replace(/[åä]/g, 'a').replace('ö', 'o').replace(' ', '_')
    return nameMapping[key] || title
  }

  getReportTitle = () => this.reportTitle()
}

export default PortalStore
