import React from 'react'
import AnimateHeight from 'react-animate-height'
import Icons from 'lib/icons'

class MessageBox extends React.Component {
  constructor(props) {
    super(props)

    this.mounted = true

    this.state = {
      visible: false,
      menuHeight: 'auto',
      animationStyle: {
        width: '0px',
        transition: '0ms',
      },
    }
  }

  UNSAFE_componentWillMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  resetBox = () => {
    const newState = {
      visible: false,
      menuHeight: 'auto',
      width: '0px',
      transition: '0ms',
    }

    this.setState(newState, () => {
      if (this.props.delayHide) {
        setTimeout(() => {
          if (!this._mounted) return
          this.setState({
            animationStyle: {
              width: '100%',
              transition: this.props.delayHide + 'ms',
            },
          })
        }, 1)

        setTimeout(() => {
          if (!this._mounted) return
          this.hideMessageBox()
        }, this.props.delayHide)
      }
    })
  }

  UNSAFE_componentWillReceiveProps() {
    this.resetBox()
  }

  componentDidMount() {
    this.resetBox()
  }

  hideMessageBox() {
    this.setState({
      visible: false,
      menuHeight: '0%',
      animationStyle: {
        width: '0px',
        transition: '0ms',
      },
    })
  }

  render() {
    let BoxType = this.props.type
    let { HideIcon } = this.props

    let ClassName
    let icon
    let role
    let IconContent

    role = 'alert'
    if (BoxType == 'info') {
      ClassName = 'info-box'
      icon = Icons.Check
    } else if (BoxType == 'error') {
      ClassName = 'error-box'
      icon = Icons.Exclamation
    } else if (BoxType == 'warning') {
      ClassName = 'warning-box'
      icon = Icons.Exclamation
    } else if (BoxType == 'success') {
      ClassName = 'success-box'
      icon = Icons.Check
    } else {
      ClassName = 'info-box'
      icon = Icons.Information
    }

    if (HideIcon != 'true') IconContent = <div className="box-icon">{icon}</div>

    let Content

    if (this.props.html) {
      Content = (
        <div
          className={'box-content' + (HideIcon != 'true' ? ' box-content-icon' : '')}
          dangerouslySetInnerHTML={{ __html: this.props.html }}
        />
      )
    } else {
      Content = <div className={'box-content' + (HideIcon != 'true' ? ' box-content-icon' : '')}>{this.props.children}</div>
    }

    const closeClick = (e) => {
      e.preventDefault()
      this.hideMessageBox()
    }

    const closeIcon = this.props.showCloseButton && (
      <a href="#" className="box-close" onClick={closeClick}>
        <img src={Icons.Close} alt="" />
      </a>
    )
    const container = (
      <div className={ClassName} role={role}>
        <div className="box-duration-bar" style={this.state.animationStyle}></div>
        {closeIcon}
        {IconContent}
        {Content}
      </div>
    )

    let returnContent
    if (this.state.visible) {
      returnContent = container
    } else {
      returnContent = (
        <AnimateHeight duration={300} height={this.state.menuHeight}>
          {container}
        </AnimateHeight>
      )
    }

    return returnContent
  }
}

export default MessageBox
