import React from 'react'

class Button extends React.Component {
  render() {
    const { children, status, ...rest } = this.props

    return (
      <button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={status == 'processing'} className="main-btn" {...rest}>
        {children}
      </button>
    )
  }
}

export default Button
