import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import Utils from 'lib/utils'
import agent from 'Stores/Agent'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const datefmt = Utils.datefmt

const PaymentRow = (props) => {
  let amountDueStyle = { color: 'black', fontWeight: 'bold', textAlign: 'center' }
  let reminderRowStyle = { backgroundColor: '#fff5f5', fontSize: 'small', textAlign: 'right' };
  
  if (props.amountLeft > 0) {
    if (new Date(props.dueDate) < new Date()) amountDueStyle.backgroundColor = '#f66'
    else amountDueStyle.backgroundColor = '#ff7'
  }

  const reminderRow = props.notifyDate ? (
    <tr style={reminderRowStyle}>
      <td colspan="6">
        {props.notifyCount} reminders sent, last on {datefmt(props.notifyDate, 'yyyy-MM-dd')}
      </td>
    </tr>
  ) : null;

  return (
    <>
    <tr>
      <td data-title={i18next.t('label.invoice_number')}>{props.invoiceNumber}</td>
      <td data-title={i18next.t('label.payment_ref')}>{props.paymentRef}</td>
      <td data-title={i18next.t('label.invoice_date')}>
        {datefmt(props.invoiceDate, 'yyyy-MM-dd') || i18next.t('label.invoice_date_not_set')}
      </td>
      <td data-title={i18next.t('label.due_date')}>{datefmt(props.dueDate, 'yyyy-MM-dd') || i18next.t('label.due_date_not_set')}</td>
      <td data-title={i18next.t('label.amount_due')} style={{ textAlign: 'center' }}>
        {props.amount} kr
      </td>
      <td data-title={i18next.t('label.remaining_amount_due')} style={amountDueStyle}>
        {props.amountLeft} kr
      </td>
    </tr>
    {reminderRow}
    </>
  )
}

const PaymentsTable = (props) => {
  let { payments } = props
  let rows = []

  payments && payments.map((p, i) => rows.push(<PaymentRow {...p} key={i} />))

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <td>
            <strong>
              <Trans>label.invoice_number</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.payment_ref</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.invoice_date</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.due_date</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.amount_due</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.remaining_amount_due</Trans>
            </strong>
          </td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const PaymentsPanel = (props) => (
  <div style={{ maxWidth: '300px' }}>
    <div>
      <ul class="simple-list mb-small" style={{ borderBottom: 0 }}>
        <li>
          <strong>
            <Trans>label.name</Trans>:
          </strong>
          <span style={{ float: 'right' }}>{props.name}</span>
        </li>
        <li>
          <strong>
            <Trans>label.customer_number</Trans>:
          </strong>
          <span style={{ float: 'right' }}>{props.customerNumber}</span>
        </li>
      </ul>
    </div>
  </div>
)

const PaymentsPage = () => {
  const [loading, setLoading] = useState(true)
  const [payments, setPayments] = useState(null)
  const [contracts, setContracts] = useState([])
  const [noContracts, setNoContracts] = useState(true)
  const [contract, setContract] = useState([])

  const getPayments = (contractId) => {
    agent.Contracts.getPayments(contractId).then((payments) => {
      setPayments(payments)
      setLoading(false)
    })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    async function getContracts() {
      const contractsResult = await agent.Contracts.get()
      setContracts(contractsResult.contracts)
      if (contractsResult.contracts.length > 0) {
        setContract(contractsResult.contracts[0].id);
        setNoContracts(false);
        getPayments(contractsResult.contracts[0].id);
        setLoading(false);
      }
      else {
        setNoContracts(true);
      }
    }

    getContracts()
  }, [])

  let contractList = [];
  contracts && contracts.map((p, i) => contractList.push({ value: p.id, label: p.number }));

  const handleContractChange = (e) => {
    setContract(e.value);
    getPayments(e.value)
  };

  if (loading) return <div>Loading...</div>
  if (noContracts) return <div><Trans>media.no_contracts</Trans></div>

  let showPayments = true;
  let name = '';
  let customerNumber = '';
  let templateText = '';
  if (payments === null || payments?.data == null) {
    showPayments = false;
  }
  else {
    name = payments?.data.name
    customerNumber = payments?.data.customerNumber
    templateText = payments?.information
  }


  return (
    <div>
      <h1>
        <Trans>pages.payments</Trans>
      </h1>

      {contractList.length !== 1 && (
        <div className="form-group field field-string">
          <span className="formLabel"><Trans>media.select_contract</Trans>:</span>
          <div className="input_container">
            <div className="cascade-select">
              <Dropdown options={contractList} value={contract} placeholder={i18next.t('media.select_contract_placeholder')} onChange={handleContractChange} />
            </div>
          </div>
        </div>
      )}

      <br></br>

      {!showPayments && <div><Trans>payment.payments_not_found</Trans></div>}
      {showPayments && <PaymentsPanel name={name} customerNumber={customerNumber} />}
      {showPayments && <PaymentsTable payments={payments.data.payments} />}
      <div dangerouslySetInnerHTML={{ __html: templateText }} />
    </div>
  )
}

export default PaymentsPage
