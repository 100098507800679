import React from 'react'
import Slider from 'react-slick'

import { ReactComponent as IconChevronLeftThin } from 'Images/SVG/chevron-left.svg'
import { ReactComponent as IconChevronRightThin } from 'Images/SVG/chevron-right.svg'
import i18next from "i18next";

const Arrow = ({ type, className, style, onClick }) => {
  let arrow, cls, label
  if (type == 'left') {
    cls = 'prev-button'
    arrow = <IconChevronLeftThin />
    style = { ...style, display: 'block' }
    label = i18next.t('carousel.prev')
  } else {
    cls = 'next-button'
    arrow = <IconChevronRightThin />
    label = i18next.t('carousel.next')
  }

  return (
    <button onClick={onClick} className={'full-height-button ' + cls} aria-label={label}>
      <div className={className} style={{ ...style }}>
        <span>{arrow}</span>
      </div>
    </button>
  )
}

const CarouselImage = ({ image, description }) => {
  const src = 'https://aepfnqkden.cloudimg.io/width/760/x/' + image

  return (
    <div style={{ position: 'relative' }}>
      <img style={{ position: 'relative' }} className="pp-responsive-image" src={src} />
      {description && <div className="slick-image-description">{description}</div>}
    </div>
  )
}

const Carousel = ({ ImageList }) => {
  //const ImageList = testImageData
  if (ImageList.length == 0) return false

  const CarouselSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <Arrow type="left" />,
    nextArrow: <Arrow type="right" />,
    customPaging: function paging(i) {
      return (
        <a
          href="# "
          className="thumbnail"
          title={ImageList[i].description}
          style={{ backgroundImage: 'url(' + ImageList[i].source + ')' }}
          aria-label={i18next.t('carousel.aria_label',{nr:(i + 1)})}
        ></a>
      )
    },
    dotsClass: 'slick-dots slick-thumb',
  }

  let rows = []
  ImageList.forEach((p, i) => {
    rows.push(<CarouselImage {...p} key={i} image={p.source} description={p.description} />)
  })

  return (
    <div style={{ position: 'relative', paddingBottom: '65px' }}>
      <Slider {...CarouselSettings}>{rows}</Slider>
    </div>
  )
}

export default Carousel
