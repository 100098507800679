import React from 'react'
import MessageBox from 'Components/MessageBox';
import i18next from "i18next";

class ErrorHandling extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      severity: props.severity,
      message: props.message,
    }
  }

  handleClose = (e) => {
    this.props.onClose(e);
  }

  render() {
    return (this.props.open && (<MessageBox type={this.props.severity} showCloseButton={true} delayHide={3000}>{this.props.message}</MessageBox>));
  }
}

const ErrorHandlingHelper = {
  getDefaultState: function () {
    return {
      message: '',
      severity: 'success',
      open: false,
    }
  },

  show: function (message, severity) {
    return {
      open: true,
      message: message,
      severity: (typeof (severity) == 'undefined' ? 'success' : severity),
    }
  },

  close: function () {
    return {
      open: false,
      message: '',
      severity: 'success',
    }
  },

  error: function (exception, startText) {
    var retVal = {
      message: (typeof (startText) != 'undefined' ? startText + ' ' : ''),
      severity: 'error',
      open: true,
    };

    var bAddedDescription = false;


    if (exception && exception.response && exception.response.body && exception.response.body.errors) { // Från API
      for (var i = 0; i < exception.response.body.errors.length; i++) {
        bAddedDescription = true;
        retVal.message += exception.response.body.errors[i].message;
        retVal.severity = exception.response.body.errors[i].severity;
      }
    }
    else if (exception && exception.response && exception.response.status == 401) {
      retVal.message += i18next.t('error.401')
      bAddedDescription = true;
    } else if (exception && exception.response && exception.response.data != '') {
      var rxSecurity = RegExp('(UDEStandard.GLOBAL.Exception.SecurityException):.*\'(.*)\', accessLevel (\\d)', 'gi');
      var matches = rxSecurity.exec(exception.response.data);
      if (matches && matches.length == 4) {
        var sLevel = '';
        var iLevel = parseInt(matches[3], 10);
        switch (iLevel) {
          case 1: sLevel = i18next.t('auth.action.show'); break;
          case 2: sLevel = i18next.t('auth.action.create'); break;
          case 4: sLevel = i18next.t('auth.action.remove'); break;
          case 8: sLevel = i18next.t('auth.action.update'); break;
          case 16: sLevel = i18next.t('auth.action.administrate'); break;
          default: sLevel = i18next.t('auth.action.another', {level:iLevel}); break;
        }

        retVal.message += i18next.t('auth.action.fail', {level:sLevel, target:matches[2]});
        bAddedDescription = true;
      } else {
        bAddedDescription = true;
        retVal.message += exception.response.data;
      }
    }

    if (!bAddedDescription)
      retVal.message += exception.message;

    return retVal;
  }
}

export { ErrorHandling, ErrorHandlingHelper };
