import React from 'react'

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      folded: props.open != true,
    }
  }

  onComponentWillMount() {
    this.setState({
      folded: true,
    })
  }

  changeFolding() {
    this.setState({
      folded: !this.state.folded,
    })
  }

  render() {
    let className

    if (this.state.folded) {
      className = 'accordion-folded'
    } else {
      className = ''
    }

    return (
      <div className={'accordion-container ' + className}>
        <button className="accordion-header" onClick={this.changeFolding.bind(this)}  aria-expanded={!this.state.folded}>
          <span className="accordion-header-icon">{this.props.icon}</span>
          <strong>{this.props.header}</strong>
        </button>
        <div className="accordion-body">{this.props.children}</div>
      </div>
    )
  }
}

export default Accordion
