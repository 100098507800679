import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

import translationSwe from 'locales/sv/translation.json'
import translationScherdins from 'locales/sv-scherdins/translation.json'
import translationSkargardsstiftelsen from 'locales/sv-skargardsstiftelsen/translation.json'
import translationEng from 'locales/en/translation.json'

var lang = 'sv'

if (process.env.REACT_APP_FORCE_LANGUAGE)
  lang = process.env.REACT_APP_FORCE_LANGUAGE

//lang = 'scherdins'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  //.use(ChainedBackend)
  //.use(LanguageDetector)
  .init({
    debug: false,
    lng: lang,
    fallbackLng: 'sv',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      'scherdins': { translations: translationScherdins },
      'skargardsstiftelsen': { translations: translationSkargardsstiftelsen },
      'sv': { translations: translationSwe },
      'en': { translations: translationEng },
    },

    /*
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((lng, ns, clb) => {
          import(`./locales/${lng}/translation.json`)
            .then((resources) => clb(null, resources))
            .catch(clb)
        })
      ],

      backendOptions: [{
        loadPath: 'locales/{{lng}}.json'
      }],
    },
    */

    // Have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    react: {
      useSuspense: true
    },
  })

export default i18n
