import React from 'react'
import JSONForm from 'react-jsonschema-form'

import CustomFieldTemplate from './CustomFieldTemplate'
import CustomObjectFieldTemplate from './CustomObjectFieldTemplate'
import CascadeSelectField from './CascadeSelectField'
import AgreeWidget from './Widgets/AgreeWidget'
import DateWidget from './Widgets/DateWidget'
import EmailChangeWidget from './Widgets/EmailChangeWidget'
import FileWidget from './Widgets/FileWidget'
import FileDropWidget from './Widgets/FileDropWidget'
import ReadOnlyWidget from './Widgets/ReadOnlyWidget'
import TelWidget from './Widgets/TelWidget'
import TreeSelectWidget from './Widgets/TreeSelectWidget'
import MessageBox from 'Components/MessageBox'
import Button from 'Components/Button'

import { Root as store } from 'Stores'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const widgets = {
  agree: AgreeWidget,
  date: DateWidget,
  tel: TelWidget,
  file: FileDropWidget,
  'tree-select': TreeSelectWidget,
  readonly: ReadOnlyWidget,
  'email-change': EmailChangeWidget,
}

const fields = {
  cascader: CascadeSelectField,
}

function fillPropertiesFromOptions(options, props) {
  if (!props) props = {}

  options.forEach((o) => {
    props[o.type] = { type: 'string' }
  })

  if (options.children) fillPropertiesFromOptions(options.children, props)
}

function GenerateJSONForm(json, formData) {
  let formDef = {
    schema: {
      type: 'object',
      title: json.title || undefined,
      description: json.description || undefined,
      required: [],
      properties: {},
    },
    uiSchema: {
      'ui:order': [],
    },
    formData: formData,
  }

  const fieldRequirement = {}

  if ('groups' in json) {
    json.fields = []
    formDef.uiSchema['ui:grouping'] = []
    json.groups.forEach((group) => {
      json.fields = json.fields.concat(group.fields)

      formDef.uiSchema['ui:grouping'].push({
        title: group.title,
        fields: group.fields.map((field) => field.name),
      })
    })
  }

  json.fields.forEach((field) => {
    const name = field.name
    const uiOptions = {}
    let type = field.type
    let uiWidget

    formDef.schema.properties[name] = {}
    formDef.uiSchema[name] = {}

    const lowerName = name.toLowerCase()

    if (!type) {
      if (field.rows) type = 'textarea'
      else if (field.tree) type = 'tree'
      else if (field.items) type = 'array'
      else if (lowerName.indexOf('password') !== -1) type = 'password'
      else if (lowerName.indexOf('date') !== -1) type = 'date'
      else if (lowerName.indexOf('email') !== -1) type = 'email'
      else if (lowerName.indexOf('phone') !== -1) type = 'tel'
      else if (lowerName.indexOf('file') !== -1) type = 'file'
      else type = 'string'
    }

    switch (type) {
      case 'tree':
        uiWidget = 'tree-select'
        type = 'string'
        break
      case 'array':
        break
      case 'textarea':
        uiWidget = 'textarea'
        type = 'string'
        break
      case 'password':
        uiWidget = 'password'
        type = 'string'
        break
      case 'date':
        uiWidget = 'date'
        type = 'string'
        break
      case 'email':
        field.format = 'email'
        type = 'string'
        break
      case 'file':
        uiWidget = FileWidget
        type = 'string'
        break
      case 'tel':
        uiWidget = TelWidget
        type = 'string'
        break
      case 'integer':
      case 'number':
        break
      default:
    }

    if (field.widget) uiWidget = field.widget

    if (field.rows) {
      uiOptions['rows'] = field.rows
    }
    if (field.options) {
      Object.assign(uiOptions, field.options)
    }

    Object.assign(formDef.schema.properties[name], { ...field, type })
    formDef.schema.properties[name] = {
      name: field.name,
      title: field.title,
      type: type,
    }

    if (field.tree) {
      formDef.schema.properties[name].items = {
        type: 'string',
        enum: field.tree,
      }
    } else if (type === 'array') {
      if (typeof field.items === 'object') {
        formDef.schema.properties[name].items = {
          enum: Object.keys(field.items),
          enumNames: Object.values(field.items),
        }
      } else {
        formDef.schema.properties[name].items = {
          enum: field.items,
        }
      }

      formDef.schema.properties[name].type = 'array'
      formDef.schema.properties[name].uniqueItems = typeof field.uniqueItems !== 'undefined' ? field.uniqueItems : true
    }

    if (field.format) {
      formDef.schema.properties[name].format = field.format
    }

    if (field.min || field.max || field.step) {
      if (field.min != undefined) formDef.schema.properties[name].minimum = field.min
      if (field.max != undefined) formDef.schema.properties[name].maximum = field.max
      if (field.step != undefined) formDef.schema.properties[name].multipleOf = field.step
    }

    let fieldOptions

    if ('options' in field && field['widget'] != 'agree') {
      fieldOptions = field.options || {}
      if (fieldOptions.constructor === Object) {
        formDef.schema.properties[name].enum = Object.keys(fieldOptions)
        formDef.schema.properties[name].enumNames = Object.values(fieldOptions)
      } else {
        formDef.schema.properties[name].enum = fieldOptions
      }
    }

    if (field.optional) fieldRequirement[name] = 'optional'
    else if (field.required) fieldRequirement[name] = 'required'

    formDef.uiSchema['ui:order'].push(field.name)

    if (field.placeholder) formDef.uiSchema[name]['ui:placeholder'] = field.placeholder

    if (field.field) {
      formDef.uiSchema[name]['ui:field'] = field.field

      const props = {}
      fillPropertiesFromOptions(fieldOptions, props)

      formDef.schema.properties[name] = {
        type: 'object',
        options: fieldOptions,
        title: field.title,
        properties: props,
      }
    }

    if (uiWidget) formDef.uiSchema[name]['ui:widget'] = uiWidget
    if (uiOptions.constructor === Object && Object.keys(uiOptions).length !== 0) formDef.uiSchema[name]['ui:options'] = uiOptions
  })

  const values = Object.values(fieldRequirement)
  const nRequired = values.filter((v) => v === 'required').length
  const nOptional = values.filter((v) => v === 'optional').length
  const defaultRequirement = nOptional > nRequired

  json.fields.forEach((f) => {
    const r = fieldRequirement[f.name]
    if (f['widget'] != 'readonly' && (r === 'required' || (defaultRequirement && r !== 'optional'))) formDef.schema.required.push(f.name)

    formDef.uiSchema[f.name].hideRequired = json.hideRequired
    formDef.uiSchema[f.name].readonly = f['widget'] == 'readonly' || f['widget'] == 'email-change'
  })

  return formDef
}

const TranslateMessage = (message) => {
  if (!message.match(/.+\..+/)) return message

  return i18next.t(message)
}

function ErrorListTemplate(props) {
  const { errors } = props
  const { schema } = props

  return (
    <MessageBox type="error">
      <ul className="error-list">
        {errors.map((error, i) => {
          return (
            <li key={i}>
              <label htmlFor={'root_' + error.property.substring(1)}>
                &quot;{schema.properties[error.property.substring(1)].title}&quot; {TranslateMessage(error.message)}
              </label>
            </li>
          )
        })}
      </ul>
    </MessageBox>
  )
}

const ApiErrorList = ({ errors, schema }) => {
  return (
    <MessageBox type="error">
      <ul className="error-list">
        {errors.map((error, i) => {
          const property = schema.properties[error.property]

          return (
            <li key={i}>
              <label htmlFor={'root_' + error.property}>
                {property && `"${property.title}"`} {TranslateMessage(error.message)}
              </label>
            </li>
          )
        })}
      </ul>
    </MessageBox>
  )
}

function transformErrors(errors) {
  return errors.map((error) => {
    if (error.name === 'required') {
      error.message = i18next.t('error.required_field')
    }
    if (error.params.format === 'email') {
      error.message = i18next.t('email_format')
    }
    if (error.params.type === 'integer') {
      error.message = i18next.t('integer_format')
    }
    return error
  })
}

class Form extends React.Component {
  static FormStatus = Object.freeze({
    Idle: 'idle',
    Processing: 'processing',
    Success: 'success',
    Error: 'error',
  })

  constructor(props) {
    super(props)

    this.state = {
      apiErrors: [],
      formData: props.formData,
      status: Form.FormStatus.Idle, //['success', 'processing', 'error'],
    }
  }

  componentDidMount = () => {
    this.setState({
      formData: this.props.formData,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ formData: nextProps.formData })
  }

  onChange = (data) => {
    this.setState(
      Object.assign(data, {
        status: Form.FormStatus.Idle,
      })
    )

    this.props.onChange && this.props.onChange(data)
  }

  onError = () => {
    this.setState({
      status: Form.FormStatus.Error,
    })
  }

  onSuccess = (res) => {
    this.setState({
      status: Form.FormStatus.Success,
    })

    this.props.onSuccess && this.props.onSuccess(res)
  }

  onSubmit = async (form) => {
    this.setState({
      apiErrors: [],
      status: Form.FormStatus.Processing,
    })

    if (!this.props.onSubmit) {
      this.onSuccess()
    }

    try {
      var result = await this.props.onSubmit(form)
      this.onSuccess(result)
    } catch (error) {
      if (error.status == 400) {
        this.onError(error.response.body.errors)
        this.setState({
          apiErrors: error.response.body.errors,
        })
      }
    }
  }

  render() {
    const props = this.props
    const { autocomplete, children, hideOnSuccess, schema, submit, rowLayout, uiSchema } = props
    const { formData, status } = this.state
    let beforeButton = props.beforeButton

    let submitButton = null
    let buttonStatus

    if (store.auth.apiStatus == -1) buttonStatus = Form.FormStatus.Processing
    else buttonStatus = status

    if (submit) {
      if (React.isValidElement(submit)) submitButton = submit
      else submitButton = <Button status={status}>{submit || i18next.t('label.save')}</Button>

      submitButton = React.cloneElement(submitButton, {
        status: buttonStatus,
      })
    }

    let errors
    if (this.state.apiErrors.length > 0) {
      errors = <ApiErrorList schema={schema} errors={this.state.apiErrors} />
    }

    let successBox
    if (status == Form.FormStatus.Success && this.props.successBox !== false) {
      successBox = this.props.successBox || (
        <MessageBox delayHide="5000" type="success">
          <Trans>info.saved</Trans>
        </MessageBox>
      )
    }

    let className = 'rjsf'
    if (rowLayout) className += ' row-layout'

    const form = (
      <JSONForm
        key={0}
        className={className}
        transformErrors={transformErrors}
        showErrorList={true}
        ErrorList={ErrorListTemplate}
        fields={fields}
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onError={this.onError}
        autocomplete={autocomplete || 'off'}
        FieldTemplate={CustomFieldTemplate}
        ObjectFieldTemplate={CustomObjectFieldTemplate}
        widgets={widgets}
        noHtml5Validate={true}
      >
        {beforeButton}
        {successBox}
        {errors}
        {submitButton}
        {children}
      </JSONForm>
    )

    let content = []
    if (!(status == Form.FormStatus.Success && hideOnSuccess)) content.push(form)
    else content.push(successBox)

    return <React.Fragment>{content}</React.Fragment>
  }
}

class FormWrapper extends React.Component {
  render() {
    const { schema, formData, ...rest } = this.props
    const formSchema = GenerateJSONForm(schema, formData)

    if (this.props.loading)
      return (
        <span>
          <Trans>loading</Trans>
        </span>
      )

    return <Form {...rest} {...formSchema} />
  }
}

export default FormWrapper
