import React from 'react'
import { Status, ServiceType } from 'lib/bankid'
import ClipLoader from 'react-spinners/ClipLoader'
import { ReactComponent as ImgBankID } from '../Images/SVG/bankid_2_white.svg'
import { ReactComponent as ImgFrejaEID } from '../Images/SVG/frejaeid.svg'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const STATUS = Status

const BankIdButton = ({ type, status, onStart, onSubmit, onCancel, onRetry, error, submitText, cancelText, retryText }) => {
  let onClick, text
  if (status == STATUS.READY) {
    onClick = onSubmit
    text = submitText || i18next.t('bank_id.button.ready')
  } else if (status == STATUS.PENDING) {
    onClick = onCancel
    text = cancelText || i18next.t('bank_id.button.pending')
  } else if (status == STATUS.ERROR) {
    onClick = onRetry
    text = retryText || i18next.t('bank_id.button.error')
  }

  const Logo = type == ServiceType.FrejaId ? ImgFrejaEID : ImgBankID
  const isBankId = type == ServiceType.BankId

  const onClickStart = (e) => {
    e.preventDefault()
    onStart()
  }

  return (
    <React.Fragment>
      {status == STATUS.ERROR && (
        <React.Fragment>
          <p style={{ color: 'red' }} role="alert">
            {error}
          </p>
        </React.Fragment>
      )}
      {status == STATUS.PENDING && isBankId && (
        <React.Fragment>
          <div style={{ marginBottom: '10px' }}>
            <button onClick={onClickStart} className="main-btn">
              <Trans>bank_id.button.pending_helper</Trans>
            </button>
          </div>
        </React.Fragment>
      )}
      <div>
        <button style={{ position: 'relative' }} type="button" onClick={onClick} className="main-btn">
          {status == STATUS.PENDING && (
            <div style={{ position: 'absolute', left: 8, marginTop: 2 }}>
              <ClipLoader size={32} color="#ffffff" loading />
            </div>
          )}
          <Logo style={{ width: '32px', height: '32px', paddingRight: '12px' }} />
          {text}
        </button>
      </div>
    </React.Fragment>
  )
}

export default BankIdButton
