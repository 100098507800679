import Cases from './Pages/CasesPage.js'
import ChangePassword from './Pages/ChangePasswordPage.js'
import Contract from './Pages/ContractPage.js'
import DeleteProfile from './Pages/DeleteProfilePage.js'
import Inline from './Pages/InlinePage.js'
import InterestedPage from './Pages/InterestedPage.js'
import LoggedIn from './Pages/LoggedIn.js'
import Login from './Pages/LoginPage.js'
import Logout from './Pages/LogoutPage.js'
import Media from './Pages/MediaPage.js'
import Payments from './Pages/PaymentsPage.js'
import Profile from './Pages/ProfilePage.js'
import Properties from './Pages/PropertiesPage.js'
import Register from './Pages/RegisterPage.js'
import Report from './Pages/ReportPage.js'
import ResetPassword from './Pages/ResetPasswordPage.js'
import Objects from './Pages/ObjectsPage.js'
import Offers from './Pages/OffersPage.js'
import PropertySubscription from './Pages/PropertySubscriptionPage.js'
import Subscription from './Pages/SubscriptionPage.js'
import TenantRegister from './Pages/TenantRegisterPage.js'
import Welcome from './Pages/WelcomePage.js'
import Chat from './Pages/ChatPage.js'

export default {
  Cases,
  ChangePassword,
  Contract,
  DeleteProfile,
  Inline,
  InterestedPage,
  LoggedIn,
  ResetPassword,
  Login,
  Logout,
  Media,
  Objects,
  Offers,
  Payments,
  Profile,
  Properties,
  PropertySubscription,
  Register,
  Report,
  Subscription,
  TenantRegister,
  Welcome,
  Chat,
}
