import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { inject } from 'mobx-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import observer from 'Decorators/Observer'
import Accordion from 'Components/Accordion'
import ModalWindow from 'Components/ModalWindow'
import Icons from 'lib/icons'
import Utils from 'lib/utils'
import SubscriptionForm from './Subscription/SubscriptionForm'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const queue2name = Utils.queue2name
let GetIcon = Utils.GetIcon

const EditSubscriptionButton = (props) => {
  const onClick = (e) => {
    e.preventDefault()
    props.onClick()
  }

  return (
    <div className="">
      <button className="secondary-btn" onClick={onClick}>
        <span className="secondary-btn__icon">{Icons.Pern}</span> <Trans>subscription.add_or_change</Trans>
      </button>
    </div>
  )
}

const SubscriptionBox = ({ comment, maxRent, store, type, ...props }) => {
  const mapping = store.queue.getNameMapping()
  let areas = props.areas && props.areas.map((id) => mapping.areas[id]).sort()
  const classes = props.objectClasses && props.objectClasses.map((id) => mapping.classes[id]).sort()
  const other = []
  const isAgency = store.portal.isAgency()
  const usePrice = type == 'plot'

  if (!areas) areas = []

  if (props.groundFloor) other.push(i18next.t('label.ground_floor'))
  if (props.elevator) other.push(i18next.t('label.elevator'))
  if (props.balcony) other.push(i18next.t('label.balcony'))

  return (
    <React.Fragment>
      <Accordion icon={<GetIcon id={type} />} header={queue2name(type)} open={true}>
        <table className="pp-basic-data-table mb-small">
          <tbody>
            <tr>
              <th style={{ width: '35%' }} scope="row">
                <strong>
                  <Trans>label.subscribed</Trans>:
                </strong>
              </th>
              <td>{props.subscribe ? i18next.t('yes') : i18next.t('no')}</td>
            </tr>
            <tr>
              <th style={{ width: '35%' }} scope="row">
                <strong>
                  <Trans>label.areas</Trans>:
                </strong>
              </th>
              <td>{(areas && areas.join(', ')) || i18next.t('all')}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>
                  <Trans>label.type</Trans>
                </strong>
              </th>
              <td>{(classes && classes.join(', ')) || i18next.t('all')}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>
                  {usePrice && <Trans>label.max_price</Trans>}
                  {!usePrice && <Trans>label.max_rent</Trans>}
                </strong>
              </th>
              <td>{(maxRent && `${maxRent} kr`) || i18next.t('not_set')}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>
                  <Trans>label.others</Trans>
                </strong>
              </th>
              <td>{other && other.join(', ')}</td>
            </tr>
            {!isAgency && (
              <tr>
                <th scope="row">
                  <strong>
                    <Trans>label.other_wants</Trans>
                  </strong>
                </th>
                <td>{comment}</td>
              </tr>
            )}
          </tbody>
        </table>
        <EditSubscriptionButton onClick={props.onEdit} />
      </Accordion>
    </React.Fragment>
  )
}

const Subscriptions = (props) => {
  const { store } = props
  const subscriptions = props.subscriptions
  const boxes = []

  Object.entries(subscriptions).forEach((entry) => {
    const type = entry[0]
    const subscription = entry[1]
    if (!subscription.applicationDate) return
    subscription['type'] = type
    boxes.push(<SubscriptionBox key={type} onEdit={() => props.onEdit(subscription)} {...subscription} store={store} />)
  })

  return (
    <div>
      <p>
        <Trans>subscription.text</Trans>
      </p>
      {boxes}
    </div>
  )
}

const EditSubscriptionModal = ({ subscription, queue, onClose, onSubmit, useTree }) => {
  return (
    <ModalWindow isOpen={subscription != null} title={i18next.t('subscription.edit')} onClose={onClose}>
      <Tabs>
        <TabList>
          <Tab>
            <GetIcon id={subscription.type} />
            {queue2name(subscription.type)}
          </Tab>
        </TabList>

        <TabPanel>
          <SubscriptionForm
            subscription={subscription}
            queue={queue}
            onSubmit={(values) => onSubmit(subscription.queueId, values)}
            useTree={useTree}
          />
        </TabPanel>
      </Tabs>
    </ModalWindow>
  )
}

const SubscriptionPage = ({ store }) => {
  const [editSubscription, setEditSubscription] = useState()

  useEffect(() => {
    store.queue.getQueues()
    store.queue.loadSubscriptions()
  }, [store.queue])

  const onClose = () => setEditSubscription(null)

  const onSubmit = (queueId, values) => {
    const subscription = Object.assign(
      {},
      {
        queueId: queueId,
        areas: values.areas,
        objectClasses: values.objectClasses,
        maxRent: values.maxRent,
        elevator: values.elevator,
        groundFloor: values.groundFloor,
        comment: values.comment,
        subscribe: values.subscribe,
      }
    )

    return store.queue.setSubscription(subscription).then(async () => {
      await store.queue.loadSubscriptions()
      onClose()
    })
  }

  const onEdit = (subscription) => {
    setEditSubscription(subscription)
  }

  const subscriptions = toJS(store.queue.subscriptions)
  const queues = toJS(store.queue.queues)
  const isAgency = store.portal.isAgency()
  const queue = editSubscription && queues[editSubscription.type]

  return (
    <div>
      <Subscriptions onEdit={onEdit} subscriptions={subscriptions} store={store} />
      {editSubscription && (
        <EditSubscriptionModal subscription={editSubscription} queue={queue} useTree={!isAgency} onClose={onClose} onSubmit={onSubmit} />
      )}
    </div>
  )
}

export default inject('store')(observer(SubscriptionPage))
