import React from 'react'
import Map from 'Components/Map'
import Utils from 'lib/utils'

import { ReactComponent as IconStar } from 'Images/SVG/star.svg'
import { ReactComponent as IconComment } from 'Images/SVG/comment.svg'

import { FactTable } from './FactTable'
import Carousel from './Carousel'
import FileTable from './FileTable'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const apiUrl = Utils.apiUrl

const InterestedButton = ({ object, showInterest }) => {
  let pending = object.interested == true

  if (object.status != 'application') return false

  let content = []
  if (pending) {
    content.push(
      <button onClick={() => showInterest(object, !pending)} className="main-btn" style={{ textAlign: 'center' }}>
        <Trans>label.undo_interest</Trans>
      </button>
    )
  } else {
    content.push(
      <button onClick={() => showInterest(object, !pending)} className="main-btn" style={{ textAlign: 'center' }}>
        <IconStar />
        <Trans>label.add_interest</Trans>
      </button>
    )
  }

  return content
}

const FactsFilesAndMap = ({ markers, object }) => {
  const files = []
  object.documents.forEach((doc) => {
    files.push({ ...doc, url: apiUrl('files/' + doc.id) })
  })

  return (
    <React.Fragment>
      <FileTable files={object.documents} />
      <h2>
        <Trans>label.facts</Trans>
      </h2>
      <div className="panel mb-large">
        <div>
          <FactTable data={object.objectData} />
        </div>
      </div>

      <h2>
        <Trans>label.map</Trans>
      </h2>
      {markers && <Map markers={markers} zoom={15} />}
    </React.Fragment>
  )
}

const ContactInformation = ({ object, showingInfo }) => {
  if (object.status != 'offered') return false

  showingInfo = showingInfo || i18next.t('object_info.showing_info')
  let contact = object.contactInfo || i18next.t('object_info.contact_info')

  return (
    <div className="mb-large box">
      <div className="mb-small">
        <h3 style={{ marginBottom: '5px' }}>
          <Trans>object_info.header</Trans>
        </h3>
        <p>{showingInfo}</p>
      </div>
      <div className="pp-flex pp-flex-padding pp-flex-margin pp-flex-break-for-mobile">
        <div className="pp-flex-item pp-flex-item-strict">
          <div className="conversation-bubble">
            <IconComment className="conversation-icon" />
            <IconComment className="conversation-icon" />
          </div>
        </div>
        <div className="pp-flex-item">
          <div style={{ overflow: 'hidden' }}>
            <strong className="pp-flex-hide-overflow" style={{ marginBottom: '0px', display: 'block' }}>
              <Trans>label.contact_info</Trans>:
            </strong>
            <div dangerouslySetInnerHTML={{ __html: contact }} />
          </div>
        </div>
      </div>
    </div>
  )
}

const GetAddressString = (object) => {
  let address

  if (object.address) {
    address = object.address.address1
    if (object.address.address2) address += ', ' + object.address.address2
    if (object.address.zip) address += ', ' + object.address.zip
    if (object.address.city) address += ', ' + object.address.city
  }

  return address
}

const ObjectInfo = ({ object, ...props }) => {
  const address = GetAddressString(object)

  const markers = []

  if (object.address && object.address.latitude && object.address.longitude) {
    const { latitude, longitude } = object.address
    markers.push({ latitude, longitude, title: address })
  }

  if (!object.images) object.images = []
  if (!object.documents) object.documents = []

  const images = object.images.map((i) => {
    return { source: apiUrl('files/' + i.id), filename: i.name, description: i.description }
  })

  return (
    <div>
      {!props.showContactOnly && (
        <React.Fragment>
          <Carousel ImageList={images} />

          <div className="pp-flex mt-small pp-flex-break-for-mobile pp-flex-margin">
            <div className="pp-flex-item">
              <h1 className="" style={{ marginBottom: '0' }}>
                {address}
              </h1>
            </div>
            <InterestedButton className="pp-flex-item pp-flex-item-strict" object={object} showInterest={props.showInterest} />
          </div>
          <hr />
        </React.Fragment>
      )}

      <ContactInformation object={object} showingInfo={props.showingInfo} />
      {!props.showContactOnly && <FactsFilesAndMap markers={markers} object={object} />}
    </div>
  )
}

export default ObjectInfo
