import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import ModalWindow from 'Components/ModalWindow'
import ObjectInfo from 'Components/ObjectInfo'
import Utils from 'lib/utils'
import Icons from 'lib/icons'
import agent from 'Stores/Agent'
import { confirm } from 'Components/Confirm'
import { ErrorHandling, ErrorHandlingHelper } from 'Components/ErrorHandling'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const datefmt = Utils.datefmt
const queue2name = Utils.queue2name

const ObjectRow = (row) => {
  let answerLink = []

  if (row.status == 'application') {
    if (answerLink.length > 0) answerLink.push(<span key={3}>&nbsp;</span>)
    answerLink.push(
      <button key={1} onClick={row.onClickOffer(false)} className="main-btn main-btn-mini main-btn-red">
        <Trans>interested.abort</Trans>
      </button>
    )
  } else {
    answerLink.push(
      <div>
        <Trans>label.awaiting_confirmation</Trans>
      </div>
    )
  }

  return (
    <tr>
      <td data-title={i18next.t('label.address')}>
        <a href="#" onClick={row.onObjectInfo} style={{ fontWeight: 'bold' }} className="pp-link">
          {row.address && row.address.address1}, {row.address && row.address.city}
        </a>
        <div style={{ whiteSpace: 'nowrap', fontSize: '12px', lineHeight: '1.2em', fontWeight: 'bold' }}>
          {row.rent} <Trans>label.sek_per_month</Trans> | {row.size}
          <br />
          {Math.round(row.area)} <Trans>label.kvm</Trans>
        </div>
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.type')}>
        {queue2name(row.type)}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.admission_date')}>
        {datefmt(row.admissionDate, 'yyyy-MM-dd')}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.application_end_date')}>
        {datefmt(row.applicationEndDate, 'yyyy-MM-dd')}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.your_answer')}>
        {row.answer == 'yes' && i18next.t('label.you_answered_yes')}
        {row.answer == 'no' && i18next.t('label.you_answered_no')}
        {answerLink}
      </td>
    </tr>
  )
}

const ObjectsTable = (props) => {
  const rows = []

  const onClickOffer = (obj) => (answer) => (event) => {
    event.preventDefault()
    return onClickOfferAsync(obj, answer)
  }
  async function onClickOfferAsync(obj, answer) {
    if (await confirm(i18next.t('interested.confirm_text'), i18next.t('label.ok'), i18next.t('label.cancel'), { maxWidth: '400px' })) {
      return props.onAnswer(obj, answer)
    }
  }

  const onObjectInfo = (obj, contact) => (event) => {
    event.preventDefault()
    return props.onObjectInfo(obj, contact)
  }

  props.rows.map((row) =>
    rows.push(
      <ObjectRow
        key={row.id}
        {...row}
        onObjectInfo={onObjectInfo(row)}
        onObjectInfoContact={onObjectInfo(row, true)}
        onClickOffer={onClickOffer(row)}
      />
    )
  )

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <th scope="col">
            <Trans>label.address</Trans>
          </th>
          <th style={{ width: '80px' }} scope="col">
            <Trans>label.type</Trans>
          </th>
          <th scope="col">
            <Trans>label.admission_date</Trans>
          </th>
          <th scope="col">
            <Trans>label.answer_before</Trans>
          </th>
          <th scope="col">
            <Trans>label.your_answer</Trans>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const HistoryRow = (row) => (
  <tr>
    <td data-title={i18next.t('label.address')}>
      <a href="#" onClick={row.onObjectInfo} style={{ fontWeight: 'bold' }} className="pp-link">
        {row.address && row.address.address1}, {row.address && row.address.city}
      </a>
    </td>
    <td data-title={i18next.t('label.rent')}>{Math.round(row.rent)}</td>
    <td data-title={i18next.t('label.type')}>
      {queue2name(row.type)}, {row.size}
    </td>
    <td data-title={i18next.t('label.area')}>{Math.round(row.area)} kvm</td>
    <td data-title={i18next.t('label.status')}>
      {row.status == 'history_rented' ? i18next.t('label.assigned') : i18next.t('label.assigned_someone_else')}
    </td>
  </tr>
)

const ObjectsHistoryTable = (props) => {
  const rows = []

  const onObjectInfo = (obj) => (event) => {
    event.preventDefault()
    return props.onShowObjectInfo(obj)
  }

  props.rows.map((row, index) => rows.push(<HistoryRow onObjectInfo={onObjectInfo(row)} key={index} {...row} />))

  return (
    <table className="pp-data-table">
      <thead>
        <tr>
          <th>
            <Trans>label.address</Trans>
          </th>
          <th>
            <Trans>label.rent</Trans>
          </th>
          <th>
            <Trans>label.type</Trans>
          </th>
          <th>
            <Trans>label.area</Trans>
          </th>
          <th>
            <Trans>label.status</Trans>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const Objects = ({ objects, onAnswer, onShowObjectInfo }) => {
  return (
    <React.Fragment>
      {objects.length > 0 && (
        <div>
          <ObjectsTable rows={objects} onAnswer={onAnswer} onObjectInfo={onShowObjectInfo} />
        </div>
      )}
      {objects.length == 0 && (
        <em>
          <Trans>interested.empty_current</Trans>
        </em>
      )}
    </React.Fragment>
  )
}

const Page = () => {
  const [loading, setLoading] = useState(true)
  const [objects, setObjects] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentObject, setCurrentObject] = useState()
  const [errorHandling, setErrorHandling] = useState(ErrorHandlingHelper.getDefaultState())

  const loadObjects = () => {
    agent.Queue.getInterests().then((objects) => {
      setObjects(objects)
      setLoading(false)
    })
  }

  useEffect(loadObjects, [])

  const onAnswer = (offer, response) => {
    agent.Queue.setAnswer(offer.id, 'interest', response)
      .then(loadObjects)
      .catch((err) => {
        setErrorHandling(ErrorHandlingHelper.error(err))
      })
  }

  const setModal = (visible) => setShowModal(visible)
  const showObjectInfo = (obj) => {
    setCurrentObject(obj)
    setShowModal(true)
  }

  const showInterest = (offer, response) => {
    onAnswer(offer, response)
    setShowModal(false)
  }
  const onClose = () => setModal(false)

  let { history } = objects

  if (!history) history = []
  //if (!objects) objects = []

  let available = objects.available && objects.available.filter((o) => o.interested)
  if (!available) available = []

  return (
    <React.Fragment>
      <ErrorHandling {...errorHandling} onClose={(e) => setErrorHandling(ErrorHandlingHelper.close())} />
      {loading && (
        <div>
          <Trans>loading</Trans>
        </div>
      )}
      {!loading && (
        <div>
          <h1>
            <Trans>interested.header</Trans>
          </h1>
          <Tabs>
            <TabList>
              <Tab>
                {Icons.Star} <Trans>label.current</Trans>
              </Tab>
              <Tab>
                {Icons.History} <Trans>label.history</Trans>
              </Tab>
            </TabList>
            <TabPanel>
              <Objects objects={available} onAnswer={onAnswer} onShowObjectInfo={showObjectInfo} />
            </TabPanel>
            <TabPanel>
              {history.length == 0 && (
                <em>
                  <Trans>label.empty_history</Trans>
                </em>
              )}
              {history.length > 0 && <ObjectsHistoryTable rows={history} onShowObjectInfo={showObjectInfo} />}
            </TabPanel>
          </Tabs>
          <ModalWindow
            isOpen={showModal}
            onClose={onClose}
            title={currentObject && currentObject.address && currentObject.address.address1}
          >
            <ObjectInfo object={currentObject} showInterest={showInterest} />
          </ModalWindow>
        </div>
      )}
    </React.Fragment>
  )
}

export default Page
