import React from 'react'
import * as striptags from 'striptags'
import Icons from 'lib/icons'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import sv from 'date-fns/locale/sv'
import i18next from 'i18next'

const API_ROOT = process.env.REACT_APP_API || 'http://192.168.1.180:9901/tenantservice/v1'

const datefmt = (timestamp, fmt) => {
  if (!timestamp) return null
  if (typeof timestamp == 'string') timestamp = parseISO(timestamp)

  fmt = fmt || 'yyyy-MM-dd HH:mm'

  return format(timestamp, fmt, { locale: sv })
}

const isURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(str)
}

const outputDocument = (document) => {
  var stripped = striptags(document, []).trim()
  if (isURL(stripped))
    return (
      <a href={stripped} rel="noopener noreferrer" target="_blank">
        {stripped}
      </a>
    )
  else return <div dangerouslySetInnerHTML={{ __html: document }} />
}

const queue2name = (queue) => {
  const nameMapping = {
    housing: i18next.t('queue.housing'),
    parking: i18next.t('queue.parking'),
    storage: i18next.t('queue.storage'),
    premise: i18next.t('queue.premise'),
    berth: i18next.t('queue.berth'),
    plot: i18next.t('queue.plothouse'),
    retirementhome: i18next.t('queue.retirement_home'),
    student: i18next.t('queue.student'),
    guestresearcher: i18next.t('queue.guest_researcher'),
  }

  return (queue && nameMapping[queue.replace('_', '').toLowerCase()]) || queue
}

const GetIcon = (props) => {
  switch (props.id) {
    case 'housing':
      return Icons.Housing
    case 'parking':
      return Icons.Car
    case 'berth':
      return Icons.Ship
    case 'storage':
      return Icons.Archive
    default:
      return Icons.Housing
  }
}

const apiUrl = (uri) => {
  let url

  if (!API_ROOT.endsWith('/') && !uri.startsWith('/')) url = API_ROOT + '/' + uri
  else url = API_ROOT + uri

  return url
}

export default { apiUrl, datefmt, outputDocument, GetIcon, isURL, queue2name }
