import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { toJS } from 'mobx'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import Icons from 'lib/icons'
import Utils from 'lib/utils'
import observer from 'Decorators/Observer'
import ModalWindow from 'Components/ModalWindow'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import SubscriptionPage from './SubscriptionPage'
import ChangePassword from './ChangePasswordPage'
import DeleteProfile from './DeleteProfilePage'
import { Root as rootStore } from 'Stores'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const datefmt = Utils.datefmt
const queue2name = Utils.queue2name

function getFormSpec(options) {
  return {
    title: 'Formens titel',
    groups: [
      {
        title: i18next.t('label.contact_info'),
        fields: [
          { name: 'name', title: i18next.t('label.name') },
          { name: 'email', title: i18next.t('label.email'), type: 'email' },
          { name: 'phoneMobile', type: 'tel', title: i18next.t('label.phone_mobile') },
          { name: 'phoneHome', title: i18next.t('label.phone_home') },
          { name: 'arrivalDate', title: i18next.t('label.arrival_date') },
        ],
      },
      {
        title: i18next.t('label.current_housing'),
        fields: [
          { name: 'street', title: i18next.t('label.street') },
          { name: 'zipCode', title: i18next.t('label.zipcode') },
          { name: 'city', title: i18next.t('label.city') },
          {
            name: 'currentHousing',
            title: i18next.t('label.housing'),
            options: options.housing,
            placeholder: i18next.t('label.housing_placeholder')
          },
          { name: 'currentLandlordName', title: i18next.t('label.landlord_name'), min: 0, max: 10 },
          { name: 'currentLandlordPhone', title: i18next.t('label.landlord_phone') },
          { name: 'movingInYear', title: i18next.t('label.moving_in_year') },
        ],
      },
      {
        title: i18next.t('label.employment'),
        fields: [
          {
            name: 'incomeType',
            title: i18next.t('label.income_type'),
            placeholder: i18next.t('label.income_type_placeholder'),
            options: options.income
          },
          { name: 'income', title: i18next.t('label.income') },
          { name: 'employer', title: i18next.t('label.employer') },
          { name: 'employmentDate', title: i18next.t('label.employment_date') },
          { name: 'employerPhone', title: i18next.t('label.employer_phone') },
        ],
      },
      {
        title: i18next.t('label.household'),
        fields: [
          { name: 'numberOfAdults', title: i18next.t('label.number_of_adults') },
          { name: 'numberOfChildren', title: i18next.t('label.number_of_children') },
          { name: 'householdIncome', title: i18next.t('label.household_income') },
        ],
      },
      {
        title: i18next.t('label.other'),
        fields: [{ name: 'otherInformation', title: i18next.t('label.other_information'), widget: 'textarea' }],
      },
    ],
  }
}

function getFormSpecAgency() {
  return {
    groups: [
      {
        title: i18next.t('label.contact_info'),
        fields: [
          { name: 'name', title: i18next.t('label.name') },
          { name: 'email', title: i18next.t('label.email'), type: 'email' },
          { name: 'phoneMobile', type: 'tel', title: i18next.t('label.phone') },
          { name: 'currentLandlordName', title: i18next.t('label.landlord_current_living') },
          { name: 'currentLandlordPhone', title: i18next.t('label.landlord_phone') },
        ],
      },
    ],
  }
}

function removeHiddenFields(spec, fieldSettings) {
  const newSpec = { title: spec.title, groups: [] }

  for (var group of spec.groups) {
    const fields = []

    for (var field of group.fields) {
      if (fieldSettings.fields != undefined) {

        let fieldSetting = fieldSettings.fields[field.name];
        if (fieldSetting != undefined) {
          if (fieldSetting != 'hidden') {
            if (fieldSetting == 'readOnly')
              field.widget = 'readonly';
            else if (fieldSetting == 'required')
              field.required = true;
            else
              field.required = false;

            fields.push(field)
          }
        }
      }
    }

    if (fields.length > 0) newSpec.groups.push({ title: group.title, fields: fields })
  }

  return newSpec
}

// get required fields
const getRequiredFields = (spec) => {
  const required = []

  for (var group of spec.groups) {
    for (var field of group.fields) {
      if (field.required) required.push(field.name)
    }
  }

  return required
}

// Check that no required fields are null
const checkRequiredFields = (formData, required) => {
  for (var field of required) {
    if (!formData[field]) return false
  }

  return true
}

const QueueAndProfile = inject('store')(
  observer((props) => {
    const { store } = props
    const isAgency = props.store.portal.isAgency()
    const useQueue = props.store.portal.useQueue()
    const f = props.isAgency ? getFormSpecAgency : getFormSpec
    const fieldSettings = toJS(store.user.fieldSettings) || {}

    let spec = f({
      housing: store.user.housingOptions,
      income: store.user.incomeOptions,
    })

    spec = removeHiddenFields(spec, fieldSettings)

    const formData = store.user.profile.toJSON()
    const requiredFields = getRequiredFields(spec)
    var allRequiredFieldsFilled = checkRequiredFields(formData, requiredFields)

    return (
      <div>
        {useQueue && !isAgency && <QueueList missingFields={!allRequiredFieldsFilled} />}

        <Form
          schema={spec}
          formData={formData}
          onChange={props.onChange}
          onSubmit={props.onSubmit}
          onError={props.onError}
          submit={i18next.t('label.save')}
          loading={store.user.profile.size == 0}
          successBox={props.successBox}
        />
      </div>
    )
  })
)

const VerifyLeaveQueue = (props) => {
  const schema = {
    fields: [{ name: 'password', title: i18next.t('label.type_password') + ':' }],
  }

  return (
    <React.Fragment>
      <p>
        <Trans>queue.confirm_leave_info</Trans>
      </p>
      <Form
        schema={schema}
        onSubmit={props.onSubmit}
        onError={props.onError}
        submit={i18next.t('queue.leave')}
        successBox={<MessageBox text={i18next.t('info.user_left_queue')} />}
        rowLayout={true}
      />
    </React.Fragment>
  )
}

const QueueList = (props) => {
  const missingFields = props.missingFields
  const [queues, setQueues] = useState()
  const [leaveQueue, setLeaveQueue] = useState()

  const reloadSubscriptions = async () => {
    await rootStore.queue.loadSubscriptions()
    const subs = await rootStore.queue.getSubscriptions()
    setQueues(subs.toJS())
  }

  useEffect(() => {
    reloadSubscriptions()
  }, [])

  const joinQueue = async (queueId) => {
    await rootStore.queue.joinQueue(queueId)
    reloadSubscriptions()
  }
  const confirmLeave = (queueId) => setLeaveQueue(queueId)
  const onCloseModal = () => setLeaveQueue(null)

  const onSubmit = async (form) => {
    const values = form.formData

    await rootStore.queue.leaveQueue(leaveQueue, values.password)
    await reloadSubscriptions()
    setLeaveQueue(null)
  }

  if (!queues)
    return (
      <div>
        <Trans>loading</Trans>
      </div>
    )

  const items = []
  queues.forEach((queue, k) => {
    const name = queue2name(k)
    const joined = queue && queue.applicationDate
    const applicationDate = joined && datefmt(queue.applicationDate, 'yyyy-MM-dd')
    const points = joined && queue.points

    let item = (
      <div key={k} className="form-group field field-string">
        <label htmlFor="root_name">{name}</label>
        <div className="input-container">
          {!joined && (
            <span style={{ float: 'right' }}>
              {!missingFields && (
                <a className="main-btn main-btn-mini" onClick={() => joinQueue(queue.queueId)}>
                  <Trans>queue.join</Trans>
                </a>
              )}
              {missingFields && <b style={{ color: 'red' }}><Trans>queue.incomplete_profile</Trans></b>}
            </span>
          )}
          {joined && (
            <span style={{ float: 'right' }}>
              <button className="main-btn main-btn-mini" onClick={() => confirmLeave(queue.queueId)}>
                <Trans>queue.leave</Trans>
              </button>
            </span>
          )}
          <span>{applicationDate || '-'}</span>
          <div className="errors-container clearfix"></div>
        </div>
        <div></div>
      </div>
    )

    items.push(item)
  })

  return (
    <div className="field-object">
      <div className="input-container">
        <fieldset>
          <legend id="root__title2">
            <Trans>queues.header</Trans>
          </legend>
          {items}
        </fieldset>
      </div>

      <ModalWindow isOpen={leaveQueue != null} onClose={onCloseModal} title={i18next.t('queue.confirm_leave')} maxWidth={500}>
        <VerifyLeaveQueue onSubmit={onSubmit} queueId={leaveQueue} />
      </ModalWindow>
    </div>
  )
}

class ProfilePage extends React.Component {
  componentDidMount = () => {
    this.props.store.user.getProfile()
  }

  onSubmit = (form) => {
    const values = form.formData

    return this.props.store.user.updateProfile(values).then(() => {
      this.success = true
      window.scrollTo(0, document.body.scrollHeight)
    })
  }

  render() {
    const authMethod = this.props.store.auth.getAuthMethod()
    const isAgency = this.props.store.portal.isAgency()
    const isPassword = authMethod == 'password'
    const useQueue = this.props.store.portal.useQueue()

    return (
      <div>
        <h1>
          <Trans>profile.header</Trans>
        </h1>
        <Tabs onSelect={this.onSelectTab}>
          <TabList>
            <Tab>
              {Icons.Profile} <Trans>profile.account_data</Trans>
            </Tab>
            {useQueue && (
              <Tab>
                {Icons.Mail} <Trans>profile.subscriptions</Trans>
              </Tab>
            )}
            {isPassword && (
              <Tab>
                {Icons.Lock} <Trans>label.change_password</Trans>
              </Tab>
            )}
            <Tab>
              {Icons.Ban} <Trans>profile.deactivate</Trans>
            </Tab>
          </TabList>

          <TabPanel>
            <QueueAndProfile onSubmit={this.onSubmit} isAgency={isAgency} />
          </TabPanel>
          {useQueue && (
            <TabPanel>
              <SubscriptionPage />
            </TabPanel>
          )}
          {isPassword && (
            <TabPanel>
              <ChangePassword />
            </TabPanel>
          )}
          <TabPanel>
            <DeleteProfile />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

export default inject('store')(observer(ProfilePage))
