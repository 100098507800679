import React from 'react'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import i18next from "i18next";
import { Trans } from 'react-i18next'

const form = {
  type: 'object',
  description: null,
  hideRequired: true,
  fields: [
    { name: 'old_password', title: i18next.t('label.password_current'), required: true },
    { name: 'new_password', title: i18next.t('label.new_password'), required: true },
    { name: 'new_password2', title: i18next.t('label.new_password_repeat'), required: true },
  ],
}

const PasswordForm = (props) => (
  <Form
    schema={form}
    onChange={props.onChange}
    onSubmit={props.onSubmit}
    onSuccess={props.onSuccess}
    hideOnSuccess={true}
    submit={i18next.t('label.change_password')}
  ></Form>
)

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sucess: false,
    }
  }

  onSuccess = () => {
    this.setState({
      success: true,
    })
  }

  onSubmit = async (form) => {
    const store = this.props.store
    const values = form.formData

    if (values.new_password != values.new_password2)
      throw {
        status: 400,
        response: {
          body: {
            errors: [
              {
                property: undefined,
                message: i18next.t('error.password_mismatch'),
              },
            ],
          },
        },
      }

    return store.user.changePassword(values.old_password, values.new_password)
  }

  render() {
    const successBox = <MessageBox type="success"><Trans>info.password_changed</Trans></MessageBox>

    return (
      <div>
        <PasswordForm onSubmit={this.onSubmit} successBox={successBox} onSuccess={this.onSuccess} />
      </div>
    )
  }
}

export default inject('store')(observer(Page))
