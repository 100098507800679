import React from 'react'

function CustomFormTemplate(props) {
  const { uiSchema, TitleField, DescriptionField } = props

  let content = []
  const title = props.title || uiSchema['ui:title']

  if(title)
    content.push(
      <TitleField
        id={`${props.idSchema.$id}__title`} key={-1}
        title={title}
        required={props.required}
        formContext={props.formContext}
      />)
      
  if(props.description)
    content.push(<DescriptionField key={-2}
      id={`${props.idSchema.$id}__description`}
      description={props.description}
      formContext={props.formContext}
    />)
  
  content = content.concat(props.properties.map(prop => prop.content))

  return <fieldset key={props.key}>{content}</fieldset>
}

function propertiesNameMap(properties) {
  let map = {}
  properties.forEach(property => {
    map[property.name] = property
  })

  return map
}

function CustomObjectFieldTemplate(props) {
  let grouping = props.uiSchema['ui:grouping']
  let content

  if(!grouping)
    content = CustomFormTemplate(props)
  else {
    const map = propertiesNameMap(props.properties)
    content = []
    
    let i = 0
    grouping.forEach(group => {
      const data = Object.assign({},  props)
      data.key = i
      data.title = group.title
      data.properties = []

      group.fields.forEach(field => data.properties.push(map[field]))
      content = content.concat(CustomFormTemplate(data))
      i++
    })
  }

  return content
}


export default CustomObjectFieldTemplate