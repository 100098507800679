import React from 'react'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'

const DEFAULT_BING_KEY = process.env.BING_KEY

const MapComponent = class MapComponent extends React.Component {
  initMap() {
    const props = this.props
    
    let mapOptions = {
      credentials: props.mapKey || DEFAULT_BING_KEY,
      mapTypeId: window.Microsoft.Maps.MapTypeId.road,
      zoom: props.zoom || 8,
      disableScrollWheelZoom: true
    }

    mapOptions = Object.assign(mapOptions, props.options || {})
    
    let map = new window.Microsoft.Maps.Map(props.id || '#map-container', mapOptions)
    const defaultPinOptions = {
      title: undefined,
      subTitle: undefined,
      text: undefined
    }
    let locs = []
    props.markers && props.markers.forEach(marker => {
      let { longitude, latitude, altitude, ...options} = marker

      options = Object.assign(defaultPinOptions, options)
      
      const pin = new window.Microsoft.Maps.Pushpin({ latitude, longitude, altitude }, options)
      
      map.entities.push(pin)
      locs.push(pin.getLocation())
    })
   
    if(locs.length == 1){
      map.setView({         
        center: new window.Microsoft.Maps.Location(locs[0].latitude,locs[0].longitude)   
      })
    } else if(locs.length > 1) {
      map.setView({            
        bounds: new window.Microsoft.Maps.LocationRect.fromLocations(locs),
        padding:150   
      })
      
    }

  }

  componentDidMount() {
    this.initMap()
  }

  render()
  {
    return (
      <div className="map-wrapper">
        <div id="map-container" style={{height: 400}}></div>
      </div>
    )
  }
}

const MapWithKey = inject('store')(observer((props) => {
  const info = props.store.portal.getInfo()
  const mapKey = info.get('bingKey')

  return <MapComponent mapKey={mapKey} {...props} />
}))

export default MapWithKey