import React, { useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import Form from 'Components/Forms/Form'
import agent from 'Stores/Agent'
import { BankIdClient, Status, ServiceType } from 'lib/bankid'
import BankIdButton from 'Components/BankIdButton'
import { Root } from 'Stores'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const store = Root

const getFormData = (registerTerms, integrityTerms) => {
  const formData = {
    description: '',
    fields: [
      { name: 'email', title: i18next.t('label.email'), required: true },
      { name: 'ssn', title: i18next.t('label.ssn'), required: true },
    ],
  }

  if (registerTerms) {
    formData.fields.push({
      name: 'agree1',
      title: i18next.t('label.terms_register'),
      options: { title: registerTerms },
      type: 'boolean',
      required: true,
      widget: 'agree',
    })
  }

  if (integrityTerms) {
    formData.fields.push({
      name: 'agree2',
      title: i18next.t('label.terms_integrity'),
      options: { title: integrityTerms },
      type: 'boolean',
      required: true,
      widget: 'agree',
    })
  }

  return formData
}

const RegisterForm = ({ registerTerms, integrityTerms, onChange, onSubmit, onSuccess, onError }) => {
  const formData = null
  const form = getFormData(registerTerms, integrityTerms)

  return (
    <Form
      schema={form}
      formData={formData}
      onChange={onChange}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      onError={onError}
      submit={i18next.t('label.register_button')}
    />
  )
}

const BankIdValidate = ({ serviceType, onSuccess }) => {
  const status = Status.PENDING
  let text
  if (serviceType == ServiceType.FrejaId) {
    text = i18next.t('bank_id.validate.freja_id')
  } else {
    text = i18next.t('bank_id.validate.bank_id')
  }

  return (
    <React.Fragment>
      <p>
        {text}
        <br />
        <Trans>register.info</Trans>
      </p>

      <BankIdButton type={serviceType} status={status} cancelText={i18next.t('bank_id.pending')}></BankIdButton>
    </React.Fragment>
  )
}

const RegisterBankId = (props) => {
  const [status, setStatus] = useState(Status.READY)
  const client = new BankIdClient({
    onStatus: (status) => setStatus(status),
    onSuccess: (res) => {
      store.auth.setAccessToken(res.token)
    },
    serviceType: props.serviceType,
    mobile: props.mobile,
  })

  const onSubmit = (form) => {
    const data = { ...form.formData, serviceType: props.serviceType, mobile: props.mobile }

    return agent.Auth.bankIdRegister(data).then((res) => {
      client.setStatus(Status.PENDING)
      client.pollStatus({ ssn: form.formData.ssn, session: res.session })

      return res
    })
  }

  return (
    <React.Fragment>
      {status == Status.SUCCESS && (
        <div>
          <Trans>info.user_registered</Trans>
        </div>
      )}
      {status == Status.ERROR && (
        <div>
          <Trans>error.something</Trans>
        </div>
      )}
      {status == Status.READY && (
        <RegisterForm onSubmit={onSubmit} registerTerms={props.registerTerms} integrityTerms={props.integrityTerms} />
      )}
      {status == Status.PENDING && <BankIdValidate serviceType={props.serviceType} status={status} />}
    </React.Fragment>
  )
}

const RegisterPage = (props) => {
  return (
    <React.Fragment>
      <Tabs>
        <TabList>
          <Tab>
            <Trans>bank_id.mobile_bank_id</Trans>
          </Tab>
          <Tab>
            <Trans>bank_id.bank_id</Trans>
          </Tab>
          <Tab>
            <Trans>bank_id.freja_id</Trans>
          </Tab>
        </TabList>
        <TabPanel>
          <RegisterBankId {...props} serviceType={ServiceType.BankId} mobile={true} />
        </TabPanel>
        <TabPanel>
          <RegisterBankId {...props} serviceType={ServiceType.BankId} />
        </TabPanel>
        <TabPanel>
          <RegisterBankId {...props} serviceType={ServiceType.FrejaId} />
        </TabPanel>
      </Tabs>
    </React.Fragment>
  )
}

export default RegisterPage
