import React from 'react'
import { observer } from 'mobx-react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Menu from 'Components/Menu.js'
import Routes from './Routes'
import { Root as RootStore } from './Stores'
import agent from './Stores/Agent'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const portalStore = RootStore.portal
const authStore = RootStore.auth
const queueStore = RootStore.queue

const pages = {
  available: ['bostader'],
  mypage: [
    'bostadsko',
    'valkommen',
    'registrera',
    'minasidor',
    'serviceanmalan',
    'arenden',
    'kontrakt',
    'media',
    'payments',
    'intresseanmalan',
    'erbjudanden',
    'ledigt',
    'tomthusko',
    'profil',
    'chat',
    'loggaut',
  ],
}

const queuePages = ['erbjudanden', 'ledigt', 'bostadsko', 'tomthusko']
const reportPages = ['serviceanmalan', 'arenden']

const MainWrapper = ({ inline, children }) => (!inline ? <main>{children}</main> : children)

const MenuRouter = observer(({ page, isStandalone }) => {
  const routes = []
  const menuData = []
  const apiStatus = authStore.apiStatus
  const isAuth = authStore.isAuthenticated()
  const isTenant = authStore.isTenant()
  const useEmailAuth = authStore.useEmailAuth()
  const isAgency = portalStore.isAgency()
  const useChat = portalStore.useChat()
  const useMedia = portalStore.useMedia()
  const useQueue = portalStore.useQueue()
  const useReport = portalStore.useReport()
  const reportTitle = portalStore.getReportTitle()
  const useAlreadyTenant = portalStore.useAlreadyTenant()
  const canRegister = portalStore.openRegistration()
  const showPayments = portalStore.showPayments()
  const showOffers = queueStore.showOffers()

  let endpoints = [...pages[page]]

  if (page == 'mypage') {
    if (isAgency) {
      endpoints = endpoints.filter((p) => p != 'ledigt')
    } else {
      endpoints = endpoints.filter((p) => p != 'intresseanmalan' && !Routes[p].agency)
    }

    if (useEmailAuth) {
      endpoints = [...endpoints, 'hyresgast', 'losenord']
    }
    // Endast inkludera Chat om det är aktiverat i inställningarna
    if (!canRegister) endpoints = endpoints.filter((p) => p != 'registrera')
    if (!useChat) endpoints = endpoints.filter((p) => p != 'chat')
    if (!useMedia) endpoints = endpoints.filter((p) => p != 'media')
    if (!useReport) endpoints = endpoints.filter((p) => !reportPages.includes(p))
    if (!useAlreadyTenant) endpoints = endpoints.filter((p) => p != 'hyresgast')
    if (!showPayments) endpoints = endpoints.filter((p) => p != 'payments')

    if (!useQueue) {
      endpoints = endpoints.filter((p) => !queuePages.includes(p))
    }
    if (!showOffers) endpoints = endpoints.filter((p) => p != 'erbjudanden')
  }

  endpoints.forEach((key) => {
    const route = Routes[key]

    if (route.name)
      route.name = i18next.t(route.name)

    if (!route) throw new Error(`Route '${key}' not found`)
    if (!route.component) throw new Error(`Component for route '${route.name}' not found`)
    route.path = route.path || '/' + key
    let visible = route.auth === undefined || (route.auth === false && !isAuth) || (route.auth === true && isAuth)
    visible = visible && (route.tenant == null || isTenant)

    if (visible) {
      routes.push(<Route key={key} {...route} />)

      if (route.name) {
        const menu = {}

        switch (route.path) {
          case '/erbjudanden':
            menu.count = RootStore.queue.numOffers()
            break
          case '/ledigt':
            menu.count = RootStore.queue.numAvailable()
            break
          case '/chat':
            agent.Chat.getUnreadCount().then((c) => (menu.count = c))
            break
          default:
            break
        }

        if (route.path == '/serviceanmalan' && reportTitle) route.name = reportTitle
        Object.assign(menu, route)

        menuData.push(menu)
      }
    }
  })

  let menu = isAuth ? <Menu items={menuData} isStandalone={isStandalone} /> : null
  if (isStandalone == 'true') menu = <Menu items={menuData} isStandalone={isStandalone} />
  const contentClass = menu ? 'content' : null

  const minasidorRoute = routes.find((route) => route.props.path === '/minasidor')
  if (minasidorRoute)
    routes.push(<Route key="default" path="/" component={minasidorRoute.props.component} />)
  else
    routes.push(<Route key="default" path="/" component={routes[0].props.component} />)

  return (
    <Router>
      <div className="container clearfix">
        {menu}
        <MainWrapper inline={!isStandalone}>
          <div className={contentClass}>
            {apiStatus == -1 && (
              <div className="error-box" role="alert">
                <Trans>error.connection</Trans>
              </div>
            )}
            <Switch>{routes}</Switch>
          </div>
        </MainWrapper>
      </div>
    </Router>
  )
})

const Content = (props) => {
  let content

  if (props.inline) {
    const component = Routes[props.page].component
    content = React.createElement(component, { inline: true, queue: props.queue })
  } else {
    content = <MenuRouter {...props} />
  }

  return <React.Fragment>{content}</React.Fragment>
}

export default observer(Content)
