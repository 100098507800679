import React from 'react'
import { Component } from 'react'
import { extendObservable, toJS } from 'mobx'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

class Page extends Component {
  constructor() {
    super()

    extendObservable(this, {
      success: false,
    })
  }

  componentDidMount() {
    this.props.store.reports.getReports()
  }

  onChange() {}

  onError() {}

  onSubmit = async (form) => {
    return this.props.store.reports.postReport(form.formData).then(() => (this.success = true))
  }

  option2name(option) {
    const nameMapping = {
      // Object
      // _1_ruk: i18next.t('report.options.object.1ruk'),
      // Space
      _hall: i18next.t('report.options.space.hallway'),
      _rum: i18next.t('report.options.space.room'),
      _wc_med_dusch: i18next.t('report.options.space.wc_with_shower'),
      // Detail
      _malad_vagg: i18next.t('report.options.detail.painted_wall'),
      _malat: i18next.t('report.options.detail.painted'),
      _parkett: i18next.t('report.options.detail.parquet'), // TODO: Remove when typo is fixed
      _partkett: i18next.t('report.options.detail.parquet'),
      _golvlist_malad: i18next.t('report.options.detail.painted_floor_molding'),
      _tapeter: i18next.t('report.options.detail.wallpaper'),
      _kakel: i18next.t('report.options.detail.tile'),
      _klinker: i18next.t('report.options.detail.clinker'),
      // Problem
      _el: i18next.t('report.options.problem.power'),
      _trasig_strombrytare: i18next.t('report.options.problem.power.broken_switch'),
      _trasig_vaggkontakt: i18next.t('report.options.problem.power.broken_socket'),
      _trasigt_lysror: i18next.t('report.options.problem.power.broken_fluorescent_light'),
      _vvs: i18next.t('report.options.problem.water'),
      _rinnande_duschblandare: i18next.t('report.options.problem.water.dripping_shower_tap'),
      _rinnande_toalett: i18next.t('report.options.problem.water.dripping_toilet'),
      _stopp_i_avlopp_dusch: i18next.t('report.options.problem.water.clogged_shower_drain'),
      _stopp_i_diskho: i18next.t('report.options.problem.water.clogged_kitchen_sink'),
      _stopp_i_handfat: i18next.t('report.options.problem.water.clogged_sink'),
      _stopp_i_toalett: i18next.t('report.options.problem.water.clogged_toilet'),
      // Other
      _far_anvanda_huvudnyckel: i18next.t('report.options.other.may_use_skeleton_key'),
      _hund_katt: i18next.t('report.options.other.pets'),
      _kontakta_mig_forst: i18next.t('report.options.other.contact_first'),
    }

    let key = '_' + option.trim().toLowerCase().replace(/[åä]/g, 'a').replace(/[ö]/g, 'o').replace(/[ ]/g, '_').replace(/[/]/g, '_')
    if (!nameMapping[key]) console.debug('missing translation key: ' + key)
    return nameMapping[key] || option
  }

  updateOptionLabels(options) {
    if (!options) return

    for (let i in options) {
      if (!options.hasOwnProperty(i)) continue

      if (typeof options[i] === 'string') {
        options[i] = this.option2name(options[i])
      } else {
        if (options[i].label) options[i].label = this.option2name(options[i].label)
        this.updateOptionLabels(options[i].children)
      }
    }
  }

  translateOptions(options) {
    if (!options || options.length < 1) return options

    this.updateOptionLabels(options)

    return options
  }

  getFormSchema = (options) => {
    const schema = {
      groups: [],
    }

    if (options.objects && options.objects.length > 0)
      schema.groups.push({
        title: i18next.t('label.choose_object'),
        fields: [
          {
            name: 'object',
            title: i18next.t('label.cascader_select_object'),
            type: 'object',
            field: 'cascader',
            options: options.objects,
          },
        ],
      })

    if (options.errors && options.errors.length > 0)
      schema.groups.push({
        title: i18next.t('label.choose_problem_type'),
        fields: [
          {
            name: 'error',
            title: i18next.t('label.category'),
            type: 'object',
            field: 'cascader',
            options: options.errors,
          },
        ],
      })

    var otherFields = [
      {
        name: 'description',
        title: i18next.t('label.problem_description'),
        rows: 7,
        required: true,
      },
      { name: 'location', title: i18next.t('label.another_location'), rows: 3 },
    ]

    if (options.other && Object.keys(options.other).length > 0)
      otherFields.push({
        name: 'other',
        title: i18next.t('label.other'),
        type: 'array',
        items: options.other || {},
        widget: 'checkboxes',
      })

    otherFields.push({ name: 'file', title: i18next.t('label.attachment') })

    schema.groups.push({
      title: i18next.t('label.other'),
      fields: otherFields,
    })

    return schema
  }

  getInitialFormData(objectOptions) {
    return {
      object: {
        Contract: objectOptions[0].value,
      },
    }
  }

  refresh = () => {
    this.success = false
    this.props.history.push('/')
    this.props.history.push('/serviceanmalan')
  }

  render() {
    const { store } = this.props
    const reportsStore = store.reports

    let content

    if (this.success) {
      content = (
        <React.Fragment>
          <MessageBox type="success">
            <Trans>report.info_done</Trans>
          </MessageBox>
          <button className="main-btn" onClick={this.refresh}>
            <Trans>report.new_report</Trans>
          </button>
        </React.Fragment>
      )
    } else {
      //const store = this.props.store.reports
      const info = toJS(store.portal.info)
      const infoText = info.documents && info.documents.reportNotice
      const infoDiv = infoText && <MessageBox type="info" html={infoText} />
      var objectOptions = toJS(reportsStore.objectOptions)

      const schema = this.getFormSchema({
        objects: this.translateOptions(toJS(objectOptions)),
        errors: this.translateOptions(toJS(reportsStore.errorOptions)),
        other: this.translateOptions(toJS(reportsStore.otherOptions)),
      })

      if (schema.groups.length === 1) {
        return null
      }

      const count = reportsStore.errorOptions.length

      var formData = this.getInitialFormData(objectOptions)

      content = (
        <div>
          {infoDiv}
          <Form errorCount={count} formData={formData} schema={schema} onSubmit={this.onSubmit} submit={i18next.t('report.send_report')} />
        </div>
      )
    }

    return (
      <div>
        <h1>
          <Trans>report.header</Trans>
        </h1>
        {content}
      </div>
    )
  }
}

export default inject('store')(observer(Page))
