import { useCallback, useEffect, useState } from 'react'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import agent from 'Stores/Agent'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from 'recharts';





const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
}


const MediaPage = () => {
  const [loading, setLoading] = useState(true)
  const [intervalSupported, setIntervalSupported] = useState(true)
  const [hideChart, setHideChart] = useState(false)
  const [dataFound, setDataFound] = useState(true)
  const [noContracts, setNoContracts] = useState(true)

  let [data, setData] = useState()
  let [counterList, setCounterList] = useState([])
  let [intervalTypeList, setIntervalTypeList] = useState([])
  let [counters, setCounters] = useState([])

  const [contracts, setContracts] = useState([])
  const [contract, setContract] = useState([])
  const [counter, setCounter] = useState([])
  const [intervalType, setIntervalType] = useState()
  const [legendLabels, setLegendLabels] = useState({})
  const [showPreviousPeriod, setShowPreviousPeriod] = useState(false)


  useEffect(() => {
    async function getContracts() {
      const contractsResult = await agent.Media.getInfo()
      setContracts(contractsResult.contracts)
      if (contractsResult.contracts.length > 0) {
        setContract(contractsResult.contracts[0].id);
        let tmpLegendLabels = { xAxisLabel: "", yAxisLabel: "", yValue1: "", yValue2: "" };
        setLegendLabels(tmpLegendLabels);
        setNoContracts(false);
      }
      else {
        setNoContracts(true);
      }
    }

    getContracts()
    setLoading(false);
  }, [])

  useEffect(() => {
    const fetchCounters = () => {
      agent.Media.getCounters(contract)
        .then(data => {
          let tmpCounters = [];
          tmpCounters && data.counters.map((p, i) => tmpCounters.push(p))
          setCounters(tmpCounters);

          let tmpCounterList = [];
          tmpCounterList && data.counters.map((p, i) =>
            tmpCounterList.push({ value: p.counterID, label: p.type + ', ' + p.name + ', ' + p.meterName }));
          setCounterList(tmpCounterList);

          if (tmpCounterList.length > 0) {
            setCounter(tmpCounterList[0].value);
          }
        })
        .catch(error => console.log(error));
    }

    if (contract != "") {
      fetchCounters();
    }
  }, [contract])

  const setReadings = (readings) => {
    if (readings.intervalTypeSupported === false) {
      setIntervalSupported(false);
      setHideChart(true);
    }
    else {
      setIntervalSupported(true);
      let tmpLegendLabels = { xAxisLabel: readings.xAxisLabel, yAxisLabel: readings.yAxisLabel, yValue1: readings.yValue1Label, yValue2: readings.yValue2Label };
      setLegendLabels(tmpLegendLabels);
      if (readings.hasValues === false) {
        setHideChart(true);
        setDataFound(false);
      }
      else {
        setData(JSON.parse(JSON.stringify(readings.dataPoints)))
        setDataFound(true);
        setHideChart(false);
      }
    }
  }

  useEffect(() => {
  }, [intervalTypeList])

  useEffect(() => {
    let tmpCounter = counters.find(p => p.counterID == counter);
    let dropdownLabel = "";
    let showDay = false;
    let showWeek = false;
    let showMonth = false;
    const updateIntervalTypeList = (type) => {
      let options = [];
      if (type === 1 || type === 2 || type === 3) {
        dropdownLabel = i18next.t('media.intervaltype_year');
        options.push({ value: 'yearstat', label: dropdownLabel });
      }
      if (type === 1 || type === 2) {
        dropdownLabel = i18next.t('media.intervaltype_prevmonth');
        options.push({ value: 'prevmonthstat', label: dropdownLabel });
        if (showMonth) {
          dropdownLabel = i18next.t('media.intervaltype_month');
          options.push({ value: 'monthstat', label: dropdownLabel });
        }
        if (showWeek) {
          dropdownLabel = i18next.t('media.intervaltype_week');
          options.push({ value: 'weekstat', label: dropdownLabel });
        }
      }
      if (type === 1) {
        if (showDay) {
          dropdownLabel = i18next.t('media.intervaltype_day');
          options.push({ value: 'daystat', label: dropdownLabel });
        }
      }
      if (options.length != 0)
      {

      let reverseOptions = options.reverse();
      setIntervalTypeList(reverseOptions);
      setIntervalType(reverseOptions[0].value);
    }
    }


    if (tmpCounter != undefined)
      updateIntervalTypeList(tmpCounter.intervalType)
  }, [counter, counters])

  useEffect(() => {
    const fetchCounterReadings = () => {
      agent.Media.getReadings(contract, counter, intervalType, showPreviousPeriod)
        .then(readings => setReadings(readings))
        .catch(error => console.log(error));
    }

    if (intervalType != undefined) {
      fetchCounterReadings();
    }
    else {
      setLoading(false);
      setHideChart(true);
    }

  }, [contract, counter, intervalType, showPreviousPeriod])

  let contractList = [];
  contracts && contracts.map((p, i) => contractList.push({ value: p.id, label: p.number }));

  const handleContractChange = useCallback((e) => {
    setContract(e.value);
  }, []);

  const handleCounterChange = useCallback((e) => {
    setCounter(e.value);
  }, []);

  const handleIntervalType = useCallback((e) => {
    setIntervalType(e.value);
  }, []);

  const handlePreviousPeriod = useCallback((e) => {
    setShowPreviousPeriod(!showPreviousPeriod);
  }, [showPreviousPeriod]);


  if (loading) {
    return <div><Trans>loading</Trans></div>
  }

  if (noContracts) {
    return <div><Trans>media.no_contracts</Trans></div>
  }


  return (
    <div>
      <h1>
        <Trans>pages.media</Trans>
      </h1>

      <fieldset>
        <legend id="root__title"><Trans>media.show_consumption</Trans></legend>

        <div className="form-group field field-string">
          <span className="formLabel"><Trans>media.select_contract</Trans>:</span>
          <div className="input_container">
            <div className="cascade-select">
              <Dropdown options={contractList} value={contract} placeholder={i18next.t('media.select_contract_placeholder')} onChange={handleContractChange} />
            </div>
          </div>
        </div>

        <div className="form-group field field-string">
          <span className="formLabel"><Trans>media.select_counter</Trans>:</span>
          <div className="input_container">
            <div className="cascade-select">
              <Dropdown options={counterList} value={counter} placeholder={i18next.t('media.select_counter_placeholder')} onChange={handleCounterChange} />
            </div>
          </div>
        </div>

        <div className="form-group field field-string">
          <span className="formLabel"><Trans>media.select_interval</Trans>:</span>
          <div className="input_container">
            <div className="cascade-select">
              <Dropdown options={intervalTypeList} value={intervalType} placeholder={i18next.t('media.select_interval_placeholder')} onChange={handleIntervalType} />
            </div>
          </div>
        </div>

        <div className="form-group field field-string">
          <span className="formLabel"><Trans>media.compare_previous</Trans>:</span>
          <div className="input_container">
            <div className="cascade-select">
              <Checkbox label="" value={showPreviousPeriod} onChange={handlePreviousPeriod} />
            </div>
          </div>
        </div>


        {!hideChart ?
          <div>
            <ComposedChart
              width={800}
              height={400}
              data={data}
              margin={{
                top: 40,
                right: 40,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="xValue" label={{ value: legendLabels['xAxisLabel'], position: 'insideBottomRight', offset: -10 }} />
              <YAxis label={{ value: legendLabels['yAxisLabel'], angle: -90, position: 'insideLeft', offset: -10 }} />
              <Legend formatter={(value) => legendLabels[value]} />
              <Bar dataKey="yValue1" barSize={20} fill="#413ea0" />
              {showPreviousPeriod ? <Line type="monotone" dataKey="yValue2" stroke="#ff7300" /> : null}
            </ComposedChart>
          </div>
          : null}
        {!intervalSupported ? <div><Trans>media.interval_notsupported</Trans></div> : null}
        {!dataFound ? <div><Trans>media.nodatafound</Trans></div> : null}

      </fieldset>

    </div >

  )
}

export default MediaPage;
