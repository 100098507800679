import React from 'react'
import Utils from 'lib/utils'
import { Trans } from 'react-i18next'

const apiUrl = Utils.apiUrl

const FileRow = ({ file }) => {
  const url = apiUrl('files/' + file.id)
  return (
    <tr>
      <td style={{ width: '30%' }}>
        <strong>{file.name} </strong>
        <a href={url} target="_blank" style={{ textDecoration: 'underline', textDecorationStyle: 'solid' }} rel="noopener noreferrer">
          <Trans>label.download</Trans>
        </a>
      </td>
    </tr>
  )
}

const FileTable = ({ files }) => {
  if (files.length == 0) return false

  let rows = []
  files.forEach((file, i) => {
    rows.push(<FileRow key={i} file={file} />)
  })

  return (
    <React.Fragment>
      <h2>Filer</h2>
      <div className="panel mb-large">
        <table className="pp-basic-data-table">
          <tbody>{rows}</tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default FileTable
