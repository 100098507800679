import React from 'react'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import ModalWindow from 'Components/ModalWindow'
import Map from 'Components/Map'
import i18next from "i18next";
import {Trans} from "react-i18next";

const InterestButton = (props) => (
  <a href="#" className={'main-btn' + (props.interested ? ' disabled' : '')}>
    {props.interested ? i18next.t('label.you_are_interested') : i18next.t('label.add_interest')}
  </a>
)

const Row = (props) => (
  <tr className="clickable" onClick={props.onClick && (() => props.onClick(props))}>
    <td className="full-width no-bottom-border">
      <img src={props.image} alt="" />
    </td>
    <td data-title={i18next.t('label.address')} style={{ verticalAlign: 'middle' }}>
      <a href="#">{props.address}</a>
    </td>
    <td data-title={i18next.t('label.size')} style={{ verticalAlign: 'middle' }}>
      {props.size}
    </td>
    <td data-title={i18next.t('label.type')} style={{ verticalAlign: 'middle' }}>
      {props.type}
    </td>
    <td className="full-width  right-side-full">
      <InterestButton onClick={props.onClickButton} />
    </td>
  </tr>
)

const List = (props) => (
  <table className="pp-data-table ">
    <thead>
      <tr>
        <th scope="col" style={{ width: '75px' }}></th>
        <th scope="col">
          <strong><Trans>label.address</Trans></strong>
        </th>
        <th scope="col">
          <strong><Trans>label.size</Trans></strong>
        </th>
        <th scope="col">
          <strong><Trans>label.type</Trans></strong>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {props.rows.map((row, index) => (
        <Row key={index} {...row} onClick={props.onClickRow} />
      ))}
    </tbody>
  </table>
)

const ModalContent = (props) => (
  <div>
    <h1>
      {props.name}, {props.address && props.address.address1}
    </h1>
    <div>
      <div>
        <img
          className="pp-responsive-image"
          src={props.image || 'https://assets.hemnet.se/assets/images/editorial/bostadsratt-702x250px.jpg'}
          alt=""
        />
        <p>
          <strong><Trans>label.size</Trans>:</strong>
          {props.size} {props.area} kvm
        </p>
        <p>
          <InterestButton />
        </p>
      </div>
      <div className="floating-side-panel">
        <Map options={{ showDashboard: false }} />
      </div>
    </div>
  </div>
)

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      rows: [],
    }
  }

  UNSAFE_componentWillMount() {
    let rows = [
      {
        image: 'https://www.dcarnegie.se/wp-content/uploads/2016/01/husby-370x225.jpg',
        address: 'Storgatan 23',
        size: '56kvm',
        type: '2ROK',
        interested: true,
      },
      {
        image: 'https://www.dcarnegie.se/wp-content/uploads/2016/01/husby-370x225.jpg',
        address: 'Storgatan 13',
        size: '80kvm',
        type: '3ROK',
        interested: false,
      },
      {
        image: 'https://www.dcarnegie.se/wp-content/uploads/2016/01/husby-370x225.jpg',
        address: 'Storgatan 25',
        size: '120kvm',
        type: '4ROK',
        interested: false,
      },
    ]

    this.setState({
      rows: rows,
    })
  }

  setModal(bool, e) {
    e.preventDefault()
    this.setState({
      modalIsOpen: bool,
    })
  }

  onClickRow() {}

  render() {
    const onClose = () => (e) => this.setModal(false, e)
    const { rows, modalIsOpen } = this.state
    const link = "<a href='#/profil'>" + i18next.t('property_subscription.link') + "</a>"

    return (
      <div>
        <h1><Trans>property_subscription.header</Trans></h1>
        <p>
          <Trans tOptions={{link:link }} components={{ a: <a /> }}>property_subscription.text</Trans>
        </p>

        <List rows={rows} onClickRow={this.onClickRow} />
        {!modalIsOpen && <button onClick={(e) => this.setModal(true, e)}>Open</button>}
        <ModalWindow isOpen={modalIsOpen} onClose={onClose}>
          <ModalContent />
        </ModalWindow>
      </div>
    )
  }
}

export default inject('store')(observer(Page))
