import React from 'react'
import { extendObservable } from 'mobx'
import { inject } from 'mobx-react'
import Utils from 'lib/utils'
import observer from 'Decorators/Observer'
import ModalWindow from 'Components/ModalWindow'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const datefmt = Utils.datefmt

const StatusMap = {
  Active: i18next.t('case.status.active'),
  Inactive: i18next.t('case.status.inactive'),
  Performed: i18next.t('case.status.performed'),
  Reported: i18next.t('case.status.reported'),
  Cancelled: i18next.t('case.status.cancelled'),
  Deleted: i18next.t('case.status.deleted'),
}

const CaseRow = (props) => (
  <tr>
    <th data-title={i18next.t('label.case_nr')} scope="col">
      <a onClick={props.onClick}>{props.number}</a>
    </th>
    <th data-title={i18next.t('label.date')} scope="col">
      {datefmt(props.createdDate)}
    </th>
    <th data-title={i18next.t('label.message')} scope="col">
      {props.messageToReporter}
    </th>
    <th data-title={i18next.t('label.status')} scope="col">
      <em>{StatusMap[props.status] || props.status}</em>
    </th>
  </tr>
)

const CaseTable = (props) => {
  let { cases, onClick } = props
  let rows = []
  cases && cases.map((cse, i) => rows.push(<CaseRow key={i} {...cse} onClick={() => onClick(cse)} />))

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <th scope="col">
            <strong>
              <Trans>label.case_nr</Trans>
            </strong>
          </th>
          <th scope="col">
            <strong>
              <Trans>label.date</Trans>
            </strong>
          </th>
          {/*<td><strong><Trans>label.step</Trans></strong></td>*/}
          <th scope="col">
            <strong>
              <Trans>label.message</Trans>
            </strong>
          </th>
          <th scope="col">
            <strong>
              <Trans>label.status</Trans>
            </strong>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const CaseLogRow = ({ createdDate, step, text }) => (
  <tr>
    <td data-title={i18next.t('label.date')}>{datefmt(createdDate)}</td>
    <td data-title={i18next.t('label.step')}>{step}</td>
    <td data-title={i18next.t('label.message')}>{text}</td>
  </tr>
)

const CaseLogTable = ({ log }) => {
  let rows = []

  log && log.map((row, i) => rows.push(<CaseLogRow key={i} {...row} />))

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <td>
            <strong>
              <Trans>label.date</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.step</Trans>
            </strong>
          </td>
          <td>
            <strong>
              <Trans>label.message</Trans>
            </strong>
          </td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const ReportInfo = ({ log, createdDate, reportedBy, status, body, description, messageToReporter }) => {
  return (
    <div>
      <div className="data-container">
        <div className="data-container__item">
          <strong>
            <Trans>label.date</Trans>:
          </strong>{' '}
          {datefmt(createdDate)}
        </div>
        <div className="data-container__item">
          <strong>
            <Trans>label.reported_by</Trans>:
          </strong>{' '}
          {reportedBy}
        </div>
        <div className="data-container__item">
          <strong>
            <Trans>label.status</Trans>:
          </strong>{' '}
          {StatusMap[status] || status}
        </div>
        <div className="data-container__item">
          <strong>
            <Trans>label.place_description</Trans>:
          </strong>{' '}
          {description}
        </div>
        <div className="data-container__item">
          <strong>
            <Trans>label.case_text</Trans>:
          </strong>
          <br />
          {body}
        </div>
        {messageToReporter && (
          <div className="data-container__item">
            <strong>
              <Trans>label.message</Trans>:
            </strong>{' '}
            {messageToReporter}
          </div>
        )}
      </div>
      <h3>
        <Trans>label.case_log</Trans>
      </h3>
      <CaseLogTable log={log} />
    </div>
  )
}

class Page extends React.Component {
  constructor() {
    super()

    extendObservable(this, {
      currentReport: null,
    })
  }

  componentDidMount() {
    this.props.store.reports.getReports()
  }

  render() {
    const { errorReports } = this.props.store.reports

    const onClick = (report) => {
      this.currentReport = report
    }

    const onClose = () => {
      this.currentReport = null
    }

    return (
      <div>
        <h1>
          <Trans>cases.header</Trans>
        </h1>
        <p>
          <Trans>cases.text</Trans>
        </p>

        <CaseTable cases={errorReports} onClick={onClick} />
        <ModalWindow isOpen={this.currentReport != null} title={this.currentReport ? this.currentReport.number : ''} onClose={onClose}>
          <ReportInfo {...this.currentReport} />
        </ModalWindow>
      </div>
    )
  }
}

export default inject('store')(observer(Page))
