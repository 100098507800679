import React, { useState, useEffect, useRef } from 'react'
import { BankIdClient, Status, ServiceType } from 'lib/bankid'
import BankIdButton from './BankIdButton'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const STATUS = Status
const SERVICE_TYPE = ServiceType

const SSNInput = (props) => (
  <input
    id={props.id}
    type="text"
    onChange={props.onChange}
    pattern="[0-9\-]{10,13}"
    placeholder={i18next.t('bank_id.ssn_placeholder')}
    required
    disabled={props.disabled}
  />
)

const BankIDForm = (props) => {
  const [ssn, setSsn] = useState()

  const submit = function (e) {
    e.preventDefault()
    return props.onSubmit(ssn)
  }

  return (
    <form onSubmit={submit}>
      <div>
        <label htmlFor="ssn">
          <Trans>bank_id.ssn_label</Trans>
        </label>
      </div>
      <SSNInput id={'ssn'} disabled={props.status != STATUS.READY} onChange={(e) => setSsn(e.target.value)} />
      <div style={{ display: 'none' }}>Status: {props.status}</div>
      {props.status != STATUS.ERROR && (
        // heh....
        <React.Fragment>
          <br />
          <br />
        </React.Fragment>
      )}
      <BankIdButton
        type={props.serviceType}
        logo={props.logo}
        error={props.error}
        status={props.status}
        onStart={props.onStart}
        onSubmit={submit}
        onCancel={props.onCancel}
        onRetry={props.onRetry}
      />
    </form>
  )
}


let client
const BankID = (props) => {
  const [status, setStatus] = useState()
  const [error, setError] = useState()
  const timer = useRef()
  const serviceType = props.serviceType || SERVICE_TYPE.BankId
  const mobile = props.mobile

  if (!props.onSuccess) throw new Error('BankID:onSuccess() not set')

  const errorMessages = {
    invalid_ssn: i18next.t('bank_id.error.invalid_ssn'),
    default: i18next.t('bank_id.error.default'),
  }

  const onError = (error) => {
    var message = errorMessages[error]
    if (!message) message = errorMessages['default']

    setStatus(STATUS.ERROR)
    setError(message)
  }

  useEffect(() => {
    client = new BankIdClient({
      mobile: mobile,
      serviceType: serviceType,
      onStatus: (status) => setStatus(status),
      onSuccess: props.onSuccess,
      onError: onError,
    })

    setStatus(STATUS.READY)

    return () => {
      client.dispose()
    }
  }, [mobile, props.onSuccess, serviceType])

  const onStart = () => {
    client.startBankId()
  }
  const onSubmit = (ssn) => {
    client.login({ ssn: ssn })
  }

  const onRetry = () => {
    client.cancel()
    setStatus(STATUS.READY)
    setError(null)
  }

  return (
    <div>
      <BankIDForm
        status={status}
        error={error}
        onStart={onStart}
        onSubmit={onSubmit}
        onCancel={onRetry}
        onRetry={onRetry}
        serviceType={serviceType}
      />
    </div>
  )
}


export default BankID
