import React from 'react'
import Modal from 'react-modal'
import i18next from "i18next";
import { Trans } from 'react-i18next'

const ModalFooter = (props) => {
  return <div className="pp-modal-footer-toolbar">testar lite</div>
}

class ModalWindow extends React.Component {
  render() {
    let { className, overlayClassName, maxWidth, title, ...rest } = this.props

    className = className || {
      base: 'pp-modal-container',
      afterOpen: 'pp-modal-container--open',
      beforeClose: 'pp-modal-container--close',
    }
    overlayClassName = overlayClassName || {
      base: 'pp-modal-overlay',
      afterOpen: 'pp-modal-overlay--open',
      beforeClose: 'pp-modal-overlay--close',
    }

    let toolbar
    if (this.props.toolbar) toolbar = <ModalFooter toolbar={this.props.toolbar} />

    const onClose = () => this.props.onClose(this)

    return (
      <Modal
        portalClassName={'pp-component'}
        className={className}
        overlayClassName={overlayClassName}
        isOpen={this.props.isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={400}
        aria={{
          labelledby: 'id-modal-title',
          modal: 'true',
        }}
        {...rest}
      >
        <div className="pp-modal-window" style={maxWidth && { maxWidth: maxWidth }}>
          <div className="pp-modal-header">
            <div id="id-modal-title" className="pp-modal-header-title">
              {title}
            </div>
            <button className="pp-modal-close" aria-label={i18next.t('label.close_window')} title={i18next.t('label.close_window')} onClick={onClose}>
              <span className="pp-modal-close-icon">
                <svg viewBox="0 0 12 12">
                  <line x1="1" y1="11" x2="11" y2="1" />
                  <line x1="1" y1="1" x2="11" y2="11" />
                </svg>
              </span>
              <span className="pp-modal-close-text"><Trans>label.close</Trans></span>
            </button>
          </div>
          <div className="pp-modal-content">{this.props.children}</div>
          {toolbar}
        </div>
      </Modal>
    )
  }
}

export default ModalWindow
