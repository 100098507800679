import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import Agent from 'Stores/Agent.js'
import Icons from 'lib/icons'
import ModalWindow from 'Components/ModalWindow'
import Utils from 'lib/utils'
import MessageBox from 'Components/MessageBox'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const { datefmt, apiUrl } = Utils

const status2str = {
  pending: 'Väntande',
  authorized: 'Bearbetas',
  approved: 'Betald',
  invoiceSent: 'Faktura skickad',
}

const type2str = {
  agencyCost: 'Förmedlingsavgift',
  yearlyFee: 'Köavgift',
}

const InvoiceModal = ({ paymentId, onSubmit }) => {
  const [submit, setSubmit] = useState(false)

  const onClick = () => {
    setSubmit(true)
    onSubmit(paymentId)
  }

  return (
    <div>
      <p>En faktura kommer skickas ut till dig via e-post inom ett par minuter, du kan även ladda ner fakturan manuellt.</p>
      <div>
        <button onClick={onClick} className="main-btn" disabled={submit}>
          Betala med faktura
        </button>
      </div>
    </div>
  )
}

const SubscriptionModal = ({ paymentId, onSubmit }) => {
  const [submit, setSubmit] = useState(false)

  const onClick = () => {
    setSubmit(true)
    onSubmit(paymentId)
  }

  return (
    <div>
      <p>
      Köavgiften kommer att dras från ditt kort och sedan nästkommande år.
      </p>
      <p>
      Om ditt korts giltighetsdatum löper ut innan nästa år så kommer du inte att kunna lägga in det för prenumeration utan då får du uppdatera det nästa gång det är dags att betala.
      </p>
      <div>
        <button onClick={onClick} className="main-btn" disabled={submit}>
          Aktivera prenumeration
        </button>
      </div>
    </div>
  )
}


const PaymentSubscription = (props) => {
  const { subscription, cancelSubscription } = props
  const expireDate = Utils.datefmt(subscription.expireAt, 'yyyy-MM-dd')
  const debitDate = Utils.datefmt(subscription.debitAt, 'yyyy-MM-dd')

  let content
  let boxClass

  if (subscription.status == 'active') {
    if (expireDate > debitDate) {
      boxClass = "info-box"
      content = <div>Du har en aktiv kortprenumeration med ett kort som är giltigt t.o.m. <b>{expireDate}</b>. Nästa debitering kommer ske <b>{debitDate}</b>
      <button className="main-btn" style={{margin: '5px'}} onClick={() => cancelSubscription(subscription.id)}>Avbryt prenumerationen</button>
      </div>
    } else {
      boxClass = "warning-box"
      content = <div>Du har en aktiv kortprenumeration med ett kort vars giltighetstid (<b>{expireDate}</b>) går ut före nästa debitering <b>{debitDate}</b>.
      <div>
        <button className="main-btn" style={{margin: '5px'}} onClick={() => cancelSubscription(subscription.id)}>Avbryt prenumerationen</button>
      </div>
      </div>
    }
  } else if (subscription.status == 'expired') {
    boxClass = "warning-box"
    content = <div>Din kortprenumeration gick ut <b>{expireDate}</b>. Nästa gång du betalar köavgiften har du möjlighet att starta en ny prenumeration</div>
  }

  return <div className={boxClass}>
    <h4>Prenumeration</h4>
    {content}
  </div>
}

const PaymentsTable = (props) => {
  const { payments, onPaymentRequest, approved, canSubscribe } = props

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <th scope="col">
            <strong>Datum</strong>
          </th>
          <th scope="col">
            <strong>Typ</strong>
          </th>
          <th scope="col">
            <strong>Belopp</strong>
          </th>
          <th scope="col">
            <strong>Status</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {payments.map((p, i) => (
          <React.Fragment>
            <tr key={i}>
              <td>{datefmt(p.createdDate, 'yyyy-MM-dd')}</td>
              <td>{type2str[p.orderType]}</td>
              <td>{p.amount} kr</td>
              <td>{status2str[p.status]} {p.status != 'pending' && datefmt(p.paymentDate, 'yyyy-MM-dd')}</td>
            </tr>
            {(p.status == 'pending' || p.status=='invoiceSent') && (
            <tr key={i + '-2'}>
              <td colspan={4}>
                {p.status == 'pending' && (
                  <React.Fragment>
                    <div>

                      <button
                        className="main-btn"
                        disabled={!approved}
                        onClick={() => onPaymentRequest(p.id, 'bambora')}
                        style={{ margin: '5px' }}
                      >
                        Direktbetalning med Swish
                      </button>
                      <button
                        className="main-btn"
                        disabled={!approved}
                        onClick={() => onPaymentRequest(p.id, 'bambora')}
                        style={{ margin: '5px' }}
                      >
                        Direktbetalning med kort
                      </button>
                      {canSubscribe && <button
                        className="main-btn"
                        disabled={!approved}
                        onClick={() => onPaymentRequest(p.id, 'subscription')}
                        style={{ margin: '5px' }}
                      >
                        Kortprenumeration
                      </button>}
                      <button
                        className="main-btn"
                        disabled={!approved}
                        onClick={() => onPaymentRequest(p.id, 'invoice')}
                        style={{ margin: '5px' }}
                      >
                        Betala med faktura
                      </button>
                    </div>
                  </React.Fragment>
                )}
                {p.status == 'invoiceSent' && (
                  <React.Fragment>
                    <a
                      href={apiUrl('/payments/downloadinvoice?id=' + p.id)}
                      className="main-btn"
                      download="test.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ladda ner
                    </a>
                  </React.Fragment>
                )}
              </td>
            </tr>)}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}

const QueuePlace = ({ queue, forceReload }) => {
  const [approved, setApproved] = useState()
  const [invoiceToPay, setInvoiceToPay] = useState(null)
  const [showVerifySubscription, setShowVerifySubscription] = useState(null)

  const onPaymentRequest = (id, method) => {
    if (method == 'invoice') {
      setInvoiceToPay(id)
    } else if (method == 'subscription') {
      setShowVerifySubscription(id)
    } else {
      Agent.Queue.getPaymentUrl(id).then((data) => {
        window.location.href = data.paymentUrl
      })
    }
  }

  const hasPending = queue.pending.length > 0 && queue.pending.filter((p) => p.status == 'pending').length > 0

  const cancelSubscription = (id) => {
    Agent.Queue.cancelSubscription(id).then(() => {
      forceReload()
    });
  }

  return (
    <React.Fragment>
      {queue.subscription && <PaymentSubscription cancelSubscription={cancelSubscription} subscription={queue.subscription} />}
      {queue.info && <div dangerouslySetInnerHTML={{ __html: queue.info }} />}
      <div className="panel">
        {queue.active && <div className="panel-header">Dina köpoäng</div>}
        {!queue.active && <div className="panel-header">Betala din köavgift</div>}
        {queue.blockedUntil && <MessageBox type="error">Din köplats är spärrad tillochmed {datefmt(queue.blockedUntil)}</MessageBox>}
        <div className="panel-body">
          {queue.active && (
            <ul className="simple-list mb-small">
              <li>
                <strong>Startdatum</strong>
                <span style={{ float: 'right' }}>{datefmt(queue.applicationDate, 'yyyy-MM-dd')}</span>
              </li>
              <li>
                <strong>Poäng</strong>
                <span style={{ float: 'right' }}>{queue.points}</span>
              </li>
              <li>
                <strong>Betald till</strong>
                <span style={{ float: 'right' }}>{datefmt(queue.inactivationDate, 'yyyy-MM-dd')}</span>
              </li>
              {queue.blockedUntil && (
                <li>
                  <strong>Spärrad till</strong>
                  <span style={{ float: 'right' }}>{datefmt(queue.blockedUntil, 'yyyy-MM-dd')}</span>
                </li>
              )}
            </ul>
          )}
          {queue.pending && queue.pending.length > 0 && (
            <div>
              <div style={{ marginBottom: 10 }}>
                {hasPending && (
                  <React.Fragment>
                    <input id="approve" type="checkbox" onChange={() => setApproved(!approved)} />
                    &nbsp;
                    <label htmlFor="approve">Jag godkänner Sigtuna Bostadsförmedlings&nbsp;</label>
                    <a target="_blank" href="https://bostadsigtuna.se/om-oss/kopvillkor/" class="pp-link" rel="noopener noreferrer">
                      köpvillkor
                    </a>
                  </React.Fragment>
                )}
              </div>
              <PaymentsTable payments={queue.pending} onPaymentRequest={onPaymentRequest} approved={approved || !hasPending} canSubscribe={queue.canSubscribe} />
            </div>
          )}
        </div>
      </div>
      <ModalWindow
        isOpen={showVerifySubscription != null}
        onClose={() => setShowVerifySubscription(null)}
        title={i18next.t('queue.verify_subscription')}
        maxWidth={600}
      >
        <SubscriptionModal
          paymentId={showVerifySubscription}
          onSubmit={(id) => {
            setInvoiceToPay(null)
            Agent.Queue.getPaymentUrl(id, true).then((data) => {
              window.location.href = data.paymentUrl
            })
          }}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={invoiceToPay != null}
        onClose={() => setInvoiceToPay(null)}
        title={i18next.t('queue.verify_invoice')}
        maxWidth={500}
      >
        <InvoiceModal
          paymentId={invoiceToPay}
          onSubmit={(id) => {
            Agent.Queue.invoicePaymentRequest(id)
              .then((data) => {
                setInvoiceToPay(null)
                forceReload()
              })
              .catch((err) => {
                alert(err)
                setInvoiceToPay(null)
              })
          }}
        />
      </ModalWindow>
    </React.Fragment>
  )
}

const QueueStatusAndPayments = ({ queue, forceReload }) => (
  <Tabs>
    <TabList>
      <Tab>{Icons.Profile} Köpoäng</Tab>
      <Tab>{Icons.Mail} Betalningshistorik</Tab>
    </TabList>

    <TabPanel>
      <QueuePlace queue={queue} forceReload={forceReload} />
    </TabPanel>
    <TabPanel>{<PaymentsTable payments={queue.history} />}</TabPanel>
  </Tabs>
)

const JoinQueue = ({ queueName, onJoin }) => {
  const [approved, setApproved] = useState()
  const theQueue = queueName + 'n'
  var link

  if (queueName.includes('tomt')) link = 'hus-och-tomtko/tomt-och-husko-formedlingsregler'
  else link = 'koregler'

  return (
    <React.Fragment>
      <div style={{ marginBottom: 10 }}>
        <p></p>
        <input id="approve" type="checkbox" onChange={() => setApproved(!approved)} />
        &nbsp;
        <label htmlFor="approve">Jag är införstådd med Sigtuna Bostadsförmedlings </label>
        <a target="_blank" href={'https://bostadsigtuna.se/' + link} className="pp-link" rel="noopener noreferrer">
          kö- och förmedlingsregler för {queueName}
        </a>
      </div>

      <button className="main-btn" onClick={onJoin} disabled={!approved}>
        Gå med i {theQueue}
      </button>
    </React.Fragment>
  )
}

const QueuePage = (props) => {
  const [loading, setLoading] = useState(true)
  const [queueStatus, setQueueStatus] = useState([])

  // This hardcoded nonsense is an ugly hack due to the lack of time / bad planning
  // The correct way would be to build the menu according to data provided by the API

  var queueId, title, theQueue

  if (props.match.path == '/bostadsko') {
    queueId = 'fc8634bd-60a9-4605-8c22-0812a695ce1a'
    title = 'Bostadskö'
  } else {
    queueId = '2b75b199-df82-4276-9393-fc44a4711076'
    title = 'Tomt- och huskö'
  }

  theQueue = title.toLowerCase() + 'n'

  const loadQueueStatus = () => {
    setLoading(true)
    Agent.Queue.paymentStatus(queueId).then((data) => {
      setQueueStatus(data)
      setLoading(false)
    })
  }

  const onJoinQueue = () => {
    Agent.Queue.joinQueue(queueStatus.queueId).finally(() => {
      loadQueueStatus()
    })
  }

  useEffect(loadQueueStatus, [queueId])

  if (loading)
    return (
      <span>
        <Trans>loading</Trans>
      </span>
    )

  return (
    <div>
      <h1>{title}</h1>

      {queueStatus.applicationDate && <QueueStatusAndPayments queue={queueStatus} forceReload={() => loadQueueStatus()} />}
      {!queueStatus.applicationDate && queueStatus.hasEmail && (
        <JoinQueue queue={queueStatus} onJoin={onJoinQueue} queueName={title.toLowerCase()} />
      )}
      {!queueStatus.applicationDate && !queueStatus.hasEmail && (
        <div>
          <MessageBox>Du måste bekräfta din epostadress innan du kan gå med i {theQueue}</MessageBox>
          <Link to="/profil" className="main-btn">
            Gå till profilen
          </Link>
        </div>
      )}
    </div>
  )
}

export default QueuePage
