import React from 'react'
import { Redirect } from 'react-router-dom'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'

const Page = class Page extends React.Component {
  componentWillUnmount() {
    this.props.store.auth.logout()
  }

  render() {
    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <Redirect to="/loggain" />
      </div>
    )
  }
}

export default inject('store')(observer(Page))
