import React from 'react'
import { toJS } from 'mobx'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import Form from 'Components/Forms/Form'
import {Trans} from "react-i18next";
import i18next from "i18next";

class ResetPasswordForm extends React.Component {
  render() {
    const { consentDate } = this.props

    const form = {
      type: 'object',
      description: null,
      hideRequired: true,
    }

    let buttonText = null

    if (this.props.token) {
      form.fields = [
        { name: 'password', title: i18next.t('label.new_password'), required: true },
        { name: 'password2', title: i18next.t('label.new_password_repeat'), required: true },
      ]

      if (!consentDate)
        form.fields.push({
          name: 'agree',
          title: i18next.t('label.agree'),
          options: { text: this.props.registerTerms },
          type: 'boolean',
          required: true,
          widget: 'agree',
        })

      buttonText = i18next.t('label.save_new_password')
    } else {
      form.fields = [{ name: 'email', title: i18next.t('label.email'), required: true }]
      buttonText = i18next.t('label.request_new_password')
    }

    return <Form schema={form} {...this.props} onSubmit={this.props.onSubmit} hideOnSuccess={true} submit={buttonText} />
  }
}

class Page extends React.Component {
  constructor(props) {
    super()

    const args = this.getArgs(props.location)

    this.state = {
      loading: true,
      consentDate: null,
      token: args.token,
      email: args.email,
    }
  }

  componentDidMount() {
    this.props.store.ui.setPasswordSuccess = false
    this.props.store.ui.resetPasswordSuccess = false

    this.props.store.portal.loadInfo()
    this.checkConsent()
  }

  getArgs(location) {
    const array = location.pathname.split('/')
    let email, token

    if (array.length == 4) {
      email = array[2]
      token = array[3]
    }

    return { email, token }
  }

  async checkConsent() {
    let consentDate
    let newState = { loading: false }

    if (this.state.email && this.state.token) {
      try {
        consentDate = await this.props.store.auth.getConsentDate(this.state.email, this.state.token)
        newState.consentDate = consentDate
      } catch (error) {
        newState.email = null
        newState.token = null
      }
    }

    this.setState(newState)
  }

  render() {
    const { store } = this.props
    const { loading, consentDate, email, token } = this.state
    const info = store.portal.info
    const documents = toJS(info.get('documents'))
    const registerTerms = documents && documents.registerTerms

    if (loading) return <div><Trans>loading</Trans></div>

    const onSubmit = (form) => {
      if (token) {
        const values = form.formData

        if (values.password !== values.password2) {
          throw {
            status: 400,
            response: {
              body: {
                errors: [
                  {
                    property: undefined,
                    message: i18next.t('error.password_mismatch'),
                  },
                ],
              },
            },
          }
        }

        return store.auth.setNewPassword(email, token, form.formData.password).then(() => {
          store.ui.setPasswordSuccess = true
        })
      } else {
        return store.auth.requestNewPassword(form.formData.email).then(() => {
          store.ui.resetPasswordSuccess = true
        })
      }
    }

    let content
    if (store.ui.resetPasswordSuccess) {
      content = <p className="info"><Trans>info.password_reset_email_sent</Trans></p>
    } else if (store.ui.setPasswordSuccess) {
      content = <p className="info"><Trans>info.password_changed</Trans></p>
    } else {
      content = (
        <div>
          {!token && <em><Trans>reset_password.helper</Trans></em>}
          <ResetPasswordForm onSubmit={onSubmit} token={token} consentDate={consentDate} registerTerms={registerTerms} />
        </div>
      )
    }

    return (
      <div>
        <h1><Trans>reset_password.header</Trans></h1>
        {content}
      </div>
    )
  }
}

export default inject('store')(observer(Page))
