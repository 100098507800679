import React from 'react'
import { extendObservable } from 'mobx'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import observer from 'Decorators/Observer.js'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import BankID from 'Components/BankID'
import Form from 'Components/Forms/Form'
import Icons from 'lib/icons'
import i18next from "i18next";
import { Trans } from "react-i18next";

const LoginForm = (props) => {
  var form = {
    type: 'object',
    title: undefined,
    description: undefined,
    hideRequired: false,
    fields: [
      { name: 'email', title: i18next.t('label.email'), required: true },
      { name: 'password', title: i18next.t('label.password'), required: true },
    ],
  }

  return (
    <Form schema={form} onChange={props.onChange} onSubmit={props.onSubmit} onError={props.onError} submit={i18next.t('login.button')} autocomplete="on">
      {props.children}
    </Form>
  )
}

class Page extends React.Component {
  constructor() {
    super()

    extendObservable(this, {
      invalidLogin: false,
    })
  }

  componentDidMount() {
    this.props.store.ui.firstPage = true
  }

  componentWillUnmount() {
    this.props.store.ui.firstPage = false
  }

  onSubmit = (form) => {
    const values = form.formData
    return this.props.store.auth.login(values.email, values.password).then(async () => {
      await this.props.store.user.preloadUser()
    })
  }

  onBankIdSuccess = (data) => {
    this.props.store.auth.loginBankId(data)
  }

  onBankIdLogin = (result) => {
    const loginType = result.serviceType == 0 ? 'bankid' : 'frejaid'
    this.props.store.auth.setAccessToken(result.token, loginType)
  }

  render() {
    const { store } = this.props
    const content = []

    const info = this.props.store.portal.info
    const bankId = info.get('bankId')
    const frejaId = info.get('frejaId')
    const emailAuth = info.get('emailAuth')
    const documents = info.get('documents')
    const about = documents && documents.about

    if (this.invalidLogin)
      content.push(
        <div key={1} className="info-box error-box" data="alert">
          <Trans>error.wrong_credentials</Trans>
        </div>
      )

    if (store.auth.apiError)
      content.push(
        <div key={1} className="info-box error-box" data="alert">
          <Trans>error.temporary</Trans>
        </div>
      )

    content.push(
      <LoginForm key={2} onSubmit={this.onSubmit}>
        <span className="form-extra-container">
          <span className="grey-dash">&ndash;</span>
          <Link to="/losenord"><Trans>login.forgot_password</Trans></Link>
        </span>
      </LoginForm>
    )

    if (!(bankId || frejaId || emailAuth)) return <Trans>loading</Trans>

    return (
      <div>
        <h1><Trans>login.header</Trans></h1>
        <Tabs>
          <TabList>
            {emailAuth && (
              <Tab>
                {Icons.Profile}
                <Trans>login.email</Trans>
              </Tab>
            )}
            {bankId && (
              <Tab>
                {Icons.Lock}
                <Trans>login.mobile_bank_id</Trans>
              </Tab>
            )}
            {bankId && (
              <Tab>
                {Icons.Lock}
                <Trans>login.bank_id</Trans>
              </Tab>
            )}
            {frejaId && (
              <Tab>
                {Icons.Lock}
                <Trans>login.freja_id</Trans>
              </Tab>
            )}
            {(frejaId || bankId) && <Tab><Trans>login.about_e_identification</Trans></Tab>}
          </TabList>
          {emailAuth && <TabPanel>{content}</TabPanel>}
          {bankId && (
            <TabPanel>
              <BankID onSuccess={this.onBankIdLogin} mobile={true} />
            </TabPanel>
          )}
          {bankId && (
            <TabPanel>
              <BankID onSuccess={this.onBankIdLogin} />
            </TabPanel>
          )}
          {frejaId && (
            <TabPanel>
              <BankID onSuccess={this.onBankIdLogin} title="FrejaID" serviceType="1" />
            </TabPanel>
          )}
          {(frejaId || bankId) && <TabPanel dangerouslySetInnerHTML={{ __html: about }}></TabPanel>}
        </Tabs>
      </div>
    )
  }
}

export default inject('store')(observer(Page))
