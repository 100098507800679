import { observable, runInAction, extendObservable, toJS } from 'mobx'
import agent from './Agent'
import i18next from 'i18next'

class QueueStore {
  constructor(parent) {
    this.parent = parent

    extendObservable(this, {
      available: [],
      history: [],
      offers: [],
      queues: observable(new Map()),
      subscriptions: observable(new Map()),

      numNotResponded: () => {
        var unanswered = this.offers.filter((o) => o.status == 'offered' && o.answer == 'notAnswered')
        return unanswered.length
      },

      numOffers: () => {
        return this.offers.length
      },

      numAvailable: () => {
        return this.available.length
      },

      numPayments: () => {
        return this.numPayments
      },
    })
  }

  async loadQueues() {
    const response = await agent.Queue.getQueues()
    if (!response) return

    runInAction(() => {
      this.queues.replace(response)
    })
  }

  async getQueues() {
    if (this.queues.size == 0) await this.loadQueues()

    return this.queues
  }

  loadObjects() {
    return agent.Queue.getObjects().then((response) => {
      this.available = [...response.available]
      this.history = [...response.history]
      this.offers = [...response.offers]
    })
  }

  setAnswer(obj, type, answer) {
    return agent.Queue.setAnswer(obj.id, type, answer).then(() => {
      let obj

      if (type == 'offer') obj = this.offers.find((o) => o == obj)
      else obj = this.available.find((o) => o == obj)

      if (obj) obj.answer = answer ? i18next.t('yes') : i18next.t('no')

      this.loadObjects()
    })
  }

  showInterest(obj, interested) {
    return this.setAnswer(obj, 'interest', interested)
  }

  respondToOffer(obj, response) {
    return this.setAnswer(obj, 'offer', response)
  }

  async loadSubscriptions() {
    const response = await agent.Queue.getSubscriptions()
    if (!response) return

    runInAction(() => {
      this.subscriptions.replace(response.subscriptions)
    })
  }

  getSubscriptions() {
    return this.subscriptions
  }

  showOffers() {
    var queues = this.queues.toJS()

    // check if any queue has offer stage
    return queues.some((queue) => !queue.skipOfferStage)
  }

  async setSubscription(subscription) {
    await agent.Queue.setSubscription(subscription)
    await this.loadSubscriptions()
    return await this.loadObjects()
  }

  async joinQueue(queueId) {
    return await agent.Queue.joinQueue(queueId).then(() => this.loadSubscriptions())
  }

  async leaveQueue(queueId, password) {
    return await agent.Queue.leaveQueue(queueId, password).then(() => this.loadSubscriptions())
  }

  getNameMapping() {
    const mapping = { areas: {}, classes: {} }

    const addAreas = (areas) => {
      if (!areas || areas.length == 0) return

      areas.forEach((area) => {
        mapping.areas[area.value] = area.label
        area.children && addAreas(area.children)
      })
    }

    this.queues.forEach((queue) => {
      const data = toJS(queue)

      if (data) {
        data.classOptions.forEach((opt) => (mapping.classes[opt.key] = opt.value))
        addAreas(data.areaOptions)
      }
    })

    return mapping
  }
}

export default QueueStore
