import React from 'react'
import { useEffect } from 'react'
import { inject } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import observer from 'Decorators/Observer'
import { useMediaQuery } from 'react-responsive'

import 'css/hamburgers.css'

import { ReactComponent as IconUser } from 'Images/SVG/user.svg'
import i18next from "i18next";
import { Trans } from 'react-i18next'

const Item = (props) => {
  const bubble = props.count > 0 ? <span className="menu-bubble">{props.count}</span> : null

  const onClick = (e) => {
    props.onClick(e)
  }

  return (
    <li>
      <NavLink activeClassName="menu-item-active" to={props.path} onClick={onClick}>
        {props.name}
        {bubble}
      </NavLink>
    </li>
  )
}

const Menu2 = (props) => {
  const [folded, setFolded] = React.useState(true)

  var isBigScreen = useMediaQuery({ query: '(min-width: 980px)' })

  useEffect(() => {
    if (isBigScreen) {
      setFolded(false)
    } else {
      setFolded(true)
    }
  }, [isBigScreen])

  const changeFolding = () => {
    setFolded(!folded)
  }

  const linkTo = (e) => {
    if (!isBigScreen && !folded) {
      setFolded(true)
    }

    if (props.location.pathname != e.key) props.history.push(e.key)
  }

  var className
  if (!folded)
    className = 'is-active'
  else
    className = ''


  let menuItems = []
  let i = 1
  props.items.forEach((item) => menuItems.push(<Item key={i++} {...item} onClick={linkTo} />))

  let containerClassName = 'menu-container'
  let menuTitle = i18next.t('menu.sub_menu_title')

  if (props.isStandalone == 'true') {
    containerClassName = 'standalone-menu-container'
    menuTitle = i18next.t('menu.title')
  }

  let currentUserContainer
  const currentUser = props.store.auth.currentUser
  if (currentUser) {
    currentUserContainer = (
      <li className="current-user">
        <small><Trans>menu.logged_in_as</Trans></small>
        <span>
          <IconUser className="pp-config-main-color" aria-hidden="true" /> {currentUser.name}
        </span>
      </li>
    )
  }

  return (
    <nav>
      <div className={containerClassName}>
        <div className="menu-logo-container">
          <a href="#" className="menu-logo">
            <Trans>menu.personal_page</Trans>
          </a>
        </div>
        <div className={'menu ' + className}>
          <a onClick={changeFolding} className={'pp-menu-toggle hamburger--collapse hamburger ' + className}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
            <span className="menu-title">{menuTitle}</span>
          </a>
          <div style={{ display: !isBigScreen && folded ? "none" : "block" }} aria-hidden={isBigScreen ? false : folded}>
            <ul className="menuList">
              {currentUserContainer}
              {menuItems}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default inject('store')(observer(Menu2))
