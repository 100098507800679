import React from 'react'
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik'
import MessageBox from 'Components/MessageBox'
import TreeSelect from 'Components/TreeSelect'
import MultiSelect from 'Components/MultiSelect'
import * as Yup from 'yup'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

Yup.setLocale({
  number: {
    min: i18next.t('number.min'),
    max: i18next.t('number.max'),
  },
})

const FormGroup = (prop) => (
  <div className={'form-group field field-' + (prop.type || 'text')} style={prop.style}>
    <label htmlFor={prop.name}>{prop.title}</label>
    <div className="input-container">{prop.children}</div>
  </div>
)
const FormGroupFieldset = (prop) => (
  <div className={'form-group field field-' + (prop.type || 'text')}>
    <fieldset>
      <legend htmlFor={prop.name}>{prop.title}</legend>
      <div className="input-container">{prop.children}</div>
    </fieldset>
  </div>
)

const ObjectClasses = (props) => {
  const options = props.options
  const limit = props.limit
  const selected = props.selected

  return (
    <FieldArray
      name="objectClasses"
      render={(arrayHelpers) => (
        <div className="checkboxes">
          {options.map((cls) => (
            <div key={cls.key} className="checkbox">
              <input
                id={'obj_' + cls.key}
                name="objectClasses"
                className="checkbox"
                value={cls.key}
                checked={selected.includes(cls.key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    if (limit.max > 0 && selected.length >= limit.max) return
                    arrayHelpers.push(cls.key)
                  } else {
                    const idx = selected.indexOf(cls.key)
                    arrayHelpers.remove(idx)
                  }
                }}
                type="checkbox"
              />{' '}
              <label htmlFor={'obj_' + cls.key}>{cls.value}</label>
            </div>
          ))}
        </div>
      )}
    />
  )
}

const GetValidationSchema = (areaLimit, classLimit, maxRentRequired, maxRentLimit) => {
  const validation = {}
  if (maxRentRequired) validation.maxRent = Yup.number().required(i18next.t('max_rent_required')).min(0).max(maxRentLimit)
  else validation.maxRent = Yup.number().nullable().min(0).max(maxRentLimit)

  let areas = Yup.array()
  if (areaLimit.min != null) areas = areas.min(areaLimit.min, i18next.t('areas_min_required', { nr: areaLimit.min, count: areaLimit.min }))
  if (areaLimit.max > 0) areas = areas.max(areaLimit.max, i18next.t('areas_max_required', { nr: areaLimit.max, count: areaLimit.max }))
  validation.areas = areas

  let objectClasses = Yup.array()
  if (classLimit.min != null)
    objectClasses = objectClasses.min(classLimit.min, i18next.t('object_type_min', { nr: classLimit.min, count: classLimit.min }))

  validation.objectClasses = objectClasses
  return Yup.object(validation)
}

const SubscriptionForm = ({ subscription, queue, onSubmit, useTree }) => {
  const areaOptions = queue.areaOptions
  const classOptions = queue.classOptions
  const areaLimit = queue.areaLimit
  const classLimit = queue.classLimit
  const maxRentRequired = queue.maxRentRequired
  const maxRentLimit = queue.maxRentLimit
  const visible = queue.subscriptionFields

  if (subscription.maxRent == '0') subscription.maxRent = null

  return (
    <Formik
      initialValues={{
        elevator: subscription.elevator,
        groundFloor: subscription.groundFloor,
        maxRent: subscription.maxRent,
        areas: subscription.areas,
        objectClasses: subscription.objectClasses,
        comment: subscription.comment,
        subscribe: subscription.subscribe,
      }}
      validationSchema={GetValidationSchema(areaLimit, classLimit, maxRentRequired, maxRentLimit)}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values)
          .catch((err) => {
            const errors = {}
            err.response.body.errors.map((e) => (errors[e.property] = e.message))
            setErrors(errors)
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}
    >
      {({ setFieldValue, handleBlur, handleChange, values, isValid, isSubmitting, status, errors }) => {
        const handleChangeTree = (currentNode, selectedNodes) => {
          const selected = []
          selectedNodes.forEach((node) => {
            selected.push(node.value)
          })

          setFieldValue('areas', selected)
        }

        const handleChangeMultiSelect = (selected) => {
          setFieldValue('areas', selected)
        }

        return (
          <Form className="rjsf">
            <div className="form-group">
              <div className="form-group field field-object">
                <div className="input-container">
                  <fieldset>
                    <legend id="subscribeHeader">
                      <Trans>subscription.header</Trans>
                    </legend>
                    <div className="form-group field field-boolean">
                      <div className="input-container" style={{ width: '100%', maxWidth: '100%' }}>
                        <div className="checkbox">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.subscribe}
                            id="subscribe"
                            name="subscribe"
                            type="checkbox"
                          />
                          <label htmlFor="subscribe">
                            &nbsp;<Trans>subscription.helper</Trans>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="form-group field field-object">
                <div className="input-container">
                  <fieldset>
                    <legend id="title">
                      <Trans>label.wants</Trans>
                    </legend>
                    <FormGroup title={i18next.t('label.areas')} name="areas">
                      {areaLimit.max > 0 && (
                        <div>
                          <b>
                            <Trans tOptions={{ min: areaLimit.min, max: areaLimit.max }}>subscription.choose_areas</Trans>
                          </b>
                        </div>
                      )}
                      {!useTree && (
                        <MultiSelect name="areas" data={areaOptions} selected={values.areas} onChange={handleChangeMultiSelect} />
                      )}
                      {useTree && <TreeSelect name="areas" data={areaOptions} selected={values.areas} onChange={handleChangeTree} />}
                    </FormGroup>
                    <FormGroup type="integer" title={i18next.t('label.max_' + queue.priceType)} name="maxRent">
                      <Field id="maxRent" name="maxRent" type="number" />
                      <ErrorMessage
                        name="maxRent"
                        className="error-detail"
                        role="alert"
                        render={(msg) => <span className="required"> {msg}</span>}
                      />
                    </FormGroup>

                    <FormGroupFieldset title={i18next.t('label.object_type')} name="objectClasses">
                      {classLimit.max > 0 && (
                        <div>
                          <b>
                            <Trans tOptions={{ min: classLimit.min, max: classLimit.max }}>subscription.choose_object_types</Trans>
                          </b>
                        </div>
                      )}
                      <ObjectClasses
                        onBlur={handleBlur}
                        onChange={handleChange}
                        selected={values.objectClasses}
                        options={classOptions}
                        limit={classLimit}
                        name="objectClasses"
                      />
                    </FormGroupFieldset>
                    {visible.includes('elevator') && (
                      <FormGroup title={i18next.t('label.elevator')} type="boolean" name="elevator">
                        <div className="checkbox">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.elevator}
                            id="elevator"
                            name="elevator"
                            type="checkbox"
                          />
                          <label htmlFor="elevator">
                            <Trans>label.elevator</Trans>
                          </label>
                        </div>
                      </FormGroup>
                    )}
                    {visible.includes('groundFloor') && (
                      <FormGroup title={i18next.t('label.ground_floor')} type="boolean" name="groundFloor">
                        <div className="checkbox">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.groundFloor}
                            id="groundFloor"
                            name="groundFloor"
                            type="checkbox"
                          />
                          <label htmlFor="groundFloor">
                            <Trans>label.ground_floor</Trans>
                          </label>
                        </div>
                      </FormGroup>
                    )}
                    {queue.showComment && (
                      <FormGroup title={i18next.t('label.enter_other_wants')} type="text" name="comment">
                        <textarea
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.comment}
                          name="comment"
                          label={i18next.t('label.other_wants')}
                          rows={3}
                        />
                      </FormGroup>
                    )}

                    {errors.areas && <MessageBox type="error">{errors.areas}</MessageBox>}
                    {errors.objectClasses && <MessageBox type="error">{errors.objectClasses}</MessageBox>}
                    <button className="main-btn" type="submit" disabled={!(isValid && !isSubmitting)}>
                      <Trans>label.save</Trans>
                    </button>
                  </fieldset>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SubscriptionForm
