import React, { useState, useEffect, useRef } from 'react'
import agent from 'Stores/Agent'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const CertificateRow = (props) => {
  return (
    <li>
      {props.name}
      <button onClick={() => props.onDelete(props.id)} style={{ marginLeft: '5px' }}>
        <Trans>label.remove</Trans>
      </button>
    </li>
  )
}
const Certificates = function (props) {
  const [file, setFile] = useState()
  const [error, setError] = useState()
  const [certificates, setCertificates] = useState()
  const [uploading, setUploading] = useState()

  useEffect(() => {
    loadCertificates()
  }, [])

  const loadCertificates = () => {
    agent.User.getCertificates().then((certs) => {
      setCertificates(certs)
    })
  }

  const selectFile = (e) => {
    let file = e.target.files[0]
    setFile(file)
  }

  const uploadFile = (e) => {
    setUploading(true)
    setError(false)
    var data = new FormData()
    data.append('file', file)
    agent.User.uploadCertificate(data)
      .then(() => {
        loadCertificates()
      })
      .catch((e) => {
        setError(i18next.t('error.something_during_upload'))
      })
      .finally(() => setUploading(false))
  }

  const deleteCert = (id) => {
    agent.User.deleteCertificate(id).then((success) => {
      const newCerts = certificates.filter((c) => c.id !== id)
      setCertificates(newCerts)
    })
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: props.certificateInfo }} />
      <ul>{certificates && certificates.map((c) => <CertificateRow {...c} onDelete={deleteCert} />)}</ul>
      &nbsp;
      <input type="file" name="file" onChange={selectFile} />
      <button
        style={{ marginLeft: '5px' }}
        className="main-btn"
        name="submit"
        value={i18next.t('label.upload')}
        onClick={uploadFile}
        disabled={!file || uploading}
      >
        <Trans>label.upload</Trans>
      </button>
      {error && (
        <span style={{ color: 'red' }} role="alert">
          <Trans>error.something_during_upload</Trans>
        </span>
      )}
    </div>
  )
}

const CertificateSection = ({ certificateInfo }) => (
  <div className="field-object">
    <div className="input-container">
      <fieldset>
        <legend id="root__title2">
          <Trans>label.certificates</Trans>
        </legend>
        <Certificates certificateInfo={certificateInfo} />
      </fieldset>
    </div>
  </div>
)

export default CertificateSection
