import React from 'react'
import Form from 'Components/Forms/Form'
import i18next from 'i18next'

const getFormData = (registerTerms) => {
  return {
    description: '',
    fields: [
      { name: 'email', title: i18next.t('label.email'), required: true },
      { name: 'firstname', title: i18next.t('label.firstname'), required: true },
      { name: 'lastname', title: i18next.t('label.lastname'), required: true },
      { name: 'ssn', title: i18next.t('label.ssn') },
      { name: 'birthday', title: i18next.t('label.birthday') },
      { name: 'street', title: i18next.t('label.street') },
      { name: 'zipcode', title: i18next.t('label.zipcode') },
      { name: 'city', title: i18next.t('label.city') },
      { name: 'phone', type: 'tel', title: i18next.t('label.phone') },
      { name: 'phoneHome', title: i18next.t('label.phone_home') },
      { name: 'phoneWork', title: i18next.t('label.phone_work') },
      { name: 'password', title: i18next.t('label.password'), required: true },
      { name: 'password2', title: i18next.t('label.password_repeat'), required: true },
      {
        name: 'agree',
        title: i18next.t('label.agree'),
        options: { text: registerTerms },
        type: 'boolean',
        required: true,
        widget: 'agree',
      },
    ],
  }
}

const ModifyFields = (spec, visible) => {
  var fields = []

  for (var field of spec.fields) {
    if (field.required || visible[field.name]) {
      field.required = field.required || visible[field.name] == 'required'
      fields.push(field)
    }
  }

  spec.fields = fields
}

const RegisterForm = (props) => {
  const formData = null
  const form = getFormData(props.registerTerms)

  ModifyFields(form, props.regInfo.fields)

  return (
    <Form
      schema={form}
      formData={formData}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      onSuccess={props.onSuccess}
      onError={props.onError}
      submit={i18next.t('label.register_button')}
    />
  )
}

export default RegisterForm
