import React, { Component } from 'react'
import Select from 'react-select'
import isEqual from 'lodash/isEqual'
import i18next from "i18next";

const fillOptions = (options, items) => {
  for (var item of items) {
    if (item.type == 'Neighbourhood') options.push({ label: item.label, value: item.value })
    if (item.children) fillOptions(options, item.children)
  }
}

const MultiSelect2 = (props) => {
  const options = []
  fillOptions(options, props.data)

  const defaultValues = options.filter((o) => props.selected.includes(o.value))

  const onChange = (change) => {
    if (!change) return
    const values = change.map((c) => c.value)

    props.onChange(values)
  }

  return (
    <Select
      className="multiselect"
      isSearchable={true}
      defaultValue={defaultValues}
      isMulti
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      options={options}
      onChange={onChange}
      noOptionsMessage={() => i18next.t('label.no_options')}
    />
  )
}

const MultiSelect = (props) => {
  const onChange = (e) => {
    const values = Array.from(e.target.selectedOptions, (item) => item.value)
    props.onChange(values)
  }

  const options = []
  fillOptions(options, props.data)
  const selected = {}
  props.selected.map((value) => (selected[value] = true))

  return (
    <select id={props.name} name={props.name} onChange={onChange} multiple>
      {options.map((o) => (
        <option value={o.value} selected={selected[o.value]}>
          {o.label}
        </option>
      ))}
    </select>
  )
}

export default MultiSelect
