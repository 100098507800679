import React from 'react'

const TelWidget = (props) => {
  return (
    <input type='tel' className='form-control' id={props.id}
      title={props.title} value={props.value || ''} required={props.required}
      pattern={props.pattern || '\\+?[0-9]{3,6}-?[0-9]{6,12}'} // '\+?[0-9]{3}-?[0-9]{6,12}'
      onChange={(event) => props.onChange(event.target.value)}
    />)
}

export default TelWidget