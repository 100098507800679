import { useState } from "react";

const SegmentedControl = ({
    name,
    segments,
    onChange,
    defaultIndex = 0,
}) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);

    const onInputChange = (e, index) => {
        setActiveIndex(index);
        onChange(e)
    };

    return (
        <div className="segment-control">
            {segments?.map((item, i) => (
                <div
                    key={item.value}
                    className={`segment ${i === activeIndex ? "active" : "inactive"}`}
                >
                    <input
                        type="radio"
                        value={item.value}
                        id={item.label}
                        name={name}
                        onChange={(e) => onInputChange(e, i)}
                        checked={i === activeIndex}
                    />
                    <label htmlFor={item.label}>{item.label}</label>
                </div>
            ))}
        </div>
    );
};

export default SegmentedControl;