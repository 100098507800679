import React, { useEffect, useState } from 'react'
import observer from 'Decorators/Observer.js'
import RegisterForm from './Forms/RegisterForm.js'
import RegisterBankIdForm from './Forms/RegisterBankIdForm.js'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

import { Root as store } from 'Stores'
import agent from 'Stores/Agent'

const Page = (props) => {
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [regInfo, setRegInfo] = useState(null)

  useEffect(() => {
    ;(async () => {
      let [info, regInfo] = await Promise.all([store.portal.loadInfo(), agent.Portal.regInfo()])
      setRegInfo(regInfo)
      setLoading(false)
    })()
  }, [])

  const onSubmit = (form) => {
    const values = form.formData

    if (values.password !== values.password2) {
      throw {
        status: 400,
        response: {
          body: {
            errors: [
              {
                property: undefined,
                message: i18next.t('error.password_mismatch'),
              },
            ],
          },
        },
      }
    }

    let userStore = store.user
    return userStore.register(values)
  }

  const onSuccess = () => {
    setSuccess(true)
  }

  if (loading)
    return (
      <span>
        <Trans>loading</Trans>
      </span>
    )

  const registerTerms = regInfo.registerTerms
  const integrityTerms = regInfo.integrityTerms
  const useEmailAuth = store.auth.useEmailAuth()

  let content

  if (success)
    content = (
      <div className="info-box success-box">
        <Trans>info.registration_succeeded</Trans>
      </div>
    )
  else {
    content = (
      <div>
        <h1>
          <Trans>register.header</Trans>
        </h1>
        {useEmailAuth && <RegisterForm onSubmit={onSubmit} registerTerms={registerTerms} regInfo={regInfo} onSuccess={onSuccess} />}
        {!useEmailAuth && (
          <RegisterBankIdForm onSubmit={onSubmit} integrityTerms={integrityTerms} registerTerms={registerTerms} onSuccess={onSuccess} />
        )}
      </div>
    )
  }

  return <div>{content}</div>
}

export default observer(Page)
