import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { toJS } from 'mobx'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import Certificates from 'Components/Certificates'
import ModalWindow from 'Components/ModalWindow'
import ObjectInfo from 'Components/ObjectInfo'
import Utils from 'lib/utils'
import { Root as rootStore } from 'Stores'
import { ErrorHandlingHelper } from 'Components/ErrorHandling'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

import agent from 'Stores/Agent'

const datefmt = Utils.datefmt
const queue2name = Utils.queue2name
let GetIcon = Utils.GetIcon

// row of a property
const ApartmentRow = (row) => {
  const interested = row.interested
  let interestLink

  if (interested) {
    interestLink = (
      <a onClick={() => row.onInterest(false)}>
        {' '}
        <Trans>label.undo_interest</Trans>
      </a>
    )
  } else {
    interestLink = (
      <a onClick={() => row.onInterest(true)}>
        {' '}
        <Trans>label.add_interest</Trans>
      </a>
    )
  }

  const addressLink = (
    <a onClick={row.onClick} style={{ fontWeight: 'bold' }}>
      {row.address && row.address.address1} {row.address && row.address.city}
    </a>
  )

  return (
    <tr className="">
      <th scope="col" data-title={i18next.t('label.address')}>
        {addressLink}
      </th>
      <th scope="col" data-title={i18next.t('label.rent')}>
        {Math.round(row.rent)} <Trans>label.sek_per_month</Trans>
      </th>
      <th scope="col" data-title={i18next.t('label.area_and_type')}>
        {row.area} <Trans>label.kvm</Trans>, {row.size}
      </th>
      <th scope="col" data-title={i18next.t('label.apply_before')}>
        {datefmt(row.endDate, 'yyyy-MM-dd')}
      </th>
      <th scope="col" data-title={i18next.t('label.admission_date')}>
        {datefmt(row.admissionDate, 'yyyy-MM-dd')}
      </th>
      {row.status == 'cannot_apply_blocked' && (
        <th scope="col" data-title={i18next.t('label.blocked')}>
          <Trans>object.cannot_apply_blocked</Trans>
        </th>
      )}
      {row.status == 'application' && (
        <th scope="col" data-title={i18next.t('label.add_interest')}>
          {interested && i18next.t('label.you_are_interested')}
          {interestLink}
        </th>
      )}
      {(row.status == 'pending' || row.status == 'offered_pending') && (
        <th scope="col" data-title={i18next.t('label.add_interest')}>
          <Trans>label.awaiting_confirmation</Trans>
        </th>
      )}
    </tr>
  )
}

const ApartmentList = observer(
  class ApartmentList extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        currentObject: null,
      }
    }

    onShowInterest = (object, answer) => {
      this.props.store.queue
        .showInterest(object, answer)
        .then(() => {
          object.interested = !object.interested

          if (this.state.currentObject != null)
            this.setState({
              currentObject: object,
            })
        })
        .catch((err) => {
          alert(ErrorHandlingHelper.error(err).message)
        })
    }

    onCloseModal = () => {
      this.setState({
        currentObject: null,
      })
    }

    onClickRow = (row) => {
      this.setState({
        currentObject: row,
      })
    }

    render() {
      const objects = this.props.objects

      const rows = objects.map((obj, i) => (
        <ApartmentRow key={i} {...obj} onClick={() => this.onClickRow(obj)} onInterest={(answer) => this.onShowInterest(obj, answer)} />
      ))

      return (
        <React.Fragment>
          <table className="pp-data-table">
            <thead>
              <tr>
                <th scope="col">
                  <Trans>label.address</Trans>
                </th>
                <th scope="col">
                  <Trans>label.rent</Trans>
                </th>
                <th scope="col">
                  <Trans>label.area_and_type</Trans>
                </th>
                <th scope="col">
                  <Trans>label.apply_before</Trans>
                </th>
                <th scope="col">
                  <Trans>label.admission_date</Trans>
                </th>
                <th scope="col">
                  <Trans>label.add_interest</Trans>
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>

          <ModalWindow
            isOpen={this.state.currentObject != null}
            onClose={this.onCloseModal}
            title={this.state.currentObject && this.state.currentObject.address.address1}
          >
            {this.state.currentObject && <ObjectInfo object={this.state.currentObject} showInterest={this.onShowInterest} />}
          </ModalWindow>
        </React.Fragment>
      )
    }
  }
)

const FilterAndList = (props) => {
  const { objects, filter, onClick, title } = props

  return (
    <div>
      {objects && objects.length > 0 && (
        <div>
          <ApartmentList store={props.store} objects={objects} onClick={onClick} />
        </div>
      )}
      {(!objects || objects.length == 0) && (
        <div>
          <Trans>objects.empty</Trans>
        </div>
      )}
    </div>
  )
}

// Page showing the properties
const ObjectList = inject('store')(
  observer(
    class Page2 extends React.Component {
      render() {
        const { store, objects } = this.props

        const filter = {
          areas: [],
        }

        const text = store.propertiesText || ''
        const regionMap = {}
        objects.forEach((o) => {
          if (o.region) regionMap[o.region] = o.region
        })

        filter.areas = Object.keys(regionMap)

        const onClick = (apartment) => {
          this.props.history.push('/ledigt/' + apartment.id)
        }

        return (
          <div>
            <Switch>
              <Route exact path="/ledigt">
                <FilterAndList store={store} title={text} objects={objects} filter={filter} onClick={onClick} />
              </Route>
              <Route exact path="/ledigt/:id"></Route>
            </Switch>
          </div>
        )
      }
    }
  )
)

let ObjectListCount = (props) => {
  const { objects } = props
  const count = objects.length
  if (count != 0) {
    return <span className="bubble">{count}</span>
  } else {
    return false
  }
}

const ObjectsPage = () => {
  const [objects, setObjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [queues, setQueues] = useState([])
  const [tabIndex, setTabIndex] = useState(0)

  const loadObjects = async () => {
    let objects = await agent.Queue.getObjects()
    setObjects(objects)
  }

  useEffect(() => {
    async function fetchData() {
      let q = await rootStore.queue.getQueues()
      setQueues(toJS(q))

      await loadObjects()
      setLoading(false)
    }

    fetchData()
  }, [])

  if (loading)
    return (
      <React.Fragment>
        <Trans>loading</Trans>
      </React.Fragment>
    )

  let { available, certificateInfo, pageInfo, uploadCertificates } = objects
  const showCertificates = uploadCertificates.interest
  const tabs = []
  const panels = []

  for (const [k, queue] of Object.entries(queues)) {
    var filtered = available.filter((o) => o.type && o.type == queue.queueType)
    var title = queue2name(k)

    tabs.push(
      <Tab key={k}>
        <GetIcon id={k} />
        {title} <ObjectListCount key={k} objects={filtered} />
      </Tab>
    )
    panels.push(
      <TabPanel key={k}>
        {showCertificates && <Certificates certificateInfo={certificateInfo} />}
        <ObjectList objects={filtered} />
      </TabPanel>
    )
  }

  return (
    <React.Fragment>
      <h1>
        <Trans>objects.header</Trans>
      </h1>
      {pageInfo && <div dangerouslySetInnerHTML={{ __html: pageInfo }}></div>}
      <Tabs selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}>
        <TabList>{tabs}</TabList>
        {panels}
      </Tabs>
    </React.Fragment>
  )
}

export default inject('store')(observer(ObjectsPage))
