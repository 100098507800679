import React from 'react'
import TreeSelect from 'react-dropdown-tree-select'

class TreeSelectWidget extends React.Component {
  constructor(props) {
    super()

    this.state = {data: props.schema.items.enum}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const data = nextProps.schema.items.enum

    if (data !== this.state.data) {
      this.setState({data: data})
    }
  }

  onChange = (currentNode, selectedObjects) => {
    var object = {}

    selectedObjects.forEach(o => {
      if(!object[o.type])
        object[o.type] = []

      object[o.type].push(o.value)
    })

    this.props.onChange(JSON.stringify(object))
  }

  render() {
    const data = this.state.data

    return (
      <div className="tree-select">
        <TreeSelect data={data} onChange={this.onChange} />
      </div>
    )
  }
}

export default TreeSelectWidget