import React, { Component, useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'
import MessageBox from 'Components/MessageBox'
import Utils from 'lib/utils'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import Agent from 'Stores/Agent'

const { datefmt, outputDocument } = Utils

const QueuePlacesAgency = ({ subscriptions }) => {
  const rows = []

  // Needs enpoint for this
  const queues = {
    housing: '#bostadsko',
    plot: '#tomthusko',
  }

  for (const [queue, page] of Object.entries(queues)) {
    const name = Utils.queue2name(queue)
    const joinedQueue = subscriptions[queue] && subscriptions[queue].points !== null
    const linkContent = joinedQueue ? subscriptions[queue].points + ' poäng' : i18next.t('queue.join')
    const link = (
      <a href={page} style={{ textDecorationLine: 'underline' }}>
        {linkContent}
      </a>
    )

    rows.push(
      <li key={queue}>
        <strong>{name}</strong>
        <span style={{ float: 'right' }}>{link}</span>
      </li>
    )
  }

  return (
    <React.Fragment>
      <div className="panel">
        <div className="panel-header">{i18next.t('queue.header')}</div>
        <div className="panel-body">
          <ul className="simple-list mb-small">{rows}</ul>
        </div>
      </div>
    </React.Fragment>
  )
}

const QueuePlaces = ({ subscriptions }) => {
  const handleQueuesPage = '#/profil'
  const panelHeader = i18next.t('queues.header')
  const items = []

  let numSubscriptions = 0

  for (const [k, subscription] of Object.entries(subscriptions)) {
    const name = Utils.queue2name(k)
    if (subscription.applicationDate) {
      numSubscriptions++

      items.push(
        <li key={k}>
          <strong>{name}</strong>
          <span style={{ float: 'right' }}>{datefmt(subscription.applicationDate, 'yyyy-MM-dd')}</span>
        </li>
      )
    }
  }

  return (
    <React.Fragment>
      {numSubscriptions == 0 && (
        <a href={handleQueuesPage} className="main-btn main-btn-block">
          <Trans>queue.join</Trans>
        </a>
      )}
      {numSubscriptions > 0 && (
        <div className="panel">
          <div className="panel-header">{panelHeader}</div>
          <div className="panel-body">
            <ul className="simple-list mb-small">{items}</ul>
          </div>
          <a href={handleQueuesPage} className="main-btn main-btn-block">
            <Trans>queue.position</Trans>
          </a>
        </div>
      )}
    </React.Fragment>
  )
}

const MyQueuePlaces = ({ isAgency }) => {
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)

  const loadSubscriptions = () => {
    Agent.Queue.getSubscriptions().then((response) => {
      if (response) setSubscriptions(response.subscriptions)

      setLoading(false)
    })
  }

  useEffect(loadSubscriptions, [])

  if (loading) return <div>Laddar...</div>

  return (
    <React.Fragment>
      {<div className="pp-flex-grid__4">
        {isAgency && <QueuePlacesAgency subscriptions={subscriptions} />}
        {!isAgency && <QueuePlaces subscriptions={subscriptions} />}
      </div>}

    </React.Fragment>
  )
}

const Content = ({ isAgency, useQueue }) => {
  const [pageData, setPageData] = useState({ welcomeText: null, hideQueuePosition: false })

  const loadData = () => {
    Agent.Portal.loggedIn().then((response) => {
      setPageData(response)
    })
  }

  useEffect(loadData, [])

  let header, footer
  let content = pageData.welcomeText
  let hideQueuePosition = pageData.hideQueuePosition

  if (content) {
    var regexp = /\[header\](.*)\[\/header\]/
    var headerMatch = content.match(regexp)

    if (headerMatch && headerMatch[1]) {
      header = headerMatch[1]
      content = content.replace(regexp, '').replace(/<p><\/p>/, '')
    }

    regexp = /\[footer\](.*)\[\/footer\]/
    var footerMatch = content.match(regexp)

    if (footerMatch && footerMatch[1]) {
      footer = footerMatch[1]
      content = content.replace(regexp, '').replace(/<p><\/p>/, '')
    }
  }

  return <React.Fragment>
    {header && <div className="pp-responsive-image-container mb-large" dangerouslySetInnerHTML={{ __html: header }} />}
    <div className="pp-flex-grid">
      <div className="pp-flex-grid__8">{outputDocument(content)}</div>
      {useQueue && !hideQueuePosition && <MyQueuePlaces isAgency={isAgency} />}
    </div>
    {footer && <div className="pp-responsive-image-container mt-large" dangerouslySetInnerHTML={{ __html: footer }} />}
  </React.Fragment >
}

const Page = ({ store }) => {
  const [noticeText, setNoticeText] = useState(null)

  useEffect(() => {
    const loadStores = async () => {
      await Promise.all([
        store.queue.loadSubscriptions(),
        store.queue.loadQueues()
      ])

      const isAgency = await store.portal.isAgency()

      if (isAgency) {
        const currentUser = await store.auth.getCurrentUser()
        if (currentUser && !currentUser.email) setNoticeText(i18next.t('info.need_to_verify_email', { queue: 'en kö' }))
      }
    }

    loadStores()
  }, [store])

  return (
    <div>
      {noticeText && <MessageBox>{noticeText}</MessageBox>}
      <h1>
        <Trans>logged_in.header</Trans>
      </h1>

      <Content isAgency={store.portal.isAgency()} />
    </div>
  )
}

export default inject('store')(observer(Page))

