import React, { useState } from 'react'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import ModalWindow from 'Components/ModalWindow'

import agent from 'Stores/Agent'
import i18next from "i18next";
import { Trans } from 'react-i18next'

const VerifyEmail = (props) => {
  const [done, setDone] = useState()

  const schema = {
    fields: [{ name: 'new_email', title: i18next.t('label.new_email'), type: 'email' }],
  }

  const onSubmit = (form) => {
    const values = form.formData

    return agent.User.changeEmail(values.new_email).then(() => {
      setDone(true)
    })
  }

  return (
    <React.Fragment>
      {!done && <Form schema={schema} onSubmit={onSubmit} onError={props.onError} submit={i18next.t('label.send')} rowLayout={true} />}
      {done && <MessageBox html={i18next.t('info.verification_email_sent')} />}
    </React.Fragment>
  )
}

const ChangeEmail = ({ email }) => {
  const [isOpen, setIsOpen] = useState()

  const onOpen = (e) => {
    e.preventDefault()

    setIsOpen(true)
  }
  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      {email && <span>{email}&nbsp;</span>}
      {!email && <p><Trans>info.verification_email_sent</Trans></p>}
      <button className="main-btn" onClick={onOpen}>
        {!email && i18next.t('label.send_new_verification_link')}
        {email && i18next.t('label.change')}
      </button>
      <ModalWindow isOpen={isOpen} onClose={onClose} title={i18next.t('label.verify_email')} maxWidth={500}>
        <VerifyEmail />
      </ModalWindow>
    </React.Fragment>
  )
}

export default ChangeEmail
