import React from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import sv from 'date-fns/locale/sv'

import 'react-datepicker/dist/react-datepicker.css'

registerLocale('sv', sv)

class DateWidget extends React.Component {
  constructor(props) {
    super(props)

    setDefaultLocale(sv)

    var date = props.value
    if(typeof date == 'string') {
      date = parseISO(date)
      if(date == 'Invalid Date') date = null
    }

    this.state = {
      hasDateSupport: false,
      stamp: date
    }
  }

  componentDidMount() {
    this.checkDateSupport()
  }

  checkDateSupport = () => {
    const element = document.createElement('input')
    try {
      element.type = 'date'
    } catch(e) {
      // ignore
    }
    
    this.setState({
      hasDateSupport: element.type === 'date'
    })
  }

  handleChange = (stamp) => {
    if(stamp == this.state.stamp) return
    
    this.setState({
      stamp: stamp
    })

    this.props.onChange(format(stamp, 'yyyy-MM-dd'))
  }

  render() {

    return (
      <div className="input-date-container">
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={this.state.stamp}
          onChange={this.handleChange}
          locale="sv"
        />
      </div>)
  }
}

export default DateWidget