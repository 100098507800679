import React, { useEffect, useState } from 'react';
import i18next from "i18next";

const type2name = (type) => {
  const nameMapping = {
    Contract: i18next.t('type.contract'),
    Spatisystem: i18next.t('type.spatisystem'),
    Space: i18next.t('type.space'),
    SpacePart: i18next.t('type.space_part'),
    ErrorCategory: i18next.t('type.error_category'),
    ErrorDetail: i18next.t('type.error_detail')
  }

  return (type && nameMapping[type]) || type;
}

const CascadeSelectField = ({schema, formData, onChange}) => {
  const [selected, setSelected] = useState({});
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const selected = traverseOptions(schema.options, {});
    setSelected(selected);
    setTypes(Object.keys(selected));
  }, [schema.options]);

  useEffect(() => {
    // if all object keys are null, return
    if (Object.keys(selected).every((key) => !selected[key])) return;
    
    onChange(selected);
  }, [selected])

  const traverseOptions = (options, selected) => {
    options && options.forEach((option) => {
      if (!selected[option.type]) {
        selected[option.type] = options.length == 1 
          ? option.value
          : formData && formData[option.type];
      }
      traverseOptions(option.children, selected);
    });
    return selected;
  }

  const onChangeType = (type) => (event) => {
    let value = event.target.value;
    if (value.length === 0) value = null;

    setSelected(prevSelected => {
      const newState = {...prevSelected};
      const index = types.indexOf(type);

      for (let i = index; i < types.length; i++)
        if (newState[types[i]]) newState[types[i]] = null;

      newState[type] = value;
      return newState;
    });
  }

  let optionList = schema.options;
  const selectTags = types.map((typeKey, i) => {
    let selOption, optionTags;
    if (optionList && optionList.length > 0) {
      optionTags = optionList.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ));
      selOption = optionList.find((option) => option.value == selected[typeKey]);
    }

    if (optionTags) {
      const typeStr = type2name(typeKey).toLowerCase();
      optionTags.unshift(
        <option key={-1} value={''}>
          {schema.options.placeholder || `- ${i18next.t('label.choose')} ${typeStr} -`}
        </option>
      );

      optionList = selOption && selOption.children;

      return (
        <select
          key={i}
          onChange={onChangeType(typeKey)}
          value={(selOption && selOption.value) || ''}
        >
          {optionTags}
        </select>
      );
    }
    return null;
  });

  return <div className="cascade-select">{selectTags}</div>;
}

export default CascadeSelectField;
