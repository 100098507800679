import React from 'react'
import { toJS } from 'mobx'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import {Trans} from "react-i18next";
import i18next from "i18next";

const getFormSchema = (registerTerms) => {
  return {
    type: 'object',
    description: null,
    fields: [
      { name: 'ssn', title: i18next.t('label.ssn'), required: true },
      { name: 'contract', title: i18next.t('label.contract_number'), required: true },
      { name: 'email', title: i18next.t('label.new_email'), required: true },
      /*
      {name: 'password', title: i18next.t('label.password'), required: true},
      {name: 'password2', title: i18next.t('label.password_repeat'), required: true},
      */
      { name: 'agree', title: i18next.t('label.agree'), options: { text: registerTerms }, type: 'boolean', required: true, widget: 'agree' },
    ],
  }
}

const TenantRegisterForm = (props) => (
  <Form
    hideOnSuccess={true}
    schema={getFormSchema(props.registerTerms)}
    onChange={props.onChange}
    onSubmit={props.onSubmit}
    onError={props.onError}
    successBox={<MessageBox type="success"><Trans>info.account_activation_email_sent</Trans></MessageBox>}
    submit={i18next.t('label.register_button')}
  ></Form>
)

class Page extends React.Component {
  onSubmit = (form) => {
    const { store } = this.props
    const values = form.formData

    if (values.password !== values.password2) {
      throw {
        status: 400,
        response: { body: { errors: [{ property: undefined, message: i18next.t('error.password_mismatch') }] } },
      }
    }

    return store.user.registerTenant(values)
  }

  render() {
    const { store } = this.props
    const info = toJS(store.portal.info)
    const registerTerms = info && info.documents && info.documents.registerTerms
    const link = "<a href='#/losenord'>" + i18next.t('tenant_register.link') + "</a>"

    return (
      <div>
        <h1><Trans>tenant_register.header</Trans></h1>
        <p>
          <Trans tOptions={{link:link }} components={{ a: <a /> }}>tenant_register.text</Trans>
        </p>

        <TenantRegisterForm onSubmit={this.onSubmit} registerTerms={registerTerms} />
      </div>
    )
  }
}

export default inject('store')(observer(Page))
