import React, { useEffect, useRef, useState } from 'react'
import { HashRouter as Router, Link, Route, Switch, useHistory, useParams } from 'react-router-dom'
import Agent from 'Stores/Agent'
import Utils from 'lib/utils'
import Slider from 'react-slick'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import BingMap from 'Components/BingMap'
import Button from 'Components/Button'
import SegmentedControl from 'Components/SegmentedControl'
import { ErrorHandling, ErrorHandlingHelper } from 'Components/ErrorHandling'

const apiUrl = Utils.apiUrl
const area = (i) => (i > 0 ? `${i} kvm` : null)
const amount = (i) => (i > 0 ? `${i} kr` : null)
const objectData = (o, key) => o.objectData.reduce((acc, curr) => (curr.name == key ? curr.value : acc), null)
const dte = (d) => (d ? d.substring(0, 10) : null)
const tme = (d) => (d ? d.substring(11, 16) : null)

const imgUrl = (url) => (url.indexOf('localhost') != -1 ? url : 'https://aepfnqkden.cloudimg.io/width/512/x/' + url)

const Dataitem = ({ label, value }) => (
  <div className="dataItem">
    <div className="dataItem label">
      <h3>{label}</h3>
    </div>
    <div className="dataItem data">{value}</div>
  </div>
)

const ReactSlick = ({ images }) => {
  return (
    <Slider
      dots
      className="slider"
      slidesToShow={1}
      nextArrow={
        <button type="button" data-role="none" class="slick-arrow slick-next" style={{ display: 'block' }}>
          Nästa bild
        </button>
      }
      prevArrow={
        <button type="button" data-role="none" class="slick-arrow slick-prev" style={{ display: 'block' }}>
          Föregående bild
        </button>
      }
      appendDots={(dots) => (
        <ul style={{ display: 'block' }} aria-hidden="true">
          {dots}
        </ul>
      )}
    >
      {images.map((img, ix) => (
        <img key={img.id} alt={'Bildspel, bild ' + (ix + 1)} className="sliderImage" src={apiUrl('/files/' + img.id)} />
      ))}
    </Slider>
  )
}


const MapComponent = ({ address }) => {
  const [showMap, setShowMap] = useState(false)
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

    const toggleMap = () => {
        if (!hasRenderedOnce) {
            setHasRenderedOnce(true);
        }
        setShowMap(mapShown => !mapShown);
    };


  return (
    <div>
      <button onClick={toggleMap}>
        {!showMap ? 'Visa på karta' : 'Dölj karta'}
      </button>
      {hasRenderedOnce && <div className="data" style={{ display: showMap ? 'block' : 'none' }} aria-hidden={true}>
        <BingMap
          address1={address.address1 || ''}
          address2={address.address2 || ''}
          zip={address.zip || ''}
          city={address.city || ''}
        />
      </div>}
    </div>
  )
}


const ObjectInfo = ({ object, handleSetInterest, isApartment, isPlot, isDetachedHouse }) => {
  const hasMap = object.address && object.address.address1 && object.address.city

  return (
    <div className="singleObject">
      {object.images && object.images.length > 0 && (
        <React.Fragment>
          <h1 className="sr-only">Bilder på bostaden</h1>
          <div style={{ display: 'block' }}>
            <ReactSlick images={object.images} />
          </div>
        </React.Fragment>
      )}

      <div className="singleObjectContent">
        <div className="topObjectInfo">
          <div className="objectInfo">
            {isApartment && <h1 className="oi sizeDescription">{object.sizeDescription}</h1>}
            {isPlot && <h1 className="oi sizeDescription">Tomt {area(object.area)}</h1>}
            {isDetachedHouse && <h1 className="oi sizeDescription">Villa {area(object.area)}</h1>}
            <div className="oi address">{object.address.address1}</div>
            {object.objectType && (
              <div className="oi objectType">
                Observera att objektet har avsedd boendetyp <strong>{object.objectType.name}</strong> och att sökanden måste uppfylla
                motsvarande krav för att kunna anmäla sitt intresse för objektet.
              </div>
            )}
            {object.shortTimeLease && <div className="oi objectType">Detta objekt hyrs ut med korttidskontrakt</div>}
            {object.objectData && object.objectData.length > 0 && (
              <React.Fragment>
                {object.objectData.map((od) => {
                  if (od.datatype != 1 || od.value == null) return null

                  return (
                    <div
                      key={od.key}
                      className="oi markup"
                      dangerouslySetInnerHTML={{
                        __html: od.value
                          .replace(/<script/gi, '&lt;script')
                          .replace(/<strong>/gi, '<h2>')
                          .replace(/<\/strong>/gi, '</h2>'),
                      }}
                    ></div>
                  )
                })}
              </React.Fragment>
            )}
          </div>
          <div className="push">
            <div className="content">
              <div style={{ marginBottom: '5px' }}>
                Detta objekt har {object.numApplicants > 0 ? (object.numApplicants + ' intresseanmälningar.') : 'ännu inga'}
              </div>
              {object.numApplicants > 0 && (
                <div style={{ marginBottom: '5px' }}>
                  {object.maxPoints} poäng har den som just nu ligger etta
                  {object.status !== 'not_logged_in' && (
                    <React.Fragment> och din poäng räcker till plats {object.queueOrder}</React.Fragment>
                  )}
                  .
                </div>
              )}
              <br />
              <br />
              {object.status == 'not_logged_in' && (
                <React.Fragment>
                  <a href="/mina-sidor">Logga in</a> för att anmäla intresse
                  <br />
                  <br />
                  Intresseanmälan kan göras till och med {dte(object.applicationEndDate)} klockan {tme(object.applicationEndDate)}.
                </React.Fragment>
              )}
              {object.status == 'applied' && (
                <React.Fragment>
                  <Button onClick={(e) => handleSetInterest(object.queueSessionId, object.id, false)}>Avbryt intresseanmälan</Button>
                  <br />
                  <br />
                </React.Fragment>
              )}
              {object.status == 'can_apply' && (
                <React.Fragment>
                  <Button onClick={(e) => handleSetInterest(object.queueSessionId, object.id, true)}>Anmäl intresse!</Button>
                  <br />
                  <br />
                  Intresseanmälan kan göras till och med {dte(object.applicationEndDate)} klockan {tme(object.applicationEndDate)}.
                </React.Fragment>
              )}
              {object.status == 'cannot_apply_age' && (
                <React.Fragment>
                  Objektet är endast tillgängligt för en specifik åldersgrupp och du kan därför inte anmäla intresse på det.
                </React.Fragment>
              )}
              {object.status == 'cannot_apply_blocked' && <React.Fragment>Du är spärrad från att anmäla intresse</React.Fragment>}
              {object.status == 'cannot_apply_maxlimit' && <React.Fragment>Du kan max vara anmäld på 5 aktiva objekt</React.Fragment>}
              {object.status == 'cannot_apply_payment' && (
                <React.Fragment>Din möjlighet att anmäla intresse är pausad till dess utestående betalningar har inkommit.</React.Fragment>
              )}
              <br />
              {isApartment && object.administrativeCompany && (
                <React.Fragment>
                  <br />
                  Bostaden hyrs ut av <strong>{object.administrativeCompany}</strong>
                </React.Fragment>
              )}
              {isPlot && object.administrativeCompany && (
                <React.Fragment>
                  <br />
                  Tomten säljs av <strong>{object.administrativeCompany}</strong>
                </React.Fragment>
              )}
              {isDetachedHouse && object.administrativeCompany && (
                <React.Fragment>
                  <br />
                  Bostaden säljs av <strong>{object.administrativeCompany}</strong>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {/* Fasta poster på boendet */}
        <div className="data">
          <div className="label">
            <h2>Boendet</h2>
          </div>
          <div className="dataItems">
            <Dataitem label="Ort" value={object.address.city} />
            <Dataitem label="Adress" value={object.address.address1} />
            {!isPlot && <Dataitem label="Byggår" value={objectData(object, 'Byggnadsår')} />}
            <Dataitem label="Typ" value={object.sizeDescription} />
            {isApartment && <Dataitem label="Våning" value={objectData(object, 'Våningsplan')} />}
            <Dataitem label="Yta" value={area(object.area)} />
            <Dataitem label="Tillträde" value={dte(object.admissionDate)} />
            {isApartment && <Dataitem label="Hyra" value={amount(object.rent)} />}
            {(isPlot || isDetachedHouse) && <Dataitem label="Pris" value={amount(object.rent)} />}
          </div>
        </div>

        {/* Egenskaper */}
        {object.objectData && object.objectData.length > 0 && (
          <div className="data">
            <div className="label">
              <h2>Egenskaper</h2>
            </div>
            <div className="dataItems">
              {object.objectData.map((od) => {
                if (od.datatype == 1) return null

                return <Dataitem key={od.name} label={od.name} value={od.value} />
              })}
            </div>
          </div>
        )}

        {/* Dokument */}
        {object.documents && object.documents.length > 0 && (
          <div className="data">
            <div className="label">
              <h2>Planlösning</h2>
            </div>
            <div className="dataItems">
              {object.documents.map((d) => {
                return (
                  <div className="document">
                    <a href={apiUrl('/files/' + d.id)} target="_blank" rel="noreferrer">
                      {d.name} (öppnas i nytt fönster)
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {hasMap && <MapComponent address={object.address} />}
      </div>
    </div>
  )
}

const empty = (val) => val == '' || val == '*' || val == undefined || val == null
const between = (val, min, max) => (empty(min) || val >= min) && (empty(max) || val <= max)
const isMatchingObjectType = (obj, filter) => {
  if (!filter.objectType || filter.objectType == '*') return true;
  if (filter.objectType == 'normal' && obj.objectType == null) return true;

  return obj.objectType && filter.objectType == obj.objectType.id;
};

const isMatchingHluObjectType = (obj, filter) => {
  return (
    filter.hluObjectType == null ||
    filter.hluObjectType == '*' ||
    (filter.hluObjectType != '' && filter.hluObjectType == obj.objectTypeId)
  );
};

const isMatchingRegion = (obj, filter) => empty(filter.region) || filter.region == obj.regionId;
const isMatchingNeighbourhood = (obj, filter) => empty(filter.neighbourhood) || filter.neighbourhood == obj.neighbourhoodId;
const includesShortTimeLease = (obj, filter) => filter.includeShortTimeLease || !obj.shortTimeLease;

const filterFunc = (filter) => {
  return (obj) => {
    return (
      between(obj.rooms, filter.min_rooms, filter.max_rooms) &&
      between(obj.rent, filter.min_rent, filter.max_rent) &&
      between(obj.area, filter.min_area, filter.max_area) &&
      between(obj.size, filter.min_size, filter.max_size) &&
      isMatchingObjectType(obj, filter) &&
      isMatchingRegion(obj, filter) &&
      isMatchingNeighbourhood(obj, filter) &&
      includesShortTimeLease(obj, filter) &&
      isMatchingHluObjectType(obj, filter)
    );
  };
};

const sortCmpFunc = (orderBy) => (a, b) => {
  const field = orderBy.field
  let retval = 0
  if (a[field] < b[field]) retval = -1
  if (a[field] > b[field]) retval = 1

  if (orderBy.desc) retval *= -1

  return retval
}

const ObjectInfoPage = ({ objects, handleSetInterest }) => {
  const params = useParams()
  const history = useHistory()
  let object = objects.find((o) => o.id == params.id)

  return (
    <React.Fragment>
      {!object && <div>Objektet hittades inte</div>}
      {object && (
        <ObjectInfo
          object={object}
          isApartment={object.objectTypeId != cObjectTypePlotId && object.objectTypeId != cObjectTypeDetachedHouseId}
          isPlot={object.objectTypeId == cObjectTypePlotId}
          isDetachedHouse={object.objectTypeId == cObjectTypeDetachedHouseId}
          handleSetInterest={handleSetInterest}
        />
      )}
      <br />
      <button type="button" onClick={() => history.push('/')}>
        Tillbaka
      </button>
    </React.Fragment>
  )
}

const ObjectTypeSegmentedControl = ({ options, onChange }) => {
  let data  = options.map((o) => {
    return {
      label: o.name,
      value: o.id,
    }
  })

  data = [
    { label: 'Alla', value: '*' },
    { label: 'Vanliga', value: 'normal' },
    ...data,
  ]

  return (
    <>
      <SegmentedControl
        name="objectType"
        onChange={onChange}
        segments={data}
      />
    </>
  );
};

const ObjectsFilter = ({ onChange, orderBy, onOrderBy, filterData }) => {
  const [filter, setFilter] = React.useState({
    objectType: '*',
    min_rooms: '*',
    max_rooms: '*',
    min_rent: '*',
    max_rent: '*',
    min_area: '*',
    max_area: '*',
    region: '*',
    neighbourhood: '*',
    includeShortTimeLease: false,
  })

  function handleChange(evt) {
    const value = evt.target.type == 'checkbox' ? evt.target.checked : evt.target.value

    var f = {
      ...filter,
      neighbourhood: '*',
    }

    setFilter({
      ...f,
      [evt.target.name]: value,
    })

    onChange({
      ...f,
      [evt.target.name]: value,
    })
  }

  const handleSort = (e) => {
    onOrderBy(e.target.value)
  }

  const getAllNeighbourhoods = (regions) => {
    var arrNeighbourhoods = []

    for (var i = 0; i < regions.length; i++) Array.prototype.push.apply(arrNeighbourhoods, regions[i].neighbourhoods)

    return arrNeighbourhoods.sort(function (a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }

  const livingAreaOptions = [20, 40, 60, 80, 100, 120, 140, 160, 180];
  const rentOptions = [5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000];
  const roomsOptions = [1, 2, 3, 4, 5, 6];

  return (
    <React.Fragment>
      <form>
        <div className="objectListFilter">
          <div className="sortContainer" style={{ display: 'none' }}>
            <div className="sortItem form-group">
              <fieldset className="slim">
                <legend>Sortering</legend>
                <div className="sort-input-container">
                  <select aria-label="Sortering" className="form-control" id="sort" size="1" value={orderBy.field} onChange={handleSort}>
                    <option value="address">Adress</option>
                    <option value="size">Rum</option>
                    <option value="area">Storlek</option>
                    <option value="rent">Hyra</option>
                    <option value="num_applicants">Sökande</option>
                  </select>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="filterContainer">
            {filterData.isApartment && (
              <div className="filterItem form-group">
                <fieldset className="slim">
                  <legend>Objekttyp</legend>
                  <div className="filter-input-container">
                    <ObjectTypeSegmentedControl options={filterData.objectTypes} onChange={handleChange} />
                    <div style={{ paddingTop: '10px' }}>
                      <input
                        aria-label="Inkludera korttidskontrakt"
                        type="checkbox"
                        id="filter_includeShortTimeLease"
                        checked={filter.includeShortTimeLease}
                        name="includeShortTimeLease"
                        onChange={handleChange}
                      />
                      <label htmlFor="filter_includeShortTimeLease">Visa även korttidskontrakt</label>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {!filterData.isApartment && (
              <div className="filterItem form-group">
                <fieldset className="slim">
                  <legend>Objekttyp</legend>
                  <div className="filter-input-container">
                    <select
                      className="form-control"
                      id="filter_type"
                      size="1"
                      value={filter.hluObjectType}
                      name="hluObjectType"
                      onChange={handleChange}
                      aria-label="Objekttyp"
                    >
                      <option value="*">Alla objekttyper</option>
                      {filterData.hluObjectTypes.map((ot, ix) => (
                        <option key={ot.id} value={ot.id}>
                          {ot.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </fieldset>
              </div>
            )}
            <div className="filterItem form-group" style={{ display: 'none' }}>
              <fieldset className="slim">
                <legend>Region</legend>
                <div className="filter-input-container">
                  <select
                    aria-label="Region"
                    className="form-control"
                    id="filter_region"
                    size="1"
                    value={filter.region}
                    name="region"
                    onChange={handleChange}
                  >
                    <option value="*">Alla</option>
                    {filterData.regions.map((r, ix) => (
                      <option key={ix} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                </div>
              </fieldset>
            </div>
            <div className="filterItem form-group">
              <fieldset className="slim">
                <legend>Område</legend>
                <div className="filter-input-container">
                  <select
                    className="form-control"
                    id="filter_neighbourhood"
                    size="1"
                    value={filter.neighbourhood}
                    name="neighbourhood"
                    onChange={handleChange}
                    aria-label="Område"
                  >
                    <option value="*">Alla områden</option>
                    {getAllNeighbourhoods(filterData.regions).map((n, ix) => (
                      <option key={ix} value={n.id}>
                        {n.name}
                      </option>
                    ))}
                  </select>
                </div>
              </fieldset>
            </div>
            {filterData.isApartment && (
              <div className="filterItem form-group">
                <fieldset className="slim">
                  <legend>Rum</legend>
                  <div className="filter-input-container" id="filter_rooms">
                    <select
                      className="form-control"
                      aria-label="Min antal rum"
                      size="1"
                      value={filter.min_rooms}
                      name="min_rooms"
                      onChange={handleChange}
                    >
                      <option value="*">Min</option>
                      {roomsOptions.map((v, ix) => (
                        <option value={v} key={ix}>
                          {v} rum
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control"
                      aria-label="Max antal rum"
                      size="1"
                      value={filter.max_rooms}
                      name="max_rooms"
                      onChange={handleChange}
                    >
                      <option value="*">Max</option>
                      {roomsOptions.map((v, ix) => (
                        <option value={v} key={ix}>
                          {v} rum
                        </option>
                      ))}
                    </select>
                  </div>
                </fieldset>
              </div>
            )}
            {filterData.isApartment && (
              <div className="filterItem form-group" style={{ display: 'none' }}>
                <fieldset className="slim">
                  <legend>Boendeyta</legend>
                  <div className="filter-input-container" id="filter_area">
                    <select
                      className="form-control"
                      aria-label="Min boendeyta"
                      size="1"
                      value={filter.min_area}
                      name="min_area"
                      onChange={handleChange}
                    >
                      <option value="*">Min</option>
                      {livingAreaOptions.map((v, ix) => (
                        <option key={ix} value={v}>
                          {v} m&sup2;
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control"
                      aria-label="Max boendeyta"
                      size="1"
                      value={filter.max_area}
                      name="max_area"
                      onChange={handleChange}
                    >
                      <option value="*">Max</option>
                      {livingAreaOptions.map((v, ix) => (
                        <option key={ix} value={v}>
                          {v} m&sup2;
                        </option>
                      ))}
                    </select>
                  </div>
                </fieldset>
              </div>
            )}
            {filterData.isApartment && (
              <div className="filterItem form-group" style={{ display: 'none' }}>
                <fieldset className="slim">
                  <legend>Hyra</legend>
                  <div className="filter-input-container" id="filter_rent">
                    <select
                      className="form-control"
                      aria-label="Min hyra"
                      size="1"
                      value={filter.min_rent}
                      name="min_rent"
                      onChange={handleChange}
                    >
                      <option value="*">Min</option>
                      {rentOptions.map((v, ix) => (
                        <option key={ix} value={v}>
                          {v} kr/mån
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control"
                      aria-label="Max hyra"
                      size="1"
                      value={filter.max_rent}
                      name="max_rent"
                      onChange={handleChange}
                    >
                      <option value="*">Max</option>
                      {rentOptions.map((v, ix) => (
                        <option key={ix} value={v}>
                          {v} kr/mån
                        </option>
                      ))}
                    </select>
                  </div>
                </fieldset>
              </div>
            )}
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

const ObjectListItemApartment = ({ o, i }) => {
  return (
    <div key={i} className="objectlist object">
      <div className="img">
        {o.images && o.images.length > 0 && <img alt="Lägenhetsbild" src={imgUrl(apiUrl('/files/' + o.images[0].id))} />}
      </div>
      <div className="content">
        <h2 style={{ color: 'inherit' }}>
          <span className="address">
            {o.address.address1}
            {o.shortTimeLease && <span className="shortTimeLease">Korttidskontrakt</span>}
            {o.objectType && <span className="objecttype">{o.objectType.name}</span>}
          </span>
          <span className="administrativeCompany">{o.administrativeCompany}</span>

          <span className="rooms">{o.size}</span>
        </h2>
        <div className="data">
          {objectData(o, 'Våningsplan') && <div className="data floor">Vån {objectData(o, 'Våningsplan')}</div>}
          {amount(o.rent) && <div className="data rent">Hyra {amount(o.rent)}</div>}
          {area(o.area) && <div className="data area">Yta {area(o.area)}</div>}
          {o.admissionDate && <div className="data admissionDate">Tillträde {dte(o.admissionDate)}</div>}
        </div>
      </div>
    </div>
  )
}
const ObjectListItemPlot = ({ o, i }) => {
  return (
    <div key={i} className="objectlist object">
      <div className="img">
        {o.images && o.images.length > 0 && <img alt="Tomtbild" src={imgUrl(apiUrl('/files/' + o.images[0].id))} />}
      </div>
      <div className="content">
        <h2 style={{ color: 'inherit' }}>
          <span className="address">
            {o.address.address1}
            <span className="objecttype left">Tomt {area(o.area)}</span>
          </span>
        </h2>
        <div className="data">{amount(o.rent) && <div className="data rent left">Pris {amount(o.rent)}</div>}</div>
      </div>
    </div>
  )
}
const ObjectListItemDetachedHouse = ({ o, i }) => {
  return (
    <div key={i} className="objectlist object">
      <div className="img">
        {o.images && o.images.length > 0 && <img alt="Villabild" src={imgUrl(apiUrl('/files/' + o.images[0].id))} />}
      </div>
      <div className="content">
        <h2 style={{ color: 'inherit' }}>
          <span className="address">
            {o.address.address1}
            <span className="objecttype left">Villa {area(o.area)}</span>
          </span>
        </h2>
        <div className="data">{amount(o.rent) && <div className="data rent left">Pris {amount(o.rent)}</div>}</div>
      </div>
    </div>
  )
}

const cObjectTypePlotId = 'a1758320-21e6-4193-9b97-911da59d4517'
const cObjectTypeDetachedHouseId = '3f1b3438-abe7-49ab-9a82-319ef8c6a3c4'

const ObjectList = ({ objects, orderBy }) => {
  return (
    <React.Fragment>
      {objects && objects.length == 0 && <h1>Träffar: Inga bostäder matchar din filtrering</h1>}
      {objects && objects.length == 1 && <h1>Träffar: 1 bostad matchar din filtrering</h1>}
      {objects && objects.length > 1 && <h1>Träffar: {objects.length} bostäder matchar din filtrering</h1>}
      <div className="objectlist">
        {objects.sort(sortCmpFunc(orderBy)).map((o, i) => (
          <Link key={i} to={'/object/' + o.id} className="listItem">
            {o.objectTypeId == cObjectTypePlotId && <ObjectListItemPlot o={o} i={i} />}
            {o.objectTypeId == cObjectTypeDetachedHouseId && <ObjectListItemDetachedHouse o={o} i={i} />}
            {o.objectTypeId != cObjectTypePlotId && o.objectTypeId != cObjectTypeDetachedHouseId && <ObjectListItemApartment o={o} i={i} />}
          </Link>
        ))}
      </div>
    </React.Fragment>
  )
}

const Objects = ({ objects, filterData, objectType }) => {
  const [filter, setFilter] = useState({ objectType: objectType })
  const [orderBy, setOrderBy] = useState({ field: 'address', desc: false })

  const onFilterChanged = (filter) => {
    setFilter({ ...filter })
  }

  const onOrderBy = (field) => {
    setOrderBy({ field: field, desc: false })
  }

  const filtered = objects
    .map((o, ix) => {
      // Bygga upp beräknade properties
      o.rooms = o.size ? parseInt(o.size.replace(/(^\d+)(.+$)/i, '$1'), 10) : 0
      return o
    })
    .filter(filterFunc(filter))

  return (
    <React.Fragment>
      <ObjectsFilter onChange={onFilterChanged} orderBy={orderBy} onOrderBy={onOrderBy} filterData={filterData} />
      <ObjectList objects={filtered} orderBy={orderBy} />
    </React.Fragment>
  )
}

const AvailableObjectsPage = ({ objectType, queue }) => {
  const [loading, setLoading] = useState(true)
  const [objects, setObjects] = useState([])
  const [filterData, setFilterData] = useState({
    regions: [],
    objectTypes: [],
    isApartment: false,
    isPlot: false,
    isDetachedHouse: false,
    hluObjectTypes: [],
  })
  const [errorHandling, setErrorHandling] = useState(ErrorHandlingHelper.getDefaultState())

  const fetchData = async (queue) => {
    try {
      const [availableObjects, filterData] = await Promise.all([
        Agent.Queue.availableObjects(queue),
        Agent.Queue.getFilterData(typeof queue == 'undefined' ? '00000000-0000-0000-0000-000000000000' : queue)
      ])

      setObjects(availableObjects)
      setFilterData(filterData)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setErrorHandling(ErrorHandlingHelper.error(err))
    }
  }

  useEffect(() => {
    fetchData(queue)
  }, [queue])

  async function handleSetInterest(queueSessionId, objectId, interested) {
    try {
      let result = await Agent.Queue.setAnswer(queueSessionId, 'interest', interested)
      let data = await Agent.Queue.singleAvailableObject(queue, objectId)

      if (data.length == 0) return

      var newObjects = [...objects]

      var ix = newObjects.findIndex((o) => o.id == objectId)
      if (ix != -1) newObjects[ix] = data[0]
      else newObjects.push(data[0])

      setObjects(newObjects)
    } catch (err) {
      setErrorHandling(ErrorHandlingHelper.error(err))
    }
  }

  return (
    <React.Fragment>
      <link href="https://fonts.googleapis.com/css2?family=Raleway" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Lora" rel="stylesheet" />
      <ErrorHandling {...errorHandling} onClose={(e) => setErrorHandling(ErrorHandlingHelper.close())} />
      {loading && <div>Laddar...</div>}
      {!loading && (
        <Router>
          <Switch>
            <Route exact path="/" children={<Objects objects={objects} filterData={filterData} objectType={objectType} />} />
            <Route
              path="/object/:id"
              render={(props) => {
                return <ObjectInfoPage specialId={props.match.params.id} objects={objects} handleSetInterest={handleSetInterest} />
              }}
            />
          </Switch>
        </Router>
      )}
    </React.Fragment>
  )
}

export default AvailableObjectsPage
