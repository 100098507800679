import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'
import Map from 'Components/Map.js'
import NeighbourhoodList from 'Components/NeighbourhoodList.js'
import i18next from "i18next";
import { Trans } from 'react-i18next'

// image & title of a region

// row of a property
const PropertyRow = (props) => (
  <tr>
    <td data-title={i18next.t('label.name')}>{props.name}</td>
    <td data-title={i18next.t('label.address')}>
      {props.address && props.address.address1} {props.address && props.address.zip}
    </td>
    <td data-title={i18next.t('label.area_and_nr_of_rooms')}>
      {props.area} kvm, {props.size}
    </td>
  </tr>
)

class Accordion extends React.Component {
  constructor() {
    super()

    this.state = {
      folded: true,
    }
  }
  onComponentWillMount() {
    this.setState({
      folded: true,
    })
  }

  changeFolding() {
    this.setState({
      folded: !this.state.folded,
    })
  }

  render() {
    let className

    if (this.state.folded) className = 'accordion-folded'
    else className = ''

    return (
      <div className={'accordion-container ' + className}>
        <div className="accordion-header" onClick={this.changeFolding.bind(this)}>
          <strong>{this.props.header}</strong>
        </div>
        <div className="accordion-body">{this.props.children}</div>
      </div>
    )
  }
}

// list of properties in a region
const PropertyList = inject('store')((props) => {
  const rows = []

  props.properties.map((p) => rows.push(<PropertyRow {...p} />))

  return (
    <Accordion header={props.title}>
      <table className="pp-data-table">
        <thead>
          <tr>
            <th scope="col"><Trans>label.name</Trans></th>
            <th scope="col"><Trans>label.address</Trans></th>
            <th scope="col"><Trans>label.area_and_nr_of_rooms</Trans></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Accordion>
  )
})

// User has clicked on a neighbourhood
const NeighbourhoodInfo = inject('store')(
  observer((props) => {
    const name = props.match.params.districtName
    let store = props.store
    const neighbourhood = store.getData('neighbourhoods', { name: name })[0]

    if (!neighbourhood) {
      return null
    }

    const apartmentsPerDistrict = store.getApartmentsPerDistrict(neighbourhood.id)
    const propertyLists = []
    apartmentsPerDistrict.forEach((district, key) => {
      propertyLists.push(<PropertyList title={district.name} key={key} properties={district.apartments} />)
    })

    return (
      <div>
        <h1>Fastigheter i {neighbourhood.name}</h1>
        <p>
          Consequat excepteur minim consectetur anim deserunt do commodo laboris ipsum officia officia ullamco.Lorem aliquip in laborum est.
          Deserunt ullamco in dolore cupidatat et incididunt velit sit velit eiusmod aliquip dolore. Sint sit reprehenderit dolor cupidatat
          et et occaecat ad amet.
        </p>

        <div className="floating-container">
          <div className="floating-side-panel">
            <Map />
          </div>
          <div className="floating-main-content">{propertyLists}</div>
        </div>
      </div>
    )
  })
)

// Page showing the properties
const Page = (props) => {
  const { store } = props

  const text = store.propertiesText
  const neighbourhoods = store.getData('neighbourhoods')

  return (
    <div>
      <Switch>
        <Route exact path="/bostader">
          <NeighbourhoodList neighbourhoods={neighbourhoods} title={i18next.t('properties.header')} text={text} link="/bostader/" />
        </Route>
        <Route path="/bostader/:districtName">
          <NeighbourhoodInfo />
        </Route>
      </Switch>
    </div>
  )
}

export default inject('store')(observer(Page))
