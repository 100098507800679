import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer'
import Form from 'Components/Forms/Form'
import MessageBox from 'Components/MessageBox'
import { BankIdClient, Status, ServiceType } from 'lib/bankid'
import agent from 'Stores/Agent'
import { Root as store } from 'Stores'
import { confirm } from 'Components/Confirm'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const STATUS = Status
const SERVICE_TYPE = ServiceType

const form = {
  type: 'object',
  description: null,
  hideRequired: true,
  fields: [{ name: 'password', title: i18next.t('label.password'), required: true }],
}

const PasswordForm = (props) => (
  <React.Fragment>
    <Form
      schema={form}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      onError={props.onError}
      submit={i18next.t('label.deactivate')}
    ></Form>
  </React.Fragment>
)

const PasswordDelete = (props) => {
  const onSubmit = async (form) => {
    const values = form.formData

    return store.user.deleteProfile(values.password)
  }

  const content = []
  const hasContract = store.auth.isTenant()
  let i = 0

  if (hasContract) {
    content.push(
      <MessageBox key={i++}>
        <Trans>delete_profile.active_block</Trans>
      </MessageBox>
    )
  } else {
    content.push(
      <MessageBox key={i++} type="warning">
        <strong>
          <Trans>delete_profile.important</Trans>:{' '}
        </strong>
        <Trans>delete_profile.info</Trans>
      </MessageBox>
    )

    var form = <PasswordForm key={i++} onSubmit={onSubmit} />
    content.push(form)
  }

  if (store.ui.loginFailed)
    content.push(
      <div key={1} className="info-box error-box" role="alert">
        <Trans>error.wrong_credentials</Trans>
      </div>
    )

  return <div>{content}</div>
}

const GrandIDDelete = (props) => {
  const [status, setStatus] = useState(STATUS.Ready)
  const serviceType = props.serviceType || SERVICE_TYPE.BankId
  let history = useHistory()

  const onSuccess = (result) => {
    store.auth.logout().then(() => {
      history.push('/mina-sidor/#/')
    })
  }

  const onError = (result) => {}

  var bankid = new BankIdClient({
    serviceType: serviceType,
    onError: onError,
    onSuccess: onSuccess,
    onStatus: (status) => setStatus(status),
  })

  const clickDelete = () => {
    bankid.verify(agent.Auth.bankIdDelete)
  }

  let i = 0

  async function handleOnDeleteClick(e) {
    if (
      await confirm(i18next.t('delete_profile.confirm_text'), i18next.t('label.ok'), i18next.t('label.cancel'), {
        maxWidth: '400px',
        title: i18next.t('delete_profile.confirm_title'),
      })
    )
      clickDelete(e)
  }

  return (
    <React.Fragment>
      {status != Status.PENDING && (
        <React.Fragment>
          <MessageBox key={i++} type="warning">
            <strong>
              <Trans>delete_profile.important</Trans>:{' '}
            </strong>
            <Trans>delete_profile.info</Trans>
          </MessageBox>
          <button className="main-btn" onClick={(e) => handleOnDeleteClick(e)}>
            <Trans>delete_profile.button</Trans>
          </button>
        </React.Fragment>
      )}
      {status == Status.PENDING && (
        <MessageBox key={i++} type="info">
          <Trans>delete_profile.pending</Trans>
        </MessageBox>
      )}
      {status == Status.ERROR && (
        <MessageBox key={i++} type="error">
          <Trans>error.something_try_again</Trans>
        </MessageBox>
      )}
    </React.Fragment>
  )
}

const Page = (props) => {
  const authMethod = props.store.auth.getAuthMethod()
  const profile = props.store.user.profile.toJSON()
  const [deleteStatus, setDeleteStatus] = useState(null)

  useEffect(() => {
    async function checkDeleteStatus() {
      const response = await agent.Auth.canDelete()
      setDeleteStatus(response.status)
    }

    checkDeleteStatus()
  }, [])

  if (deleteStatus == null) return <Trans>loading</Trans>

  var response

  if (deleteStatus == 'canDelete')
    response = (
      <React.Fragment>
        {authMethod == 'bankid' && <GrandIDDelete type={authMethod} />}
        {authMethod == 'password' && <PasswordDelete />}
      </React.Fragment>
    )
  else
    response = (
      <MessageBox type="error">
        {deleteStatus == 'activeQueues' && <Trans>queue.activeQueues</Trans>}
        {deleteStatus == 'unpaidAgencyCost' && <Trans>queue.unpaidAgencyCost</Trans>}
      </MessageBox>
    )

  return response
}

export default inject('store')(observer(Page))
