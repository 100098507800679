import request from 'superagent'
import superagent from 'superagent'
import { Root as store } from './'

const getApiRoot = () => {
  let tenant = window.location.pathname.split('/')[1]
  const hostname = window.location.hostname
  const protocol = window.location.protocol

  tenant = tenant.replace(/^\/|\/$/g, '')
  if (tenant) tenant = '/' + tenant
  return `${protocol}//${hostname}/api${tenant}/tenantservice/v1`
}

const API_ROOT = process.env.REACT_APP_API || getApiRoot()

const onError = (res) => {
  if (res.status == 400) return

  if (res.status == 401) {
    store.auth.logout()
    return
  }

  store.auth.setApiWorking(false)
}

const catchError = (err) => {
  if (err.status == 401) return
  store.auth.setApiWorking(false)
  throw err
}

const tokenPlugin = (req) => {
  let token = store.auth.accessToken

  if (token) {
    req.set('authorization', `bearer ${token}`)
  }
}

const requestConfig = (req) => {
  req.use(tokenPlugin).on('error', onError)
}

const handleResponse = (res) => {
  store.auth.setApiWorking(true)

  return res.body || res.text
}

const requests = {
  del: (url) => superagent.del(`${API_ROOT}${url}`).use(requestConfig).then(handleResponse),
  get: (url, query) => superagent.get(`${API_ROOT}${url}`).query(query).use(requestConfig).then(handleResponse).catch(catchError),
  post: (url, body) => superagent.post(`${API_ROOT}${url}`, body).use(requestConfig).then(handleResponse),
  put: (url, body) => superagent.put(`${API_ROOT}${url}`, body).use(requestConfig).then(handleResponse),
}

const Auth = {
  bankIdDelete: async (data) => await requests.post('/bankid/delete', data),
  bankIdLogin: async (data) => await requests.post('/bankid/login', data),
  bankIdRegister: async (data) => await requests.post('/bankid/register', data),
  bankIdStatus: async (data) => await requests.get('/bankid/status', data),
  changePassword: async (oldPassword, newPassword) => await requests.post('/users/changepassword', { oldPassword, newPassword }),
  current: async () => await requests.get('/users/current'),
  canDelete: async () => await requests.get('/users/candelete'),
  delete: async (password) => await requests.post('/users/delete', { password }),
  login: async (email, password) => await requests.post('/login', { email, password }),
  ping: async () => await requests.get('/ping'),
  requestNewPassword: async (email) => await requests.post('/login/requestpassword', { email }),
  save: async (user) => await requests.post('/users/update', { user }),
  setNewPassword: async (email, token, password) => await requests.post('/login/setpassword', { email, token, password }),
  checkConsent: async (email, token) => await requests.post('/login/checkconsent', { email, token }),
}

const Portal = {
  info: () => requests.get('/info'),
  regInfo: async () => await requests.get('/users/register'),
  loggedIn: () => requests.get('/logged_in')
}

const Reports = {
  get: () => requests.get('/reports'),
  post: (report) => requests.post('/reports', report),
}

const Queue = {
  getQueues: async () => await requests.get('/queue/queues'),
  getObjects: async () => await requests.get('/queue/objects'),
  getInterests: async () => await requests.get('/queue/interests'),
  getOffers: async () => await requests.get('/queue/offers'),
  getPaymentUrl: async (id, subscription) => await requests.get('/payments/url', { id, subscription }),
  invoicePaymentRequest: async (id) => await requests.post('/payments/invoicerequest', { id }),
  downloadInvoice: async (id) => await requests.get('/payments/invoicerequest', { id }),
  paymentStatus: async (queueId) => await requests.get('/payments/status', { queueId }),
  setAnswer: async (id, type, answer) => await requests.post('/queue/answer', { id, type, answer }),
  joinQueue: async (queueId) => await requests.post('/queue/join', { queueId }),
  leaveQueue: async (queueId, password) => await requests.post('/queue/leave', { queueId, password }),
  getSubscriptions: async () => await requests.get('/queue/subscriptions'),
  setSubscription: async (subscription) => await requests.post('/queue/subscriptions', subscription),
  availableObjects: async (queueId) => await requests.get('/queue/available', { queueId }),
  singleAvailableObject: async (queueId, objectId) => await requests.get('/queue/available', { queueId, objectId }),
  getObjectTypes: async () => await requests.get('/queue/objecttypes'),
  getFilterData: async (id) => await requests.get('/queue/filterdata/' + id),
  getQueueInformation: async (id) => await requests.get('/queue/info/' + id),
  cancelSubscription: async (id) => await requests.post('/payments/subscription/cancel', { id }),
}

const User = {
  changeEmail: async (email, code) => {
    await requests.post('/users/changeemail', { email, code })
  },
  changePassword: async (oldPassword, newPassword) => await requests.post('/users/changepassword', { oldPassword, newPassword }),
  getProfile: async () => await requests.get('/users/profile'),
  register: async (values) => await requests.post('/users/register', values),
  registerTenant: async (values) => await requests.post('/users/registertenant', values),
  updateProfile: async (profile) => await requests.post('/users/profile', profile),
  getCertificates: async (file) => await requests.get('/users/certificates'),
  uploadCertificate: async (file) => await requests.post('/users/certificates', file),
  deleteCertificate: async (id) => await requests.post('/users/certificates/delete', { id }),
}

const Contracts = {
  get: () => requests.get('/contracts'),
  changeAddress: async (contractId, address) => await requests.post('/contracts/address', { contractId, address }),
  getAddresses: () => requests.get('/contracts/addresses'),
  getPayments:async (contractId) => requests.get('/invoice_payments/' + contractId),
}

const Media = {
  getInfo: async() => await requests.get('/media/info'),
  getCounters: async (contractId) => await requests.get('/media/' + contractId),
  getReadings: async (contractId, counterId, intervalType, showPreviousPeriod) => await requests.get('/media/' + contractId + '/readings/' + counterId, { intervalType, showPreviousPeriod })
}


const Chat = {
  getMessages: async () => await requests.get('/chat/messages'),
  getUnreadCount: async () => await requests.get('/chat/messagecount'),
  sendMessage: async (message) => await requests.post('/chat/message', { text: message }),
}

export default {
  Auth,
  Portal,
  Reports,
  Queue,
  User,
  Contracts,
  Media,
  Chat,
}
