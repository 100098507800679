import { runInAction, extendObservable } from 'mobx'
import agent from './Agent'

class ContractsStore {
  constructor(parent) {
    this.parent = parent

    extendObservable(this, {
      apiError: false,
      contracts: [],
      errorReports: [],
      errorOptions: [],
      objectOptions: [],
      otherOptions: undefined,

      async getContracts() {
        const data = await agent.Contracts.get()

        if (data) {
          runInAction(() => {
            this.contracts.replace(data.contracts)
          })
        }
      },
    })
  }
}

export default ContractsStore
