import React from 'react'
import { Link } from 'react-router-dom'
import observer from 'Decorators/Observer.js'

const Entry = (props) => {
  const { count, image, link, name } = props

  let countDiv
  if (props.showCount) {
    if (!count) countDiv = null
    else countDiv = <div className="countContainer">{count}</div>
  }

  return (
    <li>
      <Link to={link + name}>
        {countDiv}
        <div className="imageContainer">
          <img src={image} alt="" />
        </div>
        <div className="informationContainer">
          <h4>{name}</h4>
        </div>
      </Link>
    </li>
  )
}

const List = observer((props) => {
  const { link, neighbourhoods, showCount, title, text } = props

  let rows = []
  neighbourhoods.forEach((p) => {
    rows.push(<Entry {...p} link={link} showCount={showCount} />)
  })

  return (
    <div>
      <h1>{title}</h1>
      <p>{text}</p>
      <ul className="propertyList">{rows}</ul>
    </div>
  )
})

export default List
