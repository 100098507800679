import React from 'react';
import { useState, useEffect } from 'react';
import { ReactBingmaps } from 'react-bingmaps';
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'

const BingMapInternal = ({ address1, address2, zip, city, mapKey }) => {
    const [pushPins, setPushPins] = useState([]);
    const [center, setCenter] = useState([59.616991, 17.724098]);

    const baseAPI = 'https://dev.virtualearth.net/REST/v1/Locations?output=json&countryRegion=SE&s=1'

    useEffect(() => {
        const fetchLocation = async () => {
            const sAddress = `${address1} ${address2}`.trim();
            const sZip = zip.trim();
            const sCity = city.trim();

            if (sZip || sCity) {
                const url = `${baseAPI}&postalCode=${encodeURIComponent(sZip)}&locality=${encodeURIComponent(sCity)}&addressLine=${encodeURIComponent(sAddress)}&maxResults=1&key=${mapKey}&tick=${new Date().valueOf().toString()}`;

                try {
                    const response = await fetch(url);
                
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                
                    const resp = await response.json();
                
                    if (resp?.resourceSets[0]?.resources[0]?.point?.coordinates) {
                        const location = resp.resourceSets[0].resources[0].point.coordinates;
                        setPushPins([{ location, option: { color: 'red' } }]);
                        setCenter(location);
                    }
                } catch (error) {
                    console.error("Error fetching location", error);
                    // Consider providing user feedback or retries.
                }
            }
        };

        fetchLocation();
    }, [address1, address2, zip, city, mapKey, baseAPI]);

    return (
        <div style={{ width: '100%', height: '300px', boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.15)' }}>
            <ReactBingmaps
                pushPins={pushPins}
                bingmapKey={mapKey}
                center={center}
                mapTypeId={"road"}
                supportedMapTypes={["road"]}
                zoom={16}
            ></ReactBingmaps>
        </div>
    );
};

const BingMap = inject('store')(observer((props) => {
    const info = props.store.portal.getInfo()
    const mapKey = info.get('bingKey')

    return <BingMapInternal mapKey={mapKey} {...props} />
}))

export default BingMap;