import React from 'react'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import observer from 'Decorators/Observer.js'
import LoginPage from './LoginPage.js'
import { Trans } from 'react-i18next'

import { ReactComponent as House } from 'Images/SVG/menu-bas.svg'
import { ReactComponent as SearchHouse } from 'Images/SVG/search.svg'

const CircleIcon = (props) => {
  return (
    <span className="flex-item flex-item__fixed">
      <span className="icon-circle">
        <span className="pp-big-link-icon-container">
          <House className="pp-big-link-icon"></House>
        </span>
      </span>
    </span>
  )
}

const FirstTime = ({ useAlreadyTenant }) => (
  <div>
    <h1>
      <Trans>welcome.header</Trans>
    </h1>
    <div>
      {useAlreadyTenant && (
        <Link className="pp-big-link flex-container flex-container__center" to="/hyresgast">
          <span className="flex-item flex-item__fixed">
            <span className="icon-circle">
              <span className="pp-big-link-icon-container">
                <House className="pp-big-link-icon"></House>
              </span>
            </span>
          </span>
          <span className="flex-item pl-10">
            <b className="pp-big-link-header">
              <Trans>welcome.existing_tenant_header</Trans>
            </b>
            <span className="pp-big-link-text">
              <Trans>welcome.existing_tenant_text</Trans>
            </span>
          </span>
        </Link>
      )}
      {true && (
        <React.Fragment>
          <hr />
          <Link className="pp-big-link flex-container flex-container__center" to="/registrera">
            <span className=" flex-item flex-item__fixed">
              <span className="icon-circle">
                <span className="pp-big-link-icon-container">
                  <SearchHouse className="pp-big-link-icon"></SearchHouse>
                </span>
              </span>
            </span>
            <span className="flex-item pl-10">
              <b className="pp-big-link-header">
                <Trans>welcome.new_tenant_header</Trans>
              </b>
              <span className="pp-big-link-text">
                <Trans>welcome.new_tenant_text</Trans>
              </span>
            </span>
          </Link>
        </React.Fragment>
      )}
    </div>
  </div>
)

class Page extends React.Component {
  render() {
    const emailAuth = this.props.store.auth.useEmailAuth()
    const useAlreadyTenant = this.props.store.portal.useAlreadyTenant()
    const openRegistration = this.props.store.portal.openRegistration()

    return (
      <div>
        <div className="pp-grid__row">
          <div>
            <LoginPage />
          </div>
        </div>
        <br />
        {openRegistration && <FirstTime useAlreadyTenant={useAlreadyTenant} />}
      </div>
    )
  }
}

export default inject('store')(observer(Page))
