import AuthStore from './Auth'
import PortalStore from './Portal'
import QueueStore from './Queue'
import ReportsStore from './Reports'
import UIStore from './UI'
import UserStore from './User'
import ContractsStore from './Contracts'

const initState = (obj) => {
  Object.keys(obj).forEach((k, v) => {
    UIStore.init(k, v)
  })
}

class RootStore {
  constructor() {
    this.auth = new AuthStore(this)
    this.contracts = new ContractsStore(this)
    this.portal = new PortalStore(this)
    this.queue = new QueueStore(this)
    this.reports = new ReportsStore(this)
    this.user = new UserStore(this)
    this.ui = new UIStore(this)
  }
}

const Root = new RootStore()

export { Root, initState }
