import Pages from './Pages'
import Sigtuna from './Pages/Sigtuna'

const routes = {
  bostader: { name: 'pages.properties', component: Pages.Properties },
  //'ledigt': { name: 'pages.available'), component: Pages.Available },
  valkommen: { name: 'pages.welcome', component: Pages.Welcome, auth: false },
  loggain: { name: 'pages.login', component: Pages.Login, auth: false },
  losenord: { name: 'pages.reset_password', component: Pages.ResetPassword, auth: false },
  registrera: { name: 'pages.register', component: Pages.Register, auth: false },
  hyresgast: { name: 'pages.tenant_register', component: Pages.TenantRegister, auth: false },
  chat: { name: 'pages.chat', component: Pages.Chat, auth: true },
  ledigt: { name: 'pages.objects', component: Pages.Objects, auth: true },
  intresseanmalan: { name: 'pages.interested_page', component: Pages.InterestedPage, auth: true },
  erbjudanden: { name: 'pages.offers', component: Pages.Offers, auth: true },
  minasidor: { name: 'pages.logged_in', component: Pages.LoggedIn, auth: true },
  serviceanmalan: { name: 'pages.report', component: Pages.Report, auth: true, tenant: true },
  arenden: { name: 'pages.cases', component: Pages.Cases, auth: true, tenant: true },
  media: { name: 'pages.media', component: Pages.Media, auth: true, tenant: true },
  kontrakt: { name: 'pages.contract', component: Pages.Contract, auth: true, tenant: true },
  payments: { name: 'pages.payments', component: Pages.Payments, auth: true, tenant: false },
  prenumerationer: { name: 'pages.subscription', component: Pages.Subscription, auth: true },
  profil: { name: 'pages.profile', component: Pages.Profile, auth: true },
  bytlosenord: { name: 'pages.change_password', component: Pages.ChangePassword, auth: true },
  radera: { name: 'pages.delete_profile', component: Pages.DeleteProfile, auth: true },
  loggaut: { name: 'pages.logout', component: Pages.Logout, auth: true },
  inline: { component: Pages.Inline },

  // sigtuna
  ledigabostader: { name: 'pages.available_objects', component: Sigtuna.AvailableObjects, agency: true },
  bostadsko: { name: 'pages.apartment_queue', component: Sigtuna.Queue, auth: true, agency: true },
  tomthusko: { name: 'pages.plothouse_queue', component: Sigtuna.Queue, auth: true, agency: true },
}

export default routes
