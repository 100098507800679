import React from 'react'

const processFile = function (files, onChange) {
  const f = files[0]
  const reader = new FileReader()

  const promise = new Promise((resolve, reject) => {
    reader.onload = (event) => {
      resolve(event.target.result)
    }
  })

  reader.readAsDataURL(f)

  return promise
}

const FileWidget = (props) => {
  return (
    <div>
      <input
        type="file"
        id={props.id}
        required={props.required}
        onChange={(event) => processFile(event.target.files).then((data) => props.onChange(data))}
      />
    </div>
  )
}

export default FileWidget
