import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import Certificates from 'Components/Certificates'
import ModalWindow from 'Components/ModalWindow'
import ObjectInfo from 'Components/ObjectInfo'
import Utils from 'lib/utils'
import Icons from 'lib/icons'
import agent from 'Stores/Agent'
import { ErrorHandling, ErrorHandlingHelper } from 'Components/ErrorHandling'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const datefmt = Utils.datefmt
const queue2name = Utils.queue2name

const OfferRow = (row) => {
  let answerLink = []

  if (row.status == 'offered') {
    if (row.answer != 'yes') {
      answerLink.push(
        <a key={0} onClick={row.onClickOffer(true)} className="main-btn main-btn-mini main-btn-green">
          {Icons.Check}&nbsp;<Trans>label.accept</Trans>
        </a>
      )
    }

    if (row.answer != 'no') {
      if (answerLink.length > 0) answerLink.push(<span key={3}>&nbsp;</span>)
      answerLink.push(
        <a key={1} onClick={row.onClickOffer(false)} className="main-btn main-btn-mini main-btn-red">
          {Icons.Cross}&nbsp;<Trans>label.decline</Trans>
        </a>
      )
    }
  } else {
    answerLink.push(
      <div>
        <Trans>label.awaiting_confirmation</Trans>
      </div>
    )
  }

  return (
    <tr>
      <td data-title={i18next.t('label.address')}>
        <a href="#" onClick={row.onObjectInfo} style={{ fontWeight: 'bold' }}>
          {row.address && row.address.address1}, {row.address && row.address.city}
        </a>
        <div style={{ whiteSpace: 'nowrap', fontSize: '12px', lineHeight: '1.2em', fontWeight: 'bold' }}>
          {row.rent} kr | {row.size}
          <br />
          {Math.round(row.area)} <Trans>label.kvm</Trans>
        </div>
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.type')}>
        {queue2name(row.type)}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.admission_date')}>
        {datefmt(row.admissionDate, 'yyyy-MM-dd')}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.offer_end_date')}>
        {datefmt(row.endDate, 'yyyy-MM-dd')}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('queue.position')}>
        {row.queueOrder || '-'}
      </td>
      <td style={{ verticalAlign: 'middle' }} data-title={i18next.t('label.your_answer')}>
        {row.answer == 'yes' && i18next.t('label.you_answered_yes')}
        {row.answer == 'no' && i18next.t('label.you_answered_no')}
        {answerLink}
      </td>
    </tr>
  )
}

const OffersTable = (props) => {
  const rows = []

  const onClickOffer = (obj) => (answer) => (event) => {
    event.preventDefault()
    return props.onAnswer(obj, answer)
  }

  const onObjectInfo = (obj, contact) => (event) => {
    event.preventDefault()
    return props.onObjectInfo(obj, contact)
  }

  props.rows.map((row) =>
    rows.push(
      <OfferRow
        key={row.id}
        {...row}
        onObjectInfo={onObjectInfo(row)}
        onObjectInfoContact={onObjectInfo(row, true)}
        onClickOffer={onClickOffer(row)}
      />
    )
  )

  return (
    <table className="pp-data-table mb-large">
      <thead>
        <tr>
          <th scope="col">
            <Trans>label.address</Trans>
          </th>
          <th scope="col">
            <Trans>label.type</Trans>
          </th>
          <th scope="col">
            <Trans>label.admission_date</Trans>
          </th>
          <th scope="col">
            <Trans>label.application_end_date</Trans>
          </th>
          <th scope="col">
            <Trans>queue.position</Trans>
          </th>
          <th scope="col">
            <Trans>label.your_answer</Trans>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const HistoryRow = (row) => (
  <tr>
    <td data-title={i18next.t('label.address')}>
      <a href="#" onClick={row.onObjectInfo} style={{ fontWeight: 'bold' }} className="pp-link">
        {row.address && row.address.address1}, {row.address && row.address.city}
      </a>
    </td>
    <td data-title={i18next.t('label.rent')}>{Math.round(row.rent)}</td>
    <td data-title={i18next.t('label.type')}>
      {queue2name(row.type)}, {row.size}
    </td>
    <td data-title={i18next.t('label.area')}>{Math.round(row.area)} <Trans>label.kvm</Trans></td>
    <td data-title={i18next.t('label.status')}>
      {row.status == 'history_rented' ? i18next.t('label.assigned') : i18next.t('label.assigned_someone_else')}
    </td>
  </tr>
)

const OffersHistoryTable = (props) => {
  const rows = []

  const onObjectInfo = (obj) => (event) => {
    event.preventDefault()
    return props.onShowObjectInfo(obj)
  }

  props.rows.map((row, index) => rows.push(<HistoryRow onObjectInfo={onObjectInfo(row)} key={index} {...row} />))

  return (
    <table className="pp-data-table">
      <thead>
        <tr>
          <th scope="col">
            <Trans>label.address</Trans>
          </th>
          <th scope="col">
            <Trans>label.rent</Trans>
          </th>
          <th scope="col">
            <Trans>label.type</Trans>
          </th>
          <th scope="col">
            <Trans>label.area</Trans>
          </th>
          <th scope="col">
            <Trans>label.status</Trans>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const Offers = ({ offers, uploadCertificates, certificateInfo, onAnswer, onShowObjectInfo }) => {
  const showCertificates = uploadCertificates.offer && offers.filter((o) => o.answer == 'yes').length > 0

  return (
    <React.Fragment>
      {offers.length > 0 && (
        <div>
          <OffersTable rows={offers} onAnswer={onAnswer} onObjectInfo={onShowObjectInfo} />
          {showCertificates && <Certificates certificateInfo={certificateInfo} />}
        </div>
      )}
      {offers.length == 0 && (
        <em>
          <Trans>offers.empty</Trans>
        </em>
      )}
    </React.Fragment>
  )
}

const Page = () => {
  const [loading, setLoading] = useState(true)
  const [objects, setObjects] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentObject, setCurrentObject] = useState()
  const [showContactOnly, setShowContactOnly] = useState()
  const [errorHandling, setErrorHandling] = useState(ErrorHandlingHelper.getDefaultState())

  const loadObjects = () => {
    agent.Queue.getOffers().then((objects) => {
      setObjects(objects)
      setLoading(false)
    })
  }

  useEffect(loadObjects, [])

  const onAnswer = (offer, response) => {
    agent.Queue.setAnswer(offer.id, 'offer', response)
      .then(loadObjects)
      .catch((err) => {
        setErrorHandling(ErrorHandlingHelper.error(err))
      })
  }

  const setModal = (visible) => setShowModal(visible)
  const showObjectInfo = (obj, contactOnly) => {
    setCurrentObject(obj)
    setShowContactOnly(contactOnly)
    setShowModal(true)
  }
  const onClose = () => setModal(false)

  let { history, offers } = objects
  if (!history) history = []
  if (!offers) offers = []

  return (
    <React.Fragment>
      <ErrorHandling {...errorHandling} onClose={(e) => setErrorHandling(ErrorHandlingHelper.close())} />
      {loading && (
        <div>
          <Trans>loading</Trans>
        </div>
      )}
      {!loading && (
        <div>
          <h1>
            <Trans>offers.header</Trans>
          </h1>
          <Tabs>
            <TabList>
              <Tab>
                {Icons.Star} <Trans>label.current</Trans>
              </Tab>
              <Tab>
                {Icons.History} <Trans>label.history</Trans>
              </Tab>
            </TabList>
            <TabPanel>
              <Offers
                offers={offers}
                uploadCertificates={objects.uploadCertificates}
                certificateInfo={objects.certificateInfo}
                onAnswer={onAnswer}
                onShowObjectInfo={showObjectInfo}
              />
            </TabPanel>
            <TabPanel>
              {history.length == 0 && (
                <em>
                  <Trans>label.empty_history</Trans>
                </em>
              )}
              {history.length > 0 && <OffersHistoryTable onShowObjectInfo={showObjectInfo} rows={history} />}
            </TabPanel>
          </Tabs>
          <ModalWindow
            isOpen={showModal}
            onClose={onClose}
            title={currentObject && currentObject.address && currentObject.address.address1}
          >
            <ObjectInfo object={currentObject} showContactOnly={showContactOnly} showingInfo={objects.showingInfo} />
          </ModalWindow>
        </div>
      )}
    </React.Fragment>
  )
}

export default Page
