import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-modal'

import { confirmable, createConfirmation } from "react-confirm";
import i18next from "i18next";

class Confirm extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
      maxWidth,
      ...rest
    } = this.props;

    let className = {
      base: 'pp-modal-container',
      afterOpen: 'pp-modal-container--open',
      beforeClose: 'pp-modal-container--close',
    };
    let overlayClassName = {
      base: 'pp-modal-overlay',
      afterOpen: 'pp-modal-overlay--open',
      beforeClose: 'pp-modal-overlay--close',
    };

    return (
      <Modal
        portalClassName={'pp-component'}
        className={className}
        overlayClassName={overlayClassName}
        isOpen={this.props.show}
        onRequestClose={() => proceed(false)}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={400}
        aria={{
          labelledby: 'id-modal-title',
          modal: 'true',
        }}
        {...rest}
      >
        <div className="pp-modal-window" role="alert" style={maxWidth && { maxWidth: maxWidth }}>
          <div className="pp-modal-header">
            <div id="id-modal-title" className="pp-modal-header-title">
              {title}
            </div>
          </div>
          <div className="pp-modal-content">
            <form>
              {confirmation}
            </form>
            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
              <button className="main-btn" aria-label={i18next.t('label.ok')} onClick={() => proceed(true)}>{proceedLabel}</button>
              &nbsp;
              <button className="main-btn main-btn-red" aria-label={i18next.t('label.cancel')} onClick={() => proceed(false)}>{cancelLabel}</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

Confirm.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
  confirmation,
  proceedLabel = i18next.t('label.ok'),
  cancelLabel = i18next.t('label.cancel'),
  options = {}
) {
  return createConfirmation(confirmable(Confirm))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
